import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
//headers = headers.set('token', 'true');
function _window() :any{
  return window;
}
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  get nativeWindow():any{
    return _window();
  }
  private httpclientbackend: HttpClient;
  prefix = environment._apiUrl;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend = new HttpClient(handler);
  }
  public login(username, password): Observable<any> {
    const body = {
      username,
      password
    };
    return this.httpclientbackend.post<any>(`${this.prefix}api/auth/token`, body, {
      headers
    });
  }
  public registerUser(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}api/auth/signup`, user, {
      headers
    });
  }
  public setpassword(password, userid): Observable<any> {
    const body = {
      userid,
      password
    };
    return this.httpclientbackend.post<any>(`${environment._apiUrl}api/auth/setpassword`, body, {
      headers
    });
  }
  public resetpassword(email, password): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}api/auth/resetpassword?email=${email}&password=${password}`, {
      headers
    });
  }
  public checkexpirelink(userid): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}api/auth/checkexpirelink?userid=${userid}`, {
      headers
    });
  }
  public forgotpwdsendmail(emailaddress): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}api/auth/forgotpwdsendmail?email=${emailaddress}`, {
      headers
    });
  }
  public getDropDowns(): Observable<any> {
    console.log("ok")
    return this.httpclientbackend.get<any>(`${environment._apiUrl}api/auth/getdropdowns`, {
      headers
    });
  }
  public getStatebyCountyID(countyid): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}/api/user/getstatebycountryid?countryid=${countyid}`, {
      headers
    });
  }
  public getProfile(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/user/getprofilepermission?userid=${userid}`, {
      headers
    });
  }
  public CheckLoginUser(user): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/aouth2/signin`, user, {
      headers
    });
  }
  public getNewToken(refreshToken): Observable<any> {
    return this.httpclientbackend.post<any>(`${environment._apiUrl}api/auth/newticket`, refreshToken, {
      headers
    });
  }
  public logout(payload): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/auth/logout`, payload, {
      headers
    });
  }

  // public submitotp(user): Observable<any> {
  //   return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/submitotp`, user, {
  //     headers
  //   });
  // }
  // public resendotp(user): Observable<any> {
  //   return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/resendotp`, user, {
  //     headers
  //   });
  // }
  // public getLatestCase(payload, adddocument, userid): Observable<any> {

  //   return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/welcome/getlatestcasedataes?adddocument=${adddocument}&userid=${userid}`, payload, {
  //     headers
  //   });
  // }
  // public updateconfimotpuser(user): Observable<any> {
  //   return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/ForgotPasswordSubmitOTP`, user, {
  //     headers
  //   });
  // }
  // public usernewpassowrdsetup(user): Observable<any> {
  //   return this.httpclientbackend.post<any>(`${environment._apiUrl}/api/user/usernewpassowrdsetup`, user, {
  //     headers
  //   });
  // }

  // public getTotalCount(): Observable<any> {
  //   return this.httpclientbackend.get<any>(`${environment._apiUrl}/api/welcome/getTotalCount`, {
  //     headers
  //   });
  // }

}
