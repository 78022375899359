import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ArticleService } from 'src/app/admin/services/article.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';

declare const $: any;
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private loader: LoaderService,
    private aservice: ArticleService,
    private cd: ChangeDetectorRef,
    private mservice: ToastService
  ) { }
  featurelist = [];
  ngOnInit(): void {
    this.loader.loadme = true;
    this.aservice.getFeatures().pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      this.loader.loadme = false;
      if (data.flag) {
        this.featurelist = data.result;
        this.loader.loadme = false;
        this.cd.markForCheck();
      } else {
        this.mservice.generateMessage('ERROR', data.error, '');
        this.loader.loadme = false;
      }
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
