<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer" style="height: 60.1094px;"></div>

<section class="overflow-hidden  bg-header-video">
    <video playsinline autoplay muted loop>
        <source src="assets/images/typing_video.mp4" type="video/mp4">
    </video>
    <div class="row">
        <div class="col-md-7"> </div>
        <div class="col-md-5" style="align-items: center;">
            <div class="video-card">
                <div class="card-body p-md-5 p-sm-4">
                    <p class="card-text" style="line-height:1.5;">
                        Boost Your Typing Speed with TezTyping
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="header-link" id="examecover">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 pb-2 col-md-2">
                <h2 class="color-white font-weight-600 mb-0">Exams</h2>
                <h5 class="color-white font-weight-400  mt-0 mb-0">we cover</h5>
            </div>
            <div class="col-sm-12 p-2 col-md-9 exam-names">

                <!-- <div class="div-row d-flex j-content ">
                    <div class="div">High court LDC</div>
                    <div class="div">Raj HC / Raj STENO</div>
                    <div class="div">SSC CHSL/ SSC CGL </div>
                    <div class="div">RRB NTPC</div>
                    <div class="div">RSSB LDC</div>
                    <div class="div">High court LDC</div>
                    <div class="div">RPSC</div>


                </div>
                <div class="div-row d-flex " style="padding-top: 5px;">

                    <div class="div1">HIGH COURT EXAM</div>
                    <div class="div1">MINISTRIAL EXAM</div>
                    <div class="div1">CPCT EAXM </div>
                    <div class="div1">BIHAR BELTRON DEO</div>

                </div> -->

                <a href="#" class="anm banner-button btn btn-outline-primary mx-2 my-1" style="pointer-events: none">
                    High court LDC </a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">Raj HC / Raj
                    STENO</a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">SSC CHSL/ SSC CGL
                </a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">RRB NTPC</a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">RSSB LDC</a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">RPSC</a>
                <a href="#" class="btn btn-outline-primary mx-2  my-1" style="pointer-events:none">HIGH COURT EXAM</a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">MINISTRIAL EXAM</a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">CPCT EAXM (M.P)</a>
                <a href="#" class="btn btn-outline-primary mx-2 my-1" style="pointer-events: none">BIHAR BELTRON DEO</a>


            </div>
            <div class="col-sm-12 col-md-1 pt-4 p-0 try-btn">
                <a [routerLink]="['/package-details']" class="btn p-2 px-2">
                    Try Now!
                </a>
            </div>
        </div>
    </div>
</section>
<!-- suraj create section -->
<section style="padding: 30px 0px; background: #ffffff;">
    <div class="container" style="padding: 30px 0px; margin: auto;">
        <div class="row">
            <div class="col-md-6 col-sm-12" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div class="card">
                    <h4 style="color: #145188; padding-bottom: 4px; text-align: center;">Web Version</h4>
                    <div class="desktop-div" style="font-size: 20px; text-align: center; border-radius: 3px;
        margin: auto;color: #647788; ">Mobile, PC/Laptop & Tablet</div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div class="card">

                    <h4 style="color: #145188; padding-bottom: 4px; text-align: center;">Desktop Version</h4>
                    <div class="desktop-div" style="font-size: 20px; text-align: center; border-radius: 3px;
        margin: auto;color: #647788; ">Desktop & PC/Laptop</div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid" style="background: #F2F2F2; width: 100%;">
    <div class="container" data-aos="flip-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
        style="padding: 50px 0px ;">
        <div class="row ">
            <div class="col-md-12 text-center">
                <h2 style="color:#145188;">Boost Your Typing Speed </h2>
                <h4 style="color:#145188;"> English and Hindi Typing for Websites and Desktops</h4><br>
                <!-- <p style="font-weight: 400; color:#1d4161de ; font-size: 18px;">Hindi font available Mangal font, kurutidev Devlys font </p> -->
                <p style="line-height: 1.5; color: #2d4d69c7; font-size: 17px;"><span
                        style="font-size: 20px; color: #e0751e;">Tez typing</span> offer a variety of fonts including
                    <span style="font-size: 20px; color: #e0751e;">Mangal, Kurutidev, and Devlys</span> to accommodate
                    your typing preferences. Our <span style="font-size: 20px; color: #e0751e;">Tez typing</span>
                    feature is available for both <span style="font-size: 20px; color: #e0751e;">web and desktop</span>,
                    so you can practice your skills wherever and whenever you want.
                </p>
                <p style="line-height: 1.5; color: #2d4d69c7; font-size: 17px;">Our program offers a wide range of
                    practice sets for both Hindi and English, ensuring that you have plenty of opportunities to master
                    your typing skills. We also offer different keyboard layouts such as <span
                        style="font-size: 20px; color: #e0751e;">Inscript, Remington Gail, and CBI Layout</span>, which
                    are perfect for those preparing for <span style="font-size: 20px; color: #e0751e;">Government
                        Exams</span>.</p>
                <p style="line-height: 1.5; color: #2d4d69c7; font-size: 17px;">Our software is compatible with both
                    <span style="font-size: 20px; color: #e0751e;">websites and desktop applications</span>, so you can
                    use it no matter where you work. Plus, we offer <span
                        style="font-size: 20px; color: #e0751e;">customizable settings</span>, so you can personalize
                    your typing experience to fit your needs.
                </p>
                <p style="line-height: 1.5; color: #2d4d69c7; font-size: 17px;">Don't let language barriers slow you
                    down – try our efficient typing solution today and take your communication to the <span
                        style="font-size: 20px; color: #e0751e;">next level!</span></p>
            </div>
        </div>
    </div>
</div>














<!-- suraj create section -->

<section class="features" id="features">
    <div class="container">

        <div class="py-4  text-center col-12">
            <h3 class="font-weight-700 section-title"> Features </h3>
            <p class="section-text m-0 font-weight-600"> We Provide massive features in our software </p>
        </div>
        <div class="row">
            <!-- <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1">
                                <h5 class="card-title mb-0">Online and Offline Software</h5>
                                <p class="card-text ">Alice is a freelance web designer and developer based in London. She is specialized in HTML5, CSS3, JavaScript, Bootstrap, etc.</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <img src="assets/images/online.jpg" class="card-img-top p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1 " data-aos="zoom-in-right" data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1 ">
                                <h5 class="card-title mb-0">Best Outcome</h5>
                                <p class="card-text">You can utilise to improve your typing skills by getting proper and
                                    accurate results that correspond to upcoming government tests.</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <img src="assets/images/live.jpg" class="card-img-top  p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1" data-aos="zoom-in-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1 ">
                                <h5 class="card-title mb-0">1000 + Exercise</h5>
                                <p class="card-text"><span style="font-size: 18px; color: #e0751e;">Tez Typing</span>
                                    Tutor offers numerous exercises to help you practise and speed up by working on
                                    various paragraphs.</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <img src="assets/images/exercises.jpg" class="card-img-top  p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1 " data-aos="zoom-in-right" data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1 ">
                                <h5 class="card-title mb-0">Live Test</h5>
                                <p class="card-text"><span style="font-size: 18px ; color: #e0751e;">Tez Typing</span>
                                    Tutor offers you various tests to evaluate your performance and compare yourself to
                                    competitors.</p>

                            </div>
                        </div>
                        <div class="col-3">
                            <img src="assets/images/accurate.jpg" class="card-img-top p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1" data-aos="zoom-in-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1 ">
                                <h5 class="card-title mb-0">Various Options available</h5>
                                <p class="card-text">We provide customization options include allowing backspace,
                                    altering the font size, highlighting words, and modifying the test length.</p>
                            </div>
                        </div>
                        <div class="col-3">

                            <img src="assets/images/various-options.jpg" class="card-img-top  p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1 " data-aos="zoom-in-right" data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1 ">
                                <h5 class="card-title mb-0">All Hindi Font Provision</h5>
                                <p class="card-text">Comes in a variety of hindi fonts <span
                                        style="font-size: 18px ; color: #e0751e;">Hindi, Mangal font (Remington Gail,
                                        CBI, Inscript),</span> as well as English, Numeric fonts</p>

                            </div>
                        </div>
                        <div class="col-3">
                            <img src="assets/images/india.jpg" class="card-img-top p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6 col-sm-12 my-1">
                <div class="card p-1 ">
                    <div class="row">
                        <div class="col-9">
                            <div class="card-body p-1 ">
                                <h5 class="card-title mb-0">Users</h5>
                                <p class="card-text">Alice is a freelance web designer and developer based in London. She is specialized in HTML5, CSS3, JavaScript, Bootstrap, etc.</p>

                            </div>
                        </div>
                        <div class="col-3">
                            <img src="assets/images/Custom-Users.png" class="card-img-top p-1" alt="...">
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</section>

<section class="package" id="packages">
    <div class="container">
        <div class=" py-4  text-center ">
            <h3 class="font-weight-700 section-title"> Online Packages </h3>
            <p class="section-text m-0 font-weight-600">Choose your package according your convinence</p>
        </div>
        <div class="row" id="package-section1">
            <div class="col-sm-12 col-md-4" *ngFor="let list of packagelist">
                <div class="card  p-1" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <div class="card-header  text-center">
                        <h2 class="card-heading ">Tez Typing Tutor  </h2>
                        <p class="card-sub-heading mb-0"><i class="fas fa-rupee-sign" aria-hidden="true"></i> {{list.price}}
                            </p><span class="caed-sub-sapn">1 Month</span>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">

                            <li class="list-group-item" style="white-space: pre-line; "> <i class="fa fa-check"
                                    aria-hidden="true"></i> Description : {{list.pdescription}} </li>
                            <!-- <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Duration :
                                {{list.duration}} {{list.dtype}} </li> -->
                            <!-- <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Price:
                                {{list.price}} </li> -->
                        </ul>
                    </div>
                    
                    <div class="card-footer  text-center">
                        <a href="javascript:void(0);" (click)="subscribePackage(list)" class="btn p-2 px-4">Buy Now </a>
                        <!-- <a href="#" onclick="alert('Launching Soon');" class="btn p-2 px-4">Buy Now </a>  -->
                    </div>
                </div>
            </div>

            <!-- <div class="row " id="package-section1">
                <div class="col-sm-12 col-md-4" *ngFor="let list of packagelist">
                    <div class="card  p-1">
                        <div class="card-header  text-center">
                            <h2 class="card-heading ">BASIC</h2>
                            <p class="card-sub-heading"><i class="fas fa-rupee-sign" aria-hidden="true"></i> 300 Par anum</p>
                        </div>
                        <h5 class="color-primary mt-3 font-weight-600">{{list.pname}}</h5>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> English & Hindi Typing Typing </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Online Live Test According to Exam pattern</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Learn Typing</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> 300+ Execise</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Increase typing speed</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Result According to Exam pattern
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> UPI - Phonepe, Google pay etc...
                            </li>
                            <li class="list-group-item" style="white-space: pre-line"> Description : {{list.pdescription}} </li>
                            <li class="list-group-item"> Duration : {{list.duration}} {{list.dtype}} </li>
                            <li class="list-group-item"> Price: {{list.price}} </li>
                        </ul>
                        <div class="card-footer  text-center">
                            <a href="javascript:void(0);" (click)="subscribePackage(list)" class="btn shadow-1 text-uppercase px-4">
                                Subscribe
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-sm-12 col-md-4">
                <div class="card  p-1">
                    <div class="card-header  text-center">
                        <h2 class="card-heading online-heading">STANDARD</h2>
                        <p class="card-sub-heading"><i class="fas fa-rupee-sign" aria-hidden="true"></i> 400 Par anum</p>
                    </div>
                    <div class="card-body card-online">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> English & Hindi Typing Typing </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Online Live Test According to Exam pattern</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Learn Typing</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> 300+ Execise</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Increase typing speed</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Result According to Exam pattern
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> UPI - Phonepe, Google pay etc...
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer  text-center">
                        <a href="#" onclick="alert('Launching Soon');" class="btn p-2 px-4">Buy Now </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 ">
                <div class="card  p-1">
                    <div class="card-header  text-center">
                        <h2 class="card-heading online-heading">PREMUIM</h2>
                        <p class="card-sub-heading"><i class="fas fa-rupee-sign" aria-hidden="true"></i> 600 Par anum</p>
                    </div>
                    <div class="card-body card-online">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> English & Hindi Typing Typing </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Online Live Test According to Exam pattern</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Learn Typing</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> 300+ Execise</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Increase typing speed</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Result According to Exam pattern
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> UPI - Phonepe, Google pay etc...
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer  text-center">
                        <a href="#" onclick="alert('Launching Soon');" class="btn p-2 px-4">Buy Now </a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="offlinepackages" id="offlinepackages">
    <div class="container">

        <div class="py-4 text-center">
            <h3 class="font-weight-700 section-title">
                Desktop Application
            </h3>
            <p class="section-text m-0 font-weight-600">These are the list of packages you can subscribe</p>

        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4">
                <div class="card  p-1" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <div class="card-header  text-center">
                        <h2 class="card-heading ">Tez Typing Tutor</h2>
                        <p class="card-sub-heading"><i class="fas fa-rupee-sign" aria-hidden="true"></i> 300 
                        </p><span class="caed-sub-sapn">1 Month</span>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> English & Hindi
                                Typing Typing </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Online Live Test
                                According to Exam pattern</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Learn Typing
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> 300+ Execise
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Increase typing
                                speed</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Result According
                                to Exam pattern
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> UPI - Phonepe,
                                Google pay etc...
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer  text-center">
                        <a href="#" onclick="alert('Launching Soon');" class="btn p-2 px-4">Buy Now </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="card  p-1" data-aos="flip-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <div class="card-header  text-center">
                        <h2 class="card-heading offline-cardheading">Tez Typing Tutor</h2>
                        <p class="card-sub-heading"><i class="fas fa-rupee-sign" aria-hidden="true"></i> 400 
                        </p><span class="caed-sub-sapn">1 Month</span>
                    </div>
                    <div class="card-body card-offline">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> English & Hindi
                                Typing Typing </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Online Live Test
                                According to Exam pattern</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Learn Typing
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> 300+ Execise
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Increase typing
                                speed</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Result According
                                to Exam pattern
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> UPI - Phonepe,
                                Google pay etc...
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer  text-center">
                        <a href="#" onclick="alert('Launching Soon');" class="btn p-2 px-4">Buy Now </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 ">
                <div class="card  p-1" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <div class="card-header  text-center">
                        <h2 class="card-heading offline-cardheading">Tez Typing Tutor</h2>
                        <p class="card-sub-heading"><i class="fas fa-rupee-sign" aria-hidden="true"></i> 600 
                        </p><span class="caed-sub-sapn">12 Month</span>
                    </div>
                    <div class="card-body card-offline">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> English & Hindi
                                Typing Typing </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Online Live Test
                                According to Exam pattern</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Learn Typing
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> 300+ Execise
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Increase typing
                                speed</li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> Result According
                                to Exam pattern
                            </li>
                            <li class="list-group-item"> <i class="fa fa-check" aria-hidden="true"></i> UPI - Phonepe,
                                Google pay etc...
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer  text-center">
                        <a href="#" onclick="alert('Launching Soon');" class="btn p-2 px-4">Buy Now </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="contactus" id="contactus">
    <div class="container-fluid w-100">
        <div class="row">
            <div class="col-sm-12 col-md-5 text-center  left-contactus">

                <h3 class="font-weight-700 contactus-title mb-0 text-left">
                    Connect with us
                </h3>
                <p class="contactus-text text-left  font-weight-300">for more information and enquiry</p>

                <h5 class="font-weight-700  contactus-logo text-left">
                    <a href="https://wa.me/919509678468" target="_blank"> <img src="assets/images/whatsapp_logo.png" alt="" class="social-logo"> </a>  &nbsp;<span class="font-weight-bold">9509678468</span>
                </h5>
                <h5 class="font-weight-700 contactus-logo  text-left">
                    <a href="tel:9509678468"> <img src="assets/images/dcalling.png" class="social-logo" alt=""> </a>  &nbsp;<span class="font-weight-bold">9509678468</span>
                </h5>
                <p class="contactus-social  text-left">
                    <a href="#"><img src="assets/images/instagram.png" class="social-logo" alt=""></a>
                    <a href="#"><img src="assets/images/transparent.png" class="social-logo mx-4" alt=""></a>
                    <a href="#"> <img src="assets/images/Facebook_Logo.png" class="social-logo" alt=""></a>
                </p>
            </div>

            <div class="col-sm-12 col-md-7  right-contactus">
                <h3 class="font-weight-700 contactus-title pb-3  pl-md-4">
                    Contact us
                </h3>
                <form #cform="ngForm" novalidate>
                    <div class="row pl-md-5 ">
                        <div class="col-md-9 form-group mb-4 p-1">
                            <input type="text" name="fullname" class="form-control border-radius-5 p-2" value="" placeholder="Full Name*" data-name="fullname" [required]="true" data-prefix="Full Name" data-type="text" [(ngModel)]="inquiry.name">
                        </div>
                        <div class="col-md-9  p-1 mb-4 ">
                            <input type="email" class="form-control border-radius-5 p-2" name="email" value="" placeholder="Email*" data-name="email" [required]="true" data-prefix="Email" data-type="text" [(ngModel)]="inquiry.email">
                        </div>
                        <div class="col-md-9  p-1  mb-4">
                            <input type="text" class="form-control border-radius-5 p-2 " value="" name="website" placeholder="Contact Number" data-name="website" data-prefix="Contact" data-type="text" [(ngModel)]="inquiry.website">
                        </div>
                        <div class="col-md-9 form-group  p-1 mb-4">
                            <textarea name="message" class="form-control border-radius-5 p-2" rows="10" maxlength="500" placeholder="Message*" data-name="message" [required]="true" data-prefix="Message" data-type="text" [(ngModel)]="inquiry.message"></textarea>
                        </div>
                        <div class="col-md-9 items ">
                            <button type="button" class="border-radius-5 btn btn-theam-secondary btn-contact mt-2" (click)="contactUs(cform)" appValidation [form]="cform" [validparent]="'form-group'">Send
                            Message</button>
                        </div>
                    </div>
                </form>
            </div>


        </div>
    </div>
</section> -->







<section class="faqs" id="faqs">
    <div class="container" data-aos="flip-up" data-aos-easing="ease-out-cubic" data-aos-duration="3000">

        <div class="mb-4 mb-lg-4 py-4  text-center">
            <h3 class="font-weight-700 section-title">
                FAQ
            </h3>
            <p class="section-text m-0 font-weight-600">Frequently asked questions</p>

        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="accordion" id="faq">
                    <div class="mb-3">
                        <div class="faq-text-heading  font-weight-700 p-3 pointer rounded title position-relative"
                            data-toggle="collapse" data-target="#one" aria-expanded="true" aria-controls="one">
                            <span style="font-size: 18px;">1. How much does teztyping.com cost?</span>
                            <span class="open-Close-A">
                                <i class="fas fa-angle-right"></i>
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </div>
                        <div id="one" class="collapse show" data-parent="#faq">
                            <p class="faq-text p-3">
                                Creating account on teztyping.com is free but to use its services you need to subscribe
                                to the available packages and its price may vary according to the services which are
                                provided in it. To get more information related to packages and its subscribtion
                                rates please visit the its section.
                            </p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="faq-text-heading font-weight-700 p-3 pointer rounded title position-relative collapsed"
                            data-toggle="collapse" data-target="#two" aria-expanded="true" aria-controls="two">

                            <span style="font-size: 18px;">2. How do students reset lost passwords?</span>
                            <span class="open-Close-A">
                                <i class="fas fa-angle-right"></i>
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </div>
                        <div id="two" class="collapse" data-parent="#faq">
                            <p class="faq-text p-3">
                                You can reset your passwords from the Login page of teztyping.com.

                            </p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="faq-text-heading font-weight-700 p-3 pointer rounded title position-relative collapsed"
                            data-toggle="collapse" data-target="#three" aria-expanded="true" aria-controls="three">
                            <span style="font-size: 18px;"> 3. I accidentally deleted my student's account, can I
                                recover the data from that account?</span>
                            <span class="open-Close-A">
                                <i class="fas fa-angle-right"></i>
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </div>
                        <div id="three" class="collapse" data-parent="#faq">
                            <p class="faq-text p-3">
                                Unfortunately once an account has been deleted the data can not be recovered.
                            </p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="faq-text-heading font-weight-700 p-3 pointer rounded title position-relative collapsed"
                            data-toggle="collapse" data-target="#four" aria-expanded="true" aria-controls="four">
                            <span style="font-size: 18px;">4. How is the time spent typing calculated?</span>
                            <span class="open-Close-A">
                                <i class="fas fa-angle-right"></i>
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </div>
                        <div id="four" class="collapse" data-parent="#faq">
                            <p class="faq-text p-3">
                                Time spent typing is calculated from the time the student starts and stops the exam. It
                                also includes the timed tests.
                            </p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="faq-text-heading font-weight-700 p-3 pointer rounded title position-relative collapsed"
                            data-toggle="collapse" data-target="#five" aria-expanded="true" aria-controls="five">
                            <span style="font-size: 18px;">5. Can I disable the backspace key?</span>
                            <span class="open-Close-A">
                                <i class="fas fa-angle-right"></i>
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </div>
                        <div id="five" class="collapse" data-parent="#faq">
                            <p class="faq-text p-3">
                                The backspace key can be disabled.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-footer></app-footer>
<!-- SVG ICONS -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: none;">
    <defs>
        <g id="icon-1">
            <g>
                <path d="M41.9,39.2c-6.3,0-11.6-5.5-11.6-12v-1h23.2v1C53.5,33.7,48.2,39.2,41.9,39.2z M32.4,28.1c0.5,5,4.7,9,9.6,9
                                                s9.1-4,9.6-9H32.4z"></path>
                <polygon points="51.7,27.3 41.9,9.1 32.2,27.3 30.4,26.4 41.9,4.9 53.4,26.4 	"></polygon>
                <path d="M11.8,39.2c-6.3,0-11.6-5.5-11.6-12v-1h23.2v1C23.4,33.7,18.1,39.2,11.8,39.2z M2.2,28.1c0.5,5,4.7,9,9.6,9
                                                s9.1-4,9.6-9H2.2z"></path>
                <polygon points="21.5,27.3 11.8,9.1 2,27.3 0.3,26.4 11.8,4.9 23.3,26.4 	"></polygon>
                <rect x="10.9" y="4.2" width="32" height="2"></rect>
                <rect x="25.9" y="0.2" width="2" height="43.3"></rect>
                <rect x="17.9" y="43.2" width="18" height="2"></rect>
            </g>
        </g>
        <g id="icon-enginnering">
            <path class="st0" d="M38,24v3h-4.3c-0.4,1.6-1,3.2-1.9,4.5l3.1,3.1l-4.2,4.2l-3.1-3.1c-1.4,0.9-2.9,1.5-4.5,1.9V42h-6v-4.3
                    c-1.6-0.4-3.2-1-4.5-1.9l-3.1,3.1l-4.2-4.2l3.1-3.1c-0.9-1.4-1.5-2.9-1.9-4.5H2v-3" />
            <path class="st0" d="M12,24c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8" />
            <polyline class="st0" points="39,17 39,21 20,21 1,21 1,17 	" />
            <polyline class="st0" points="16,12 16,1 24,1 24,12 	" />
            <path class="st0" d="M12,9V3C7.2,5.7,4,9.8,4,16v2" />
            <path class="st0" d="M36,18v-2c0-6.2-3.1-10.3-8-13v6" />
        </g>
        <g id="icon-health">
            <path class="st0" d="M9.5,31.6c6.3,6.2,14,10.2,14,10.2s22-11.4,22-25.7c0-6.9-5.3-12.6-11.8-12.6" />
            <path class="st0" d="M13.3,3.5C6.8,3.5,1.5,9.2,1.5,16.1c0,3.7,1.5,7.2,3.7,10.4" />
            <circle class="st0" cx="16.5" cy="4.5" r="3" />
            <circle class="st0" cx="30.5" cy="4.5" r="3" />
            <path class="st0" d="M54.5,46.5V48c0,8.5-7,15.5-15.5,15.5l0,0c-8.5,0-15.5-7-15.5-15.5v-5.5" />
            <path class="st0" d="M50.5,49.6c-3-1.5-5-4.5-5-8.1c0-5,4-9,9-9c5,0,9,4,9,9c0,3.5-2,6.6-5,8.1" />
            <circle class="st0" cx="54.5" cy="41.5" r="4" />
            <polyline class="st1" points="1.5,26.5 10.5,26.5 13.5,21.5 18.5,31.5 24.5,14.5 29.5,26.5 34.5,26.5 	" />
        </g>
        <g id="icon-article">
            <path
                d="m54 5v54a2.006 2.006 0 0 1 -2 2h-40a2.008 2.008 0 0 1 -1.63-.84 1.956 1.956 0 0 1 -.37-1.16v-54a2.006 2.006 0 0 1 2-2h40a2.006 2.006 0 0 1 2 2z"
                class="st0" />
            <path
                d="m54 16.48v42.52a2.006 2.006 0 0 1 -2 2h-40a2.008 2.008 0 0 1 -1.63-.84c9.68-4.47 32.93-17.55 43.63-43.68z"
                class="st0" />
            <path
                d="m59 17h-16a2.006 2.006 0 0 0 -2 2v10a2.006 2.006 0 0 0 2 2h4v3.98l3.98-3.98h8.02a2.006 2.006 0 0 0 2-2v-10a2.006 2.006 0 0 0 -2-2z"
                class="st0" />
            <path
                d="m21 23h-16a2.006 2.006 0 0 0 -2 2v10a2.006 2.006 0 0 0 2 2h8.02l3.98 3.98v-3.98h4a2.006 2.006 0 0 0 2-2v-10a2.006 2.006 0 0 0 -2-2z"
                class="st0" />
            <path d="m20 3v7l-2-3-2 3v-7z" class="st0" />
            <path d="m26 21h12v2h-12z" />
            <path d="m26 25h12v2h-12z" />
            <path d="m26 29h12v2h-12z" />
            <path d="m26 33h12v2h-12z" />
            <path d="m22 40h4v2h-4z" />
            <path d="m28 40h20v2h-20z" />
            <path d="m20 44h14v2h-14z" />
            <path d="m36 44h4v2h-4z" />
            <path d="m42 44h6v2h-6z" />
            <path d="m16 44h2v2h-2z" />
            <path d="m16 48h32v2h-32z" />
            <path d="m16 52h8v2h-8z" />
            <path d="m26 52h22v2h-22z" />
            <path d="m32 18a5 5 0 0 0 3.75-1.693l-1.5-1.323a3 3 0 1 1 0-3.968l1.5-1.323a5 5 0 1 0 -3.75 8.307z" />
            <path d="m7 27h12v2h-12z" />
            <path d="m7 31h12v2h-12z" />
            <path
                d="m59 16h-4v-11a3 3 0 0 0 -3-3h-40a3 3 0 0 0 -3 3v17h-4a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h4v21a3 3 0 0 0 3 3h40a3 3 0 0 0 3-3v-27h4a3 3 0 0 0 3-3v-10a3 3 0 0 0 -3-3zm-40-12v2.7l-.168-.252a1 1 0 0 0 -1.664 0l-.168.252v-2.7zm-15 31v-10a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 0 -1 1v1.567l-2.274-2.274a1 1 0 0 0 -.707-.293h-8.019a1 1 0 0 1 -1-1zm49 24a1 1 0 0 1 -1 1h-40a1 1 0 0 1 -1-1v-21h1.6l3.689 3.689a1 1 0 0 0 1.711-.707v-2.982h3a3 3 0 0 0 3-3v-10a3 3 0 0 0 -3-3h-10v-17a1 1 0 0 1 1-1h3v6a1 1 0 0 0 1.832.555l1.168-1.755 1.168 1.752a1 1 0 0 0 1.832-.552v-6h31a1 1 0 0 1 1 1v11h-10a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h3v2.982a1 1 0 0 0 1.707.707l3.693-3.689h1.6zm7-30a1 1 0 0 1 -1 1h-8.019a1 1 0 0 0 -.707.293l-2.274 2.274v-1.567a1 1 0 0 0 -1-1h-4a1 1 0 0 1 -1-1v-10a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1z" />
            <path d="m45 21h12v2h-12z" />
            <path d="m45 25h12v2h-12z" />
        </g>
        <g id="icon-computer-it">
            <rect x="11.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="15.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="7.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="27.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="35.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="31.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="19.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="23.4" y="54.5" class="st0" width="2" height="2" />
            <rect x="11.4" y="58.5" class="st0" width="2" height="2" />
            <rect x="7.4" y="58.5" class="st0" width="2" height="2" />
            <path class="st0" d="M3.4,64.5h38v-18h-38V64.5z M5.4,48.5h34v14h-34V48.5z" />
            <rect x="22.5" y="18.5" class="st0" width="2" height="2" />
            <rect x="34.5" y="18.5" class="st0" width="2" height="2" />
            <rect x="30.5" y="18.5" class="st0" width="2" height="2" />
            <rect x="26.5" y="18.5" class="st0" width="2" height="2" />
            <path class="st0" d="M3.5,38.5h18.9v4h-0.9h-3.1v2h3.1h2.9h10.1h2.8h3v-2h-3h-0.8v-4h18.9c1.7,0,3-1.3,3-3v-32c0-1.7-1.3-3-3-3H3.5
                    c-1.7,0-3,1.3-3,3v32C0.5,37.2,1.8,38.5,3.5,38.5 M24.4,38.5h10.1v4.1H24.4V38.5z M56.4,35.5c0,0.6-0.4,1-1,1H37.3v0H21.5v0h-18
                    c-0.6,0-1-0.4-1-1v-3h53.9V35.5z M2.5,3.5c0-0.6,0.4-1,1-1h51.9c0.6,0,1,0.4,1,1v27H2.5V3.5z" />
            <rect x="15.4" y="58.5" class="st0" width="10" height="2" />
            <rect x="31.4" y="58.5" class="st0" width="2" height="2" />
            <rect x="35.4" y="58.5" class="st0" width="2" height="2" />
            <rect x="27.4" y="58.5" class="st0" width="2" height="2" />
            <path class="st0"
                d="M52.5,48.5h-4c-1.7,0-3,1.3-3,3v8c0,1.7,1.3,3,3,3h4c1.7,0,3-1.3,3-3v-8C55.5,49.8,54.2,48.5,52.5,48.5
                    M53.5,59.5c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1v-8c0-0.6,0.4-1,1-1h1v3h2v-3h1c0.6,0,1,0.4,1,1V59.5z" />
            <rect x="35.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="11.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="23.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="27.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="31.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="19.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="15.4" y="50.5" class="st0" width="2" height="2" />
            <rect x="7.4" y="50.5" class="st0" width="2" height="2" />
        </g>
        <g id="icon-science">
            <path class="st0" d="M18.5,2.1c0.9-0.7,2-1.1,3-1.1c4.1,0,7.7,5.8,9.2,14" />
            <path class="st0" d="M11.8,19c0.5-5.2,1.8-9.8,3.5-13" />
            <path class="st0"
                d="M31.3,19c0.2,1.6,0.2,3.3,0.2,5c0,12.7-4.5,23-10,23c-5.5,0-10-10.3-10-23c0-0.7,0-1.3,0-2" />
            <path class="st0" d="M11.5,29.4C3.6,23.5-0.7,16.5,1.6,12.5C4.4,7.7,15.5,9,26.5,15.3c3.5,2,6.6,4.3,9,6.7" />
            <path class="st0" d="M42.1,33c0,0.9-0.2,1.8-0.6,2.5c-2.8,4.8-13.9,3.5-24.9-2.8c-0.7-0.4-1.4-0.8-2-1.2" />
            <path class="st0" d="M37.5,24c0.9,1,1.7,2,2.3,3" />
            <path class="st0" d="M9.5,38.4c3.4-0.2,7.6-1.2,12-3.2" />
            <path class="st0"
                d="M17.5,14.8c-0.3,0.2-0.7,0.4-1,0.6C5.5,21.7-1.1,30.7,1.6,35.5c0.4,0.8,1.1,1.4,1.9,1.8" />
            <path class="st0"
                d="M25.5,33.2c0.3-0.2,0.7-0.4,1-0.6c11-6.4,17.7-15.4,14.9-20.2c-2.3-4.1-10.7-3.8-19.9,0.3" />
            <ellipse transform="matrix(0.8506 -0.5257 0.5257 0.8506 0.3676 9.294)" class="st0" cx="16.5" cy="4" rx="3"
                ry="3" />
            <ellipse transform="matrix(0.8506 -0.5257 0.5257 0.8506 -19.0014 9.1148)" class="st0" cx="6.5" cy="38"
                rx="3" ry="3" />
            <ellipse transform="matrix(0.8506 -0.5257 0.5257 0.8506 -9.5678 26.3209)" class="st0" cx="41.5" cy="30"
                rx="3" ry="3" />
        </g>
        <g id="icon-business">
            <g>
                <path class="st0"
                    d="M124.6,23H66.5c-1.6,0-2.8-1.3-2.8-2.8v-8c0-6.2,5-11.2,11.2-11.2h41.4c6.2,0,11.2,5,11.2,11.2v8
                                        C127.5,21.7,126.2,23,124.6,23z M69.3,17.3h52.5v-5.2c0-3-2.5-5.5-5.5-5.5H74.8c-3,0-5.5,2.5-5.5,5.5V17.3z" />
            </g>
            <g>
                <path class="st0" d="M170.6,145.6H20.5c-6.7,0-12.1-5.4-12.1-12.1V78.2c0-0.8,0.4-1.6,1-2.2c0.7-0.5,1.5-0.8,2.3-0.6l34.3,6.4
                                        c1.3,0.3,2.3,1.4,2.3,2.8v16h10.7V87.7c0-0.8,0.4-1.6,1-2.2c0.7-0.5,1.5-0.8,2.3-0.6l33.1,6.2l33.1-6.2c0.8-0.2,1.7,0.1,2.3,0.6
                                        c0.7,0.5,1,1.3,1,2.2v12.9h10.7v-16c0-1.4,1-2.5,2.3-2.8l34.3-6.4c0.8-0.2,1.7,0.1,2.3,0.6c0.7,0.5,1,1.3,1,2.2v55.3
                                        C182.7,140.2,177.3,145.6,170.6,145.6z M14.1,81.7v51.9c0,3.5,2.9,6.4,6.4,6.4h150.2c3.5,0,6.4-2.9,6.4-6.4V81.7L148.4,87v16.5
                                        c0,1.6-1.3,2.8-2.8,2.8h-16.4c-1.6,0-2.8-1.3-2.8-2.8V91.1l-30.3,5.7c-0.3,0.1-0.7,0.1-1,0l-30.3-5.7v12.4c0,1.6-1.3,2.8-2.8,2.8
                                        H45.6c-1.6,0-2.8-1.3-2.8-2.8V87L14.1,81.7z" />
            </g>
            <g>
                <path class="st0" d="M95.6,96.9c-0.2,0-0.4,0-0.5,0l-33.6-6.3c-1.3-0.3-2.3-1.4-2.3-2.8V74.8H48.4v9.8c0,0.8-0.4,1.6-1,2.2
                                        c-0.7,0.5-1.5,0.8-2.3,0.6L10.7,81C5,80,0.9,75,0.9,69.2V29.3c0-6.7,5.4-12.1,12.1-12.1h165.2c6.7,0,12.1,5.4,12.1,12.1v39.8
                                        c0,5.8-4.1,10.8-9.8,11.9l-34.3,6.4c-0.8,0.2-1.7-0.1-2.3-0.6c-0.7-0.5-1-1.3-1-2.2v-9.8H132v12.9c0,1.4-1,2.5-2.3,2.8l-33.6,6.3
                                        C95.9,96.8,95.7,96.9,95.6,96.9z M64.8,85.4l30.8,5.8l30.8-5.8V72c0-1.6,1.3-2.8,2.8-2.8h16.4c1.6,0,2.8,1.3,2.8,2.8v9.3l30.9-5.8
                                        c3-0.6,5.2-3.2,5.2-6.3V29.3c0-3.5-2.9-6.4-6.4-6.4H13c-3.5,0-6.4,2.9-6.4,6.4v39.8c0,3.1,2.2,5.7,5.2,6.3l30.9,5.8V72
                                        c0-1.6,1.3-2.8,2.8-2.8h16.4c1.6,0,2.8,1.3,2.8,2.8V85.4z" />
            </g>
            <g>
                <path class="st0"
                    d="M95.6,96.9c-1.3,0-2.5-1-2.8-2.3c-0.3-1.5,0.7-3,2.3-3.3l33.6-6.3c1.6-0.3,3,0.7,3.3,2.3c0,0.2,0,0.4,0,0.5
                                        c0,1.3-0.9,2.5-2.3,2.8c0,0,0,0-0.1,0l-33.6,6.3C95.9,96.8,95.7,96.9,95.6,96.9z" />
            </g>
            <g>
                <path class="st0"
                    d="M45.6,87.5c-0.2,0-0.4,0-0.5,0L10.7,81c-1.4-0.3-2.3-1.5-2.3-2.8c0-0.2,0-0.3,0-0.5c0.3-1.5,1.8-2.6,3.3-2.3
                                        l34.3,6.4c1.5,0.3,2.6,1.8,2.3,3.3c-0.1,0.5-0.3,1-0.7,1.4C47.2,87.1,46.4,87.5,45.6,87.5C45.6,87.5,45.6,87.5,45.6,87.5z" />
            </g>
            <g>
                <path class="st0"
                    d="M95.6,96.9c-0.2,0-0.4,0-0.5,0l-33.6-6.3c-1.4-0.3-2.3-1.5-2.3-2.8c0-0.2,0-0.3,0-0.5
                                        c0.3-1.5,1.8-2.6,3.3-2.3l33.6,6.3c1.4,0.3,2.3,1.5,2.3,2.8c0,0.2,0,0.3,0,0.5C98.1,95.9,96.9,96.9,95.6,96.9z" />
            </g>
            <g>
                <path class="st0" d="M145.6,87.5C145.6,87.5,145.6,87.5,145.6,87.5c-0.8,0-1.6-0.4-2.1-1c0,0,0,0,0,0c0,0,0,0,0,0
                                        c-0.3-0.4-0.6-0.8-0.7-1.4c-0.3-1.5,0.7-3,2.3-3.3l34.3-6.4c1.6-0.3,3,0.7,3.3,2.3c0,0.2,0,0.4,0,0.5c0,1.3-1,2.5-2.3,2.8
                                        l-34.3,6.4C145.9,87.5,145.7,87.5,145.6,87.5z" />
            </g>
            <g>
                <path class="st0" d="M61.9,106.4H45.6c-1.6,0-2.8-1.3-2.8-2.8V72c0-1.6,1.3-2.8,2.8-2.8h16.4c1.6,0,2.8,1.3,2.8,2.8v31.5
                                        C64.8,105.1,63.5,106.4,61.9,106.4z M48.4,100.7h10.7V74.8H48.4V100.7z" />
            </g>
        </g>
        <g id="icon-education">
            <path class="st0" d="M1.5,14.2l12.6,4.8v13c0,0.9,0.5,2.3,1.3,3.1c4,3.6,10.1,5.6,16.7,5.6c6.6,0,12.7-2.1,16.7-5.6
                    c0.8-0.7,1.3-2.1,1.3-3.1V19.1l3.1-1.2c0.4-0.1,0.5-0.5,0.4-0.9c-0.1-0.4-0.5-0.5-0.9-0.4l-19.5,7.5c-0.6,0.2-1.7,0.2-2.3,0
                    l-28.7-11L31,2.1c0.6-0.2,1.7-0.2,2.3,0l28.7,11l-2.7,1c-0.4,0.1-0.5,0.5-0.4,0.9c0.1,0.4,0.5,0.5,0.9,0.4l3-1.1
                    c0.9-0.3,1-0.9,1-1.2c0-0.3-0.1-0.8-1-1.2L33.8,0.8c-0.9-0.4-2.4-0.4-3.3,0L1.5,11.9c-0.9,0.3-1,0.9-1,1.2
                    C0.5,13.4,0.6,13.9,1.5,14.2z M32.1,25.6c0.6,0,1.2-0.1,1.6-0.3l15-5.8V32c0,0.7-0.4,1.6-0.8,2c-3.7,3.3-9.6,5.3-15.8,5.3
                    c-6.2,0-12.1-2-15.8-5.3c-0.4-0.4-0.8-1.4-0.8-2V19.6l15,5.8C30.9,25.5,31.5,25.6,32.1,25.6z" />
            <path class="st0" d="M53.3,26.8c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1.7-1.2-3.2-2.9-3.5v-6.8c0-1.2-0.9-2.2-2.1-2.4
                    l-22.8-3.4c-0.4-0.1-0.7,0.2-0.8,0.6c-0.1,0.4,0.2,0.7,0.6,0.8l22.8,3.4c0.5,0.1,0.9,0.6,0.9,1.1v6.8
                    C54.6,23.6,53.3,25.1,53.3,26.8z M59.1,26.8c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
                    C58.1,24.6,59.1,25.6,59.1,26.8z" />
            <path class="st0" d="M52.7,38.6c0.4,0.4,0.9,0.7,1.5,0.7h5.4c0.6,0,1.2-0.3,1.5-0.7c0.4-0.4,0.5-1,0.4-1.6l-1.3-5.8
                    c-0.1-0.4-0.5-0.6-0.8-0.5c-0.4,0.1-0.6,0.5-0.5,0.8l1.3,5.8c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-5.4
                    c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5l1.3-5.8c0.1-0.4-0.2-0.7-0.5-0.8c-0.4-0.1-0.7,0.2-0.8,0.5l-1.3,5.8
                    C52.2,37.5,52.4,38.1,52.7,38.6z" />
        </g>
        <g id="icon-location">
            <path class="st0" d="M598.7,242.2c97,0,184.7,39.3,248.3,102.8c63.5,63.5,102.8,151.3,102.8,248.3c0,97-39.3,184.7-102.8,248.3
                c-63.5,63.5-151.3,102.8-248.3,102.8S414,905.1,350.4,841.6c-63.5-63.5-102.8-151.3-102.8-248.3c0-97,39.3-184.8,102.8-248.3
                C414,281.5,501.8,242.2,598.7,242.2L598.7,242.2z M807.1,385c-53.3-53.3-127-86.3-208.3-86.3s-155,33-208.3,86.3
                c-53.3,53.3-86.3,127-86.3,208.3c0,81.4,33,155,86.3,208.3c53.3,53.3,127,86.3,208.3,86.3c81.4,0,155-33,208.3-86.3
                c53.3-53.3,86.3-127,86.3-208.3C893.4,512,860.4,438.3,807.1,385L807.1,385z" />
            <path class="st0" d="M598.7,1521.2c39.7-54.5,80.5-107.7,121.3-160.9c48.8-63.6,97.7-127.3,143.2-191.3l0,0
                c20.3-29.1,34.2-48.5,48-67.8c108.9-152,220.4-307.6,224.3-497.5l0.1-5.3l0-5h-0.1c0-148.2-60.1-282.5-157.2-379.6
                C881.2,116.6,747,56.5,598.7,56.5s-282.5,60.1-379.6,157.2C122,310.9,61.9,445.1,61.9,593.3v10.3c3.9,189.9,115.3,345.4,224.3,497.5
                c13.9,19.3,27.7,38.7,48,67.8l0,0c45.5,64,94.4,127.7,143.2,191.3C518.2,1413.4,559.1,1466.6,598.7,1521.2L598.7,1521.2z
                 M764.8,1394.4c-48.8,63.6-97.7,127.3-143.2,191.3l-22.9,32.3l-22.9-32.3c-45.5-64-94.4-127.7-143.2-191.3
                c-48.7-63.5-97.5-127.1-144.3-192.8l-0.1-0.2c-11.1-15.9-29.5-41.6-47.9-67.3C126.4,975.1,9.8,812.4,5.6,604.8l-0.2-11.4
                c0-163.8,66.4-312.2,173.8-419.5C286.5,66.4,434.9,0,598.7,0s312.2,66.4,419.5,173.8C1125.6,281.1,1192,429.5,1192,593.3l-0.1,0.1
                l0,5.5v0.3l-0.1,5.4v0.1c-4.2,207.7-120.7,370.3-234.6,529.3c-18.5,25.8-36.9,51.4-47.9,67.3l-0.1,0.2
                C862.3,1267.3,813.6,1330.9,764.8,1394.4L764.8,1394.4z" />
            <path class="st0"
                d="M877.8,1330.1c46.2,10.9,88.5,24.3,125.9,39.8c113,46.9,182.9,114.6,182.9,191.9c0,77.3-69.9,145-182.9,191.9
                c-105.5,43.8-250.7,70.9-410.4,70.9s-304.9-27.1-410.4-70.9C69.9,1706.8,0,1639.1,0,1561.8c0-77.3,69.9-145,182.9-191.9
                c39.8-16.5,85.3-30.7,135.1-41.9c12.5,16.6,25.1,33.2,37.7,49.7c-56.5,11.2-107.5,26.2-151.1,44.3c-91.5,38-148.1,87.6-148.1,139.8
                c0,52.2,56.6,101.9,148.1,139.8c99,41.1,236.4,66.5,388.8,66.5s289.8-25.4,388.8-66.5c91.5-38,148.1-87.6,148.1-139.8
                c0-52.2-56.6-101.9-148.1-139.8c-41.2-17.1-89-31.5-141.8-42.4C852.8,1363.1,865.4,1346.6,877.8,1330.1L877.8,1330.1z" />
        </g>
        <g id="icon-social-sci">
            <g>
                <path class="st0"
                    d="M13.9,27c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2C21.1,23.7,17.8,27,13.9,27z
                         M13.9,13.5c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2S17.3,13.5,13.9,13.5z" />
            </g>
            <g>
                <path class="st0"
                    d="M5.9,11.6c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C11.6,9,9,11.6,5.9,11.6z
                         M5.9,1.2c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7C10.6,3.3,8.5,1.2,5.9,1.2z" />
            </g>
            <g>
                <path class="st0"
                    d="M6.3,38.4c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C12,35.9,9.4,38.4,6.3,38.4z
                         M6.3,28.1c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7C11,30.2,8.9,28.1,6.3,28.1z" />
            </g>
            <g>
                <path class="st0"
                    d="M30.3,25.4c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C36,22.9,33.4,25.4,30.3,25.4
                        z M30.3,15.1c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7C35,17.2,32.9,15.1,30.3,15.1z" />
            </g>
            <g>
                <path class="st0" d="M30.3,23.1v-1c1.3,0,2.4-1.1,2.4-2.4h1C33.7,21.6,32.2,23.1,30.3,23.1z" />
            </g>
            <g>
                <path class="st0" d="M3.5,5.8h-1c0-1.9,1.5-3.4,3.4-3.4v1C4.6,3.4,3.5,4.5,3.5,5.8z" />
            </g>
            <g>
                <path class="st0"
                    d="M6.3,35.8c-0.9,0-1.7-0.3-2.4-1l0.7-0.7C5.5,35,7,35,8,34.1l0.7,0.7C8,35.5,7.2,35.8,6.3,35.8z" />
            </g>
            <g>
                <rect x="20.6" y="19.3" class="st0" width="4.5" height="1" />
            </g>
            <g>
                <rect x="9" y="10.1" transform="matrix(0.8665 -0.4991 0.4991 0.8665 -4.7968 6.3579)" class="st0"
                    width="1" height="4.1" />
            </g>
            <g>
                <rect x="8.1" y="26.4" transform="matrix(0.5072 -0.8618 0.8618 0.5072 -18.4166 21.6443)" class="st0"
                    width="3.2" height="1" />
            </g>
        </g>
        <g id="icon-art-sci">
            <g>
                <path class="st0" d="M288.4,418.7c-1.1,0-2.3-0.3-3.3-0.8c-2.2-1.1-3.7-3.2-4.1-5.6c-3.2-21,11.3-62.3,44.2-126.3
                        c26.8-51.9,59.5-106.2,76.6-134c2.2-3.5,6.8-4.6,10.3-2.4c3.5,2.2,4.6,6.8,2.4,10.3c-69.7,113-112.5,199.2-118.4,237.6
                        c27.7-19,55.5-51.6,82.9-97c26.3-43.6,46.4-89.7,58.7-120.6c1.5-3.9,5.9-5.7,9.7-4.2c3.9,1.5,5.7,5.9,4.2,9.7
                        c-27.3,68.8-84.3,190.6-159.7,232.3C290.9,418.4,289.7,418.7,288.4,418.7z" />
            </g>
            <g>
                <path class="st0" d="M464.2,159.8C464.2,159.8,464.2,159.8,464.2,159.8c-21.8,0-44.1-12.5-52.9-29.8c-7.3-14.3-8.2-29.8-2.3-44.8
                        c7.5-19.4,25.8-36.4,47.7-44.5c1-0.4,1.9-0.7,2.9-1.1c10.9-4,24.4-8.9,28-16.8c1.5-3.3,1.3-7.2-0.7-12.1c-1.2-3.1-0.3-6.6,2.4-8.6
                        c2.6-2,6.2-2.1,9-0.2c13.4,9.4,23.8,28,30.9,55.1c4.6,17.4,3.7,34.4-2.8,54.9c-6.6,21-22.3,32.5-38.3,41.9
                        C481.3,157.7,473.1,159.8,464.2,159.8z M501.7,27.9c-0.1,0.4-0.3,0.7-0.5,1.1c-6.1,13.6-23,19.8-36.5,24.8c-1,0.4-1.9,0.7-2.8,1
                        c-17.7,6.6-33,20.7-39,35.9c-4.3,11-3.7,21.9,1.7,32.5c6.3,12.3,23.3,21.6,39.6,21.6c0,0,0,0,0,0c6.2,0,11.8-1.4,16.3-4
                        c15.5-9.1,26.8-18.3,31.6-33.5c5.7-17.9,6.4-31.8,2.6-46.6C511.1,47.2,506.7,36.2,501.7,27.9z" />
            </g>
            <g>
                <path class="st0"
                    d="M168.9,469.8c-9.5,0-18.9-1.3-28.4-3.9c-16.7-4.6-35.8-10.4-51.6-20.9c-34-22.5-54.7-59.3-66-86.3
                        C8.6,325.1,0.5,286.6,0.5,253.1c0-66,25.7-128.1,72.4-174.8C119.6,31.6,181.7,5.9,247.7,5.9c1.8,0,3.7,0,5.5,0.1
                        c50,1.1,98.3,17.4,139.5,47.3c3.4,2.4,4.1,7.1,1.7,10.5c-2.4,3.4-7.1,4.1-10.5,1.7c-38.8-28-84.1-43.4-131-44.4
                        c-1.7,0-3.4-0.1-5.2-0.1c-128.1,0-232.2,104.2-232.2,232.2c0,60.8,28.6,144.3,81.6,179.4c14,9.3,31.8,14.6,47.4,18.9
                        c24.3,6.7,48.4,3.4,76-10.4c24.5-12.2,26.8-35.2,29.4-61.8c2.4-24.2,5.1-51.6,27.2-71.4c25.5-22.8,60.1-11.5,90.6-1.6
                        c14.3,4.7,27.8,9.1,39.3,9.8c1.3,0.1,2.5,0.1,3.7,0.1c38,0,54.6-38.1,61.1-61.4c0.5-1.7,0.9-3.4,1.3-5.1
                        c3.3-14.2,3.9-28.5,1.6-43.7c-0.6-4.1,2.2-7.9,6.3-8.5c4.1-0.6,7.9,2.2,8.5,6.3c2.4,16.9,1.8,33.5-1.9,49.3
                        c-0.4,1.9-0.9,3.9-1.5,5.8c-5.9,20.9-15,38.3-26.4,50.5c-14.7,15.7-32.8,23-53.8,21.7c-13.3-0.8-27.8-5.5-43-10.5
                        c-28-9.1-56.9-18.6-76-1.5c-17.7,15.9-19.9,38.1-22.3,61.7c-2.8,28.4-5.8,57.9-37.7,73.8C206.6,464.7,187.5,469.8,168.9,469.8z" />
            </g>
            <g>
                <path class="st0" d="M157.7,412.5c-22.4,0-40.7-18.2-40.7-40.7c0-22.4,18.2-40.7,40.7-40.7s40.7,18.2,40.7,40.7
                        C198.4,394.2,180.1,412.5,157.7,412.5z M157.7,346.1c-14.2,0-25.7,11.5-25.7,25.7s11.5,25.7,25.7,25.7c14.2,0,25.7-11.5,25.7-25.7
                        S171.8,346.1,157.7,346.1z" />
            </g>
            <g>
                <path class="st0" d="M93.3,304.5c-24.3,0-44.1-19.8-44.1-44.1c0-24.3,19.8-44.1,44.1-44.1c24.3,0,44.1,19.8,44.1,44.1
                        C137.4,284.7,117.6,304.5,93.3,304.5z M93.3,231.3c-16,0-29.1,13-29.1,29.1c0,16,13,29.1,29.1,29.1c16,0,29.1-13,29.1-29.1
                        C122.4,244.4,109.3,231.3,93.3,231.3z" />
            </g>
            <g>
                <path class="st0" d="M126.7,188.5c-26.3,0-47.7-21.4-47.7-47.7c0-26.3,21.4-47.7,47.7-47.7c26.3,0,47.7,21.4,47.7,47.7
                        C174.4,167.1,153,188.5,126.7,188.5z M126.7,108.1c-18,0-32.7,14.7-32.7,32.7c0,18,14.7,32.7,32.7,32.7c18,0,32.7-14.7,32.7-32.7
                        C159.4,122.8,144.7,108.1,126.7,108.1z" />
            </g>
            <g>
                <path class="st0" d="M248.7,137.5c-24.1,0-43.7-19.6-43.7-43.7s19.6-43.7,43.7-43.7c24.1,0,43.7,19.6,43.7,43.7
                        S272.8,137.5,248.7,137.5z M248.7,65.1C232.9,65.1,220,78,220,93.8s12.9,28.7,28.7,28.7s28.7-12.9,28.7-28.7
                        S264.5,65.1,248.7,65.1z" />
            </g>
            <g>
                <path class="st0"
                    d="M341.6,164.3c-18.5,0-33.6-15.1-33.6-33.6s15.1-33.6,33.6-33.6s33.6,15.1,33.6,33.6S360.1,164.3,341.6,164.3z
                         M341.6,112.1c-10.3,0-18.6,8.3-18.6,18.6c0,10.3,8.3,18.6,18.6,18.6s18.6-8.3,18.6-18.6C360.2,120.5,351.9,112.1,341.6,112.1z" />
            </g>
            <g>
                <path class="st0" d="M410.1,250.3c-0.6,0-1.2-0.1-1.8-0.2c-16.1-4-23.2-10-34-19c-1.3-1.1-2.6-2.2-4.1-3.4
                        c-3.2-2.6-3.6-7.4-1-10.6c2.6-3.2,7.4-3.6,10.6-1c1.5,1.2,2.8,2.4,4.1,3.5c10.1,8.5,15.2,12.8,27.9,15.9c4,1,6.5,5.1,5.5,9.1
                        C416.5,248,413.4,250.3,410.1,250.3z" />
            </g>
        </g>
        <g id="icon-natural-sci">
            <path class="st0"
                d="M247.7,172.7c-1.6,1.7-39,43.2-39,78.3c0,30.5,13.9,49.7,37.5,52.4v10.2c0,3.2,2.6,5.9,5.9,5.9
                    c3.2,0,5.9-2.6,5.9-5.9v-10.2c23.6-2.7,37.5-21.8,37.5-52.4c0-35-37.4-76.5-39-78.3C254.1,170.3,249.9,170.3,247.7,172.7z
                     M257.9,291.5v-29.7l15.8-24.7c1.7-2.7,0.9-6.4-1.8-8.1c-2.7-1.7-6.3-0.9-8.1,1.8l-5.9,9.2v-19.9c0-3.2-2.6-5.9-5.9-5.9
                    c-3.2,0-5.9,2.6-5.9,5.9v41.4l-10.4-14.1c-1.9-2.6-5.6-3.2-8.2-1.2c-2.6,1.9-3.2,5.6-1.2,8.2l19.9,26.9v10.2
                    c-16.6-2.6-25.7-16.7-25.7-40.5c0-24,22-53.6,31.6-65.3c9.6,11.7,31.6,41.3,31.6,65.3C283.6,274.7,274.5,288.8,257.9,291.5z" />
            <path class="st0" d="M500.8,184.1c-9.3-35.2-68.2-52.1-157.6-45c-3.2,0.3-5.6,3.1-5.4,6.3c0.3,3.2,3.1,5.6,6.3,5.4
                    c80.8-6.4,137.9,7.9,145.3,36.3c4,15.3-6.8,34.7-30.1,54.8c-24.4-19.3-58.1-38.1-98.3-54.5c-13.4-27.5-37-49.5-67-60.6V50.1h9.6
                    c3.2,0,5.9-2.6,5.9-5.9V7.9c0-3.2-2.6-5.9-5.9-5.9H200.3c-3.2,0-5.9,2.6-5.9,5.9v36.4c0,3.2,2.6,5.9,5.9,5.9h9.6v76.6
                    c-10,3.7-19.2,8.6-27.6,14.5c-25.4-3-49.6-4.2-71.6-3.5c-2.6-13.4-14.4-23.5-28.6-23.5c-16.6,0-30.3,14.1-29,31.3
                    c-28.1,7.8-45.3,21-49.9,38.5C-1.9,203.5,9.1,226,35,249c0.2,0.2,0.4,0.3,0.5,0.5C9.9,272.2-2,295,3.2,314.7
                    c8,30.5,54.6,46.6,120.8,46.6c25.8,0,54.5-2.5,85.1-7.4c47.9,18.2,102.9,4.3,136.5-36.4c38.5-14.5,81.5-35.1,113.7-60.4
                    c23.3,20.2,34.1,39.6,30.2,54.7c-8.2,31.2-78,45.1-169.7,33.8c-7.7-0.9-9.1,10.7-1.4,11.6c54.5,6.7,168.5,10.6,182.5-42.5
                    c5.2-20-7-42.8-32.4-65.2C494.6,226.4,505.9,203.7,500.8,184.1z M206.2,38.4V13.7h91.6v24.7H206.2z M217.7,136.5c2.4-0.8,4-3,4-5.6
                    V50.1h60.6v80.8c0,2.5,1.6,4.8,4,5.6c24.2,8,44.1,23.8,57.4,44.1c-21.2-7.9-44-15.2-68-21.5c-25.3-6.6-50.6-11.8-75-15.4
                    C206.1,140.8,211.8,138.4,217.7,136.5z M82.1,126c9.6,0,17.4,7.8,17.4,17.4c0,9.6-7.8,17.4-17.4,17.4c-9.6,0-17.4-7.8-17.4-17.4
                    C64.7,133.8,72.5,126,82.1,126z M14.6,311.7c-4.1-15.4,7.2-34.8,30.2-54.6c23.5,18.5,55.5,36.4,93.4,52.3c7.2,3,11.6-7.8,4.5-10.8
                    c-36.4-15.2-66.6-32.2-88.6-49c19-14.7,44.2-29.5,74-43c2.9-1.3,4.3-4.8,2.9-7.8c-1.3-2.9-4.8-4.3-7.8-2.9
                    c-32.1,14.5-58.6,30.2-78.6,46c-23-19.8-34.2-39.1-30-54.9c4.3-16.4,24.3-25.4,41.7-30.2c4.9,9.3,14.6,15.7,25.8,15.7
                    c14,0,25.7-9.9,28.5-23c18.2-0.6,38.1,0.1,58.9,2.1c-24.2,22.4-38.7,54.3-38.7,88.8c0,44.4,24,83.2,59.6,104.4
                    C91.2,358.2,22.5,342,14.6,311.7z M236.3,348.9c28.6-5.9,56.3-13.3,83.1-22.2C296.4,344.7,266.8,353.2,236.3,348.9z M338.4,307.6
                    c-42.1,15.7-87,27.3-128,34.2c-39.8-16.4-68-55.6-68-101.3c0-34.9,16.4-66.8,43.1-87.1c28.1,3.5,57.6,9.3,87.2,17
                    c29,7.6,55.6,16.4,79.6,26c6,13.6,9.2,28.5,9.2,44.1C361.5,265.8,352.9,289.1,338.4,307.6z M357.5,300.2c10-17.6,15.8-38,15.8-59.7
                    c0-13.1-2.1-25.9-6.1-37.9c34.3,14.8,62.2,31,82.8,46.9C423.1,270.1,388.8,287.3,357.5,300.2z" />
        </g>
    </defs>
</svg>