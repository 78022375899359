<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="note-form-single">
    <div class="h-100 m-0 position-absolute row w-100">
        <div class="align-items-center bg-white col-lg-6 col-md-12 d-flex justify-content-center m-auto p-0 p-4 shadow border-top-5 border-bottom-5 border-dark"
            style="margin-top: 100px !important;">
            <div class="w-100 text-center">
                <div class="pb-4 mb-2 text-center">
                    <img src="assets/images/Capture.PNG">
                </div>
                <h1 class="mb-3 text-uppercase">Thank You!</h1>
                <h1 class="mb-2 text-success" style="font-size:50px;">
                    <i class="far fa-check-circle"></i>
                </h1>
                <h5 class="mb-3 text-success">Payment successfull</h5>
                <p class="mb-2">
                    <a href="">Thank you for your business
                        <i class="fas fa-caret-right"></i>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>