import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
import { LoaderService } from './services/message/loader.service';
declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'teztypingweb';
  constructor(
    private router: Router,
    public loadService: LoaderService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (isPlatformBrowser(this._platformId)) {
          $('[data-toggle="tooltip"]').tooltip('dispose');
          // console.clear();
          if (environment.production) {
            if (window) {
              window.console.log = function () { };
            }
          }
        }
      }
    });
  }
}
