import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TestService } from 'src/app/admin/services/test.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';

declare const $: any;
@Component({
  selector: 'app-livetest',
  templateUrl: './livetest.component.html',
  styleUrls: ['./livetest.component.scss']
})
export class LivetestComponent implements OnInit {
  testScrores = [];
  testList = [];
  testtype = '';
  pageindex = 1;
  pagesize = 10;
  testcount = 0;
  componentDestroyed$: Subject<boolean> = new Subject();
  showTestResults = 1;
  testDetails: any = {};
  paradiff = '';
  isexpired = true;
  userid = 0;
  userdata: any;
  constructor(
    private testService: TestService,
    private adataservice: AuthdataService,
    private mservice: ToastService,
    public loadService: LoaderService,
    public router: Router,
    private titleService: Title
  ) { 
    this.titleService.setTitle("Tez Typing Live hindi and english typing test");
  }
  ngOnInit(): void {
    this.getTest();
    
    this.userdata = (this.adataservice.getUserData()) ? this.adataservice.getUserData() : [];
    
    let subscriptiondate = (this.userdata && this.userdata.length > 0) ? this.userdata[0].subscriptiondate : null;
    
    var today = new Date();
   
    if (subscriptiondate != null) {
      var newDate = new Date(subscriptiondate);
      if (this.userdata[0].dtype == 'month') {
        const expireddate = new Date(newDate.setMonth(newDate.getMonth() + this.userdata[0].duration));
        if (today > expireddate) {
          this.isexpired = true;
        } else {
          this.isexpired = false;
        }
      } else {
        const expireddate = new Date(newDate.setFullYear(newDate.getFullYear() + this.userdata[0].duration));
        if (today > expireddate) {
          this.isexpired = true;
        } else {
          this.isexpired = false;
        }
      }
    }
  }
  getTest() {
    if (localStorage.getItem('llanguage')) {
      this.testtype = localStorage.getItem('llanguage');
    }
    this.userid = (this.adataservice.isAuthenticated()) ? this.adataservice.getUserId() : 0;
    this.testService.getFreeTest(this.testtype, 1, this.pageindex, this.pagesize, this.userid).subscribe(data => {
      if (data.flag) {
        if (data[0].length > 0) {
          this.testList = data[0];
          
          this.testcount = data[1][0].testcount;
        } else {
          this.testList = [];
          this.testcount = 0;
          
        } console.log(this.testList);
      }
    });
  }
  getTestRank(testid) {
    this.testService.getTestRank(testid).subscribe(data => {
      if (data.flag) {
        this.testScrores = data[0];
        this.showTestResults = 3;
        
      }
    });
  }
  getTestBytype(type) {
    if (type !== this.testtype) {
      localStorage.setItem('llanguage', type);
      this.testList = [];
      this.testtype = type;
      this.pageindex = 1;
      this.getTest();
      
    }
  }
  pageChanged(event) {
    this.pageindex = event;
    this.getTest();
  }

  public trackByIndex(index: number) {
    return index;
  }
  getTestResults(testid) {
    // alert(testid);
    this.loadService.loadme = true;
    this.testService.getTestResultsByID(testid, this.adataservice.getUserId()).pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      if (data.flag) {
        if (data[0].length > 0) {
          this.testDetails = data[0][0];
          let udata = this.testDetails.paragraph.split(' ');
          let odata = this.testDetails.content.split(' ');
          let pdata = [];
          
          for (let j = 0; j < odata.length; j++) {
            if (j < udata.length && udata[j] !== odata[j]) {
              let uidata = udata[j].split('');
              let oidata = odata[j].split('');
              let mydata = '';
              for (let k = 0; k < oidata.length; k++) {
                if (k < uidata.length && uidata[k] !== oidata[k]) {
                  mydata += ('<span class="failed">' + oidata[k] + '</span>');
                } else {
                  mydata += oidata[j];
                }
              }
              pdata.push(mydata);
            } else {
              pdata.push(odata[j]);
            }
          }
          this.paradiff = pdata.join(' ');
          this.getResultByExam(this.testDetails.passingmarks);
          this.showTestResults = 2;
          // alert(pdata);
          // alert(JSON.stringify(this.testDetails));
        } else {
          this.mservice.generateMessage('ERROR', 'Details not found for this test', '');
        }
        this.loadService.loadme = false;
      } else {
        this.mservice.generateMessage('ERROR', data.message, '');
        this.loadService.loadme = false;
      }
    });

    

  }
  getResultByExam(exam) {
    
    this.testDetails.ra = exam;
    
    this.testDetails.result = (this.testDetails.ga >= this.testDetails.ra) ? 'Qualified' : 'Not Qualified';
  }

  
  redirectToPackages() {
    $.confirm({
      icon: 'fas fa-trash-alt',
      title: 'Subscribe?',
      content: 'You have not subscribed to this package.',
      type: '',
      draggable: false,
      typeAnimated: true,
      buttons: {
        subscribe: {
          text: 'Subscribe',
          btnClass: 'btn-blue',
          action: () => {
            this.router.navigate([''], { fragment: 'packages' });
          }
        },
        Cancel: () => {
        }
      }
    });
  }

}
