import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeaturesComponent } from './components/features/features.component';
import { HomeComponent } from './components/home/home.component';
import { PackagedetailsComponent } from './components/package-details/packagedetails.component';
import { LearnTypingComponent } from './components/learn-typing/learn-typing.component';
import { LearnTypinglistComponent } from './components/learn-typing-list/learn-typing-list.component';
import { LearningTestSetsComponent } from './components/learning-test-sets/learning-test-sets.component';
import { LearningTestComponent } from './components/learning-test/learning-test.component';
import { LivetestComponent } from './components/livetest/livetest.component';
import { LtypingComponent } from './components/ltyping/ltyping.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { TypingTestComponent } from './components/typing-test/typing-test.component';
import { ViewvideoComponent } from './components/viewvideo/viewvideo.component';
import { WatchvideosComponent } from './components/watchvideos/watchvideos.component';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../app/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'user',
    loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'practice-test',
    component: LtypingComponent
  },
  {
    path: 'package-details',
    component: PackagedetailsComponent
  },
  {
    path: 'learn-typing',
    component: LearnTypingComponent
  },
  {
    path: 'learn-typing-list',
    component: LearnTypinglistComponent
  },
  {
    path: 'learn-typing-test/:index/:language',
    component: LearningTestSetsComponent
  },
  {
    path: 'learn-typing-keytest/:id/:flag',
    component: LearningTestComponent
  },
  {
    path: 'live-test',
    component: LivetestComponent
  },
  {
    path: 'typing/practice/:id/:testtype',
    component: TypingTestComponent
  },
  {
    path: 'thankyou',
    component: ThankyouComponent
  },
  {
    path: 'features',
    component: FeaturesComponent
  },
  {
    path: 'watch-videos',
    component: WatchvideosComponent
  },
  {
    path: 'viewvideo/:id',
    component: ViewvideoComponent
  },
  {
    path: 'tutorials',
    component: TutorialComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
