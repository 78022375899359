<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer" style="height: 60.1094px;"></div>
<section class="pb-4">
    <div style="height: 100%;width: 100%;border-style: ridge;">
        <div class="row">
            <!-- <div class="col-md-5 p-4">
                <section class="fieldset">
                    <h1>Learn Typing</h1>
                    <div class="row">
                        <div class="col-md-4">
                            <a [routerLink]="['/learn-typing-keytest',1|encdec: 'enc','English'|encdec: 'enc']">
                                <div class="flip-card">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum" style="text-decoration: underline;
                                            ">ABCD</div><br>
                                                    <span style="font-size: small;margin-top: -50px;">Learn
                                                        English</span>
                                                </div>
                                                <div id="water1" class="water">
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum">Click to Go</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </a>
                        </div>
                        <div class="col-md-4">
                            <a [routerLink]="['/learn-typing-keytest',1|encdec: 'enc','Hindi'|encdec: 'enc']">
                                <div class="flip-card">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum" style="text-decoration: underline;margin-left: 5px
                                        ">अबसद</div><br>
                                                    <span style="font-size: small;margin-top: -50px;">Learn Hindi</span>
                                                </div>
                                                <div id="water4" class="water">
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum">Click to Go</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a [routerLink]="['/learn-typing-keytest',1|encdec: 'enc','MangalRem'|encdec: 'enc']">
                                <div class="flip-card">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum" style="text-decoration: underline;margin-left: 5px
                                        ">अबसद</div><br>
                                                    <span style="font-size: small;margin-top: -50px;">Learn Typing Mangal Font<br>(Remington Gail)</span>
                                                </div>
                                                <div id="water5" class="water">
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum">Click to Go</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                                <a [routerLink]="['/learn-typing-keytest',1|encdec: 'enc','MangalCBI'|encdec: 'enc']">
                                    <div class="flip-card">
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <div class="box">
                                                    <div class="percent" style="flex-wrap: wrap;">
                                                        <div class="percentNum" style="text-decoration: underline;margin-left: 5px
                                            ">अबसद</div><br>
                                                        <span style="font-size: small;margin-top: -50px;">Learn Typing Mangal Font<br>(Remington CBI)</span>
                                                    </div>
                                                    <div id="water6" class="water">
                                                        <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                                                            <use xlink:href="#wave"></use>
                                                        </svg>
                                                        <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                                                            <use xlink:href="#wave"></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flip-card-back">
                                                <div class="box">
                                                    <div class="percent" style="flex-wrap: wrap;">
                                                        <div class="percentNum">Click to Go</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a> 
                        </div>

                        <div class="col-md-4">
                            <a [routerLink]="['/learn-typing-keytest',1|encdec: 'enc','MangalIns'|encdec: 'enc']">
                                <div class="flip-card">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum" style="text-decoration: underline;margin-left: 5px
                                        ">अबसद</div><br>
                                                    <span style="font-size: small;margin-top: -50px;">Learn Typing Mangal Font<br>(Inscript)</span>
                                                </div>
                                                <div id="water7" class="water">
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum">Click to Go</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-4">
                            <a [routerLink]="['/learn-typing-keytest',1|encdec: 'enc','Number'|encdec: 'enc']">
                                <div class="flip-card">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum" style="text-decoration: underline;margin-left: 5px
                                        ">1234</div><br>
                                                    <span style="font-size: small;margin-top: -50px;">Learn
                                                        Numeric</span>
                                                </div>
                                                <div id="water3" class="water">
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                    <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                                                        <use xlink:href="#wave"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="box">
                                                <div class="percent" style="flex-wrap: wrap;">
                                                    <div class="percentNum">Click to Go</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                       
                    </div>

                </section>
            </div> -->
            <div class="col-md-12 p-4">
                <section class="fieldset">
                    <h1>English Paragraph Exercise</h1>
                    <div class="row">
                        <div class="col-md-3">

                        </div>
                        
                        <div *ngIf="this.myParam=='English'" class="col-md-6">
                            
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','English'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> English Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            English Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','English'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> English Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            English Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                         <div *ngIf="this.myParam=='Hindi'" class="col-md-6">
                            
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','Hindi'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> Hindi Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            Hindi Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','Hindi'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> Hindi Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            Hindi Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.myParam=='Number'" class="col-md-6">
                            
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','Number'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> Number Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            Number Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','Number'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> Number Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            Number Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.myParam=='MangalRem'" class="col-md-6">
                            
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','MangalRem'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> MangalRem Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            MangalRem Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','MangalRem'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> MangalRem Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            MangalRem Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.myParam=='MangalCBI'" class="col-md-6">
                            
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','MangalCBI'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> MangalCBI Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            MangalCBI Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','MangalCBI'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> MangalCBI Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            MangalCBI Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.myParam=='MangalIns'" class="col-md-6">
                            
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','MangalIns'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> MangalIns Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            MangalIns Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','MangalIns'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> MangalIns Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            MangalIns Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">

                        </div>
                        <!-- <div class="col-md-6">
                            <div style="max-height: 470px;overflow-y: scroll;">
                                <div *ngFor="let t of testlist;index as index"
                                    style="background: white;display: flex;padding: 5px;font-size: medium;font-weight: bold;border-style: groove;">
                                    <div style="width: 60%;">
                                        {{t.name}}
                                    </div>
                                    <div style="width: 40%;text-align: end;">
                                        <a *ngIf="t.ispaid==0"
                                            [routerLink]="['/learn-typing-keytest',(index+1) |encdec: 'enc','Hindi'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> Hindi Test
                                        </a>

                                        <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            Hindi Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired"
                                            [routerLink]="['/learn-typing-keytest',(index+1)|encdec: 'enc','Hindi'|encdec: 'enc']"
                                            style="background: #38566c;" class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i> Hindi Test
                                        </a>
                                        <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);"
                                            (click)="redirectToPackages()"
                                            class="btn btn-sm btn-secondary rounded mr-1"><i class="fas fa-lock"></i>
                                            Hindi Test
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>