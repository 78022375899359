
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer" style="height: 60.1094px;"></div>
<section class="pb-4">
    <div class="container-fluid">
        <!-- <ul class="flex-nowrap mt-4 progressbar row d-none d-md-flex">
            <li class="col" (click)="getTestBytype(1)" [ngClass]="(testtype===1)?'active':''">Time based test</li>
            <li class="col" (click)="getTestBytype(2)" [ngClass]="(testtype===2)?'active':''">Page based test</li>
        </ul> -->
        <section class="pb-4 py-3">
            <div class="container-fluid">
                <div class="shadow call-action">
                    For Free Test Please contact on this number:  <a href="tel:+91-9509678468">+91-9509678468</a>
                </div>
                <div class="card shadow">
                    <div class="card-header border-0" style="background-color: #f57f35;">
                        <div class="row">
                            <div class="col">
                                <h5 class="d-inline-block align-middle my-0 mr-3">Practice Set</h5>
                            </div>
                            <!-- <div class="col-auto">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-append">
                                        <button type="button"
                                            class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-filter"></i> {{(testtype=='')?'Language':testtype}}
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="javascript:void()"
                                                (click)="getTestBytype('English')">English</a>
                                            <a class="dropdown-item" href="javascript:void()"
                                                (click)="getTestBytype('Hindi')">Hindi</a>
                                            <a class="dropdown-item" href="javascript:void()"
                                                (click)="getTestBytype('MangalRem')">MangalRem</a>
                                            <a class="dropdown-item" href="javascript:void()"
                                                (click)="getTestBytype('MangalCBI')">MangalCBI</a>
                                            <a class="dropdown-item" href="javascript:void()"
                                                (click)="getTestBytype('MangalIns')">MangalIns</a>

                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body pt-0 px-0">
                        <div class="position-relative">
                            <div class="bg-light position-absolute w-100" style="height: 2px;"></div>
                            <div class="table-top-column-fixed overflow-auto" style="max-height: 400px;">
                                <table class="table">
                                    <tbody>
                                        <tr *ngFor="let t of testList  | paginate: {itemsPerPage: pagesize,
                                            currentPage: pageindex,
                                            totalItems: testcount};let index = index">
                                            <td style="width: 10%;text-align: center;">{{t.rn}}</td>
                                            <td style="width: 50%;">
                                                <h5 class="d-inline-block align-middle my-0 mr-3">{{t.name}}</h5>
                                            </td>
                                            <td style="width: 20%;">
                                                <h5 class="d-inline-block align-middle my-0 mr-3"
                                                    [ngClass]="(t.result==='Qualified')?'text-success':'text-danger'">
                                                    {{t.result}} </h5>
                                            </td>
                                            <td style="width: 20%;text-align: end;">
                                                <a *ngIf="t.ispaid==0 && t.language=='English'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',1|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1">
                                                    <i class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}}  Test
                                                </a>
                                                <a *ngIf="t.ispaid==0 && t.language=='Hindi'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',1|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1">
                                                    <i class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==0 && t.language=='MangalRem'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',1|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1">
                                                    <i class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==0 && t.language=='MangalCBI'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1">
                                                    <i class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==0 && t.language=='MangalIns'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1">
                                                    <i class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid == 0" [routerLink]="['/auth/login']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-lock"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired && t.language=='English'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',1|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired && t.language=='Hindi'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired && t.language=='MangalRem'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired && t.language=='MangalCBI'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid != 0 && !isexpired && t.language=='MangalIns'"
                                                    [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']"  [queryParams]="{exam:myParam}"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-play-circle"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                                <a *ngIf="t.ispaid==1 && userid != 0 && isexpired"
                                                    href="javascript:void(0);" (click)="redirectToPackages()"
                                                    class="btn btn-sm btn-secondary rounded mr-1"><i
                                                        class="fas fa-lock"></i>
                                                    {{t.language | titlecase}} Test
                                                </a>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="px-3">
                            <div class="row align-items-center">
                                <div class="col">
                                    <select style="width: 60px;" class="custom-select custom-select-sm mr-2"
                                        id="inputGroupSelect01" [(ngModel)]="pagesize"
                                        (change)="this.pageindex=1;getTest()" aria-label="pagesize">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <p class="d-inline-block" *ngIf="(pageindex*pagesize)>=testcount">Displaying
                                        {{(testcount==0)?0:((pageindex-1)*pagesize) + 1}} - {{testcount}} of
                                        {{testcount}}
                                        records</p>
                                    <p class="d-inline-block" *ngIf="(pageindex*pagesize)<=testcount">Displaying
                                        {{((pageindex-1)*pagesize) + 1}} -
                                        {{((pageindex-1)*pagesize) + (pagesize*1)}} of
                                        {{testcount}}
                                        records</p>
                                </div>
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example">
                                        <pagination-controls [autoHide]="true" (pageChange)="pageChanged($event)">
                                        </pagination-controls>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="pb-4" *ngIf="testtype===2">
            <div class="container-fluid">
                <div class="border border-radius-10 bg-white mt-4">
                    <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                        <span style=" height: 13px; width: 13px; "
                            class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span>
                        Page Based
                    </h6>
                    <div class="px-5 py-3">
                        <table class="mb-0 table table-details">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Language</th>
                                    <th>Highlight</th>
                                    <th>Backspace</th>
                                    <th>Customization</th>
                                    <th>English/Hindi</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let t of testList">
                                    <td>{{t.name}}</td>
                                    <td>{{t.language}}</td>
                                    <td>{{t.highlightoption}}</td>
                                    <td>{{t.backspaceoption}}</td>
                                    <td>{{(t.allowusersetting===1)?'Yes':'No'}}</td>
                                    <td>
                                        <a [routerLink]="['/typing/practice',t.id,1]"
                                            class="btn btn-sm btn-secondary rounded mr-1" *ngIf="t.isenglish==1">
                                            <i class="fas fa-play-circle"></i> English
                                        </a><a [routerLink]="['/typing/practice',t.id,2]"
                                            class="btn btn-sm btn-secondary rounded" *ngIf="t.ishindi==1">
                                            <i class="fas fa-play-circle"></i> Hindi
                                        </a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </section> -->
    </div>
</section>

<!-- <app-footer></app-footer> -->