import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { AuthdataService } from './authdata.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { switchMap, take, filter } from 'rxjs/operators';
import { EncDecService } from './enc-dec.service';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  constructor(
    private authDataService: AuthdataService,
    private aservice: AuthService,
    private router: Router,
    private encdec: EncDecService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authDataService.isAuthenticated()) {
      let token: any = this.authDataService.getToken();

      if (this.authDataService.isTokeExpired()) {
        if (!this.refreshTokenInProgress) {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.aservice.getNewToken({ userid: token.id, refreshToken: token.refreshToken,token: token.token}).pipe(
            switchMap((authResponse) => {
              token.token = authResponse.token;
              this.authDataService.setToken(this.encdec.convertText('enc', JSON.stringify(token), true));
              this.refreshTokenInProgress = false;
              token = this.authDataService.getToken();
              this.refreshTokenSubject.next(token.refreshToken);
              return next.handle(this.injectToken(req));
            }),
          );
        } else {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap((res) => {
              return next.handle(this.injectToken(req));
            })
          );
        }
      } else {
        return next.handle(this.injectToken(req));
      }
    } else {
      return next.handle(req);
    }
  }
  injectToken(request: HttpRequest<any>) {
    const token: any = this.authDataService.getToken();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token.token}`
      }
    });
  }
}

