import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
headers = headers.set('notoken', 'true');
@Injectable({
  providedIn: 'root'
})
export class TutorialpdfService {
  private httpclientbackend: HttpClient;
  prefix = environment._apiUrl;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend = new HttpClient(handler);
  }
  public getPdfList(pageindex, pagesize, searchtext): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/tutorial/gettutorial?searchtext=${searchtext}&pageindex=${pageindex}&pagesize=${pagesize}`, {
      headers
    });
  }
  public saveTutorialPdf(newpdf): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/tutorial/savetutorialpdf`, newpdf);
  }
  public deleteTutorial(id): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/tutorial/deletetutorial?id=${id}`, {
      headers
    });
  }
  public downloadTutorial(path): Observable<any> {
    return this.httpclientbackend.get<any>(`${environment._apiUrl}api/tutorial/download?filename=${path}`, {
      observe: 'response', responseType: 'blob' as 'json',
      headers
    });
  }
}
