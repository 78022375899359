<header class="header">

    <div class="container-fluid d-flex align-items-center">
        <div class="row align-items-center flex-1">
            <div class="col-auto align-self-center mobOnly-rxt-nav-trigger-wrap position-absolute py-4"
                style="z-index: 9;">
                <div class="rxt-nav-trigger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div class="col col-lg-auto text-center text-lg-left">
                <div class="logo" style="height:50px !important;width: 200px !important;">
                    <a [routerLink]="['/']">
                        <img class="img-fluid" src="assets/images/logo_website.jpg" alt="Tez Typing online">
                    </a>
                </div>
            </div>
            <div class="ml-auto">
                <div class="rxt-travel" data-rxt-travel-max="991" data-rxt-travel-appendTo="#rxtNavTravel">
                    <div id="rxtNavWrap" class="rxt-navigation-wrap">
                        <!-- <div class="mobOnly-menu-close-link">
                            <i class="mobOnly-menu-close fa fa-times" aria-hidden="true"></i>
                        </div> -->
                        <div class="mobile-social d-lg-none">
                            <ul class="p-0">
                                <li style="width: 80%;background: #1b3e5c;">

                                    <div class="logo"
                                        style="height:50px !important;width: 200px !important;background: #f8f9fa !important;">
                                        <a [routerLink]="['/']">
                                            <img class="img-fluid" src="assets/images/logo_website.jpg"
                                                alt="Tez Typing Hindi Typing">
                                        </a>
                                    </div>
                                </li>
                                <!-- <li> <a href="https://twitter.com/HrconnectG" target="_blank"
                                        class="mobile-social-icon color-twitter"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li> <a href="https://www.linkedin.com/company/42287448/admin/" target="_blank"
                                        class="mobile-social-icon color-linkedin"><i class="fab fa-linkedin-in"></i></a>
                                </li>
                                <li> <a href="https://www.instagram.com/hrconnect.aussizzgroup/" target="_blank"
                                        class="mobile-social-icon color-instagram"><i class="fab fa-instagram"></i></a>
                                </li> -->
                                <li>
                                    <div class="mobOnly-menu-close-link mobile-social-icon">
                                        <i class="mobOnly-menu-close fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div id="listQuickLinks"></div>
                        <div class="d-flex d-lg-block flex-column-reverse">
                            <ul class="rxt-navigation text-left">
                                <li class="active">
                                    <a [routerLink]="['/']">
                                        Home</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/']" [fragment]="'services'">Services</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/']" [fragment]="'contactus'">Contact us</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/']" [fragment]="'faqs'">FAQ</a>
                                </li>

                                <li>
                                    <a [routerLink]="['/']" [fragment]="'packages'">Packages</a>

                                </li>

                                <li>
                                    <a [routerLink]="['/features']">
                                        Features</a>
                                </li>
                                <li *ngIf="managep && managep.canView">
                                    <a href="javascript:void()" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">

                                        Manage
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" style="">
                                        <a *ng If="featuresp && featuresp.canView" class="dropdown-item" type="button"
                                            [routerLink]="['/user/features']">Features</a>
                                        <a *ngIf="rolep && rolep.canView" [routerLink]="['/user/role']"
                                            class="dropdown-item" type="button">Role / Permission</a>
                                        <a *ngIf="usersp && usersp.canView" [routerLink]="['/user/user-list']"
                                            class="dropdown-item" type="button">Users</a>
                                        <a *ngIf="inquiriesp && inquiriesp.canView" [routerLink]="['/user/inquiry']"
                                            class="dropdown-item" type="button">User Inquiries</a>
                                        <a *ngIf="testp && testp.canView" [routerLink]="['/user/test-list']"
                                            class="dropdown-item" type="button">Paid Test</a>
                                        <a *ngIf="learningp && learningp.canView"
                                            [routerLink]="['/user/learning-test-list']" class="dropdown-item"
                                            type="button">Learning</a>
                                        <a *ngIf="tutorialsp && tutorialsp.canView"
                                            [routerLink]="['/user/upload-tutorial']" class="dropdown-item"
                                            type="button">Tutorials</a>
                                        <a *ngIf="testhistoryp && testhistoryp.canView"
                                            [routerLink]="['/user/testhistory']" class="dropdown-item">
                                            Test History</a>
                                        <a *ngIf="packagep && packagep.canView" [routerLink]="['/user/packagelist']"
                                            class="dropdown-item">
                                            Packages</a>
                                        <a *ngIf="uploadvideop && uploadvideop.canView"
                                            [routerLink]="['/user/uploadvideos']" class="dropdown-item">
                                            Upload Video</a>
                                    </div>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void()" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Learning</a>
                                    <div class="dropdown-menu dropdown-menu-right" style="">
                                        <a class="dropdown-item" [routerLink]="['/tutorials']"
                                            type="button">Tutorials</a>
                                      
                                        <a class="dropdown-item" [routerLink]="['/learn-typing']" type="button">Learn
                                            Typing</a>
                                        <a class="dropdown-item" [routerLink]="['/practice-test']"
                                            type="button">Practice</a>
                                        <a class="dropdown-item" [routerLink]="['/live-test']" type="button">Live
                                            Test</a>
                                    </div>
                                </li> -->
                                <li *ngIf="!isloggedin">
                                    <a class="t-btn-secondary" style="border-radius: 4px;"
                                        [routerLink]="['/auth/signup']">
                                        SIGN UP NOW</a>
                                </li>
                                <li *ngIf="!isloggedin">
                                    <a class="t-btn-secondary" style="border-radius: 4px;"
                                        [routerLink]="['/auth/login']">
                                        LOGIN</a>
                                </li>
                                <li *ngIf="isloggedin">
                                    <a href="javascript:void()" style="border-radius: 20px;" class="t-btn-secondary"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="material-icons material-icons-15"> account_circle </i>

                                        {{username | titlecase}}
                                        <img src="assets/images/down-arrow-white.png" alt="Tez Typing english typing"
                                            class="img-fluid ml-1" style=" height: 10px; width: 20px; "></a>
                                    <div class="dropdown-menu dropdown-menu-right" style="">
                                        <!-- <a [routerLink]="['/user/dashboard']" class="dropdown-item"
                                            type="button">Dashboard</a> -->
                                        <a [routerLink]="['/user/editprofile']" class="dropdown-item" type="button">Edit
                                            Profile</a>
                                        <a [routerLink]="['/auth/logout']" class="dropdown-item"
                                            type="button">Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p class="border-light-1 border-top d-lg-none mt-3 py-3 small text-center text-muted">
                            Copyright ©
                            <script>document.write(new Date().getFullYear())</script> - All Rights Reserved
                        </p>
                    </div>
                    <div class="rxt-nav-overlay"></div>
                </div>
            </div>

        </div>
    </div>
</header>