import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
headers = headers.set('notoken', 'true');

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private httpclientbackend: HttpClient;
  prefix = environment._apiUrl;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend = new HttpClient(handler);
  }
  public getDetails(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/home/details?userid=${userid}`, {
      headers
    });
  }
  public getArticles(): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/articles/getarticles`, {
      headers
    });
  }
  public getFeatures(): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/articles/features`, {
      headers
    });
  }
  public saveArticle(newarticle): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/articles/savearticle`, newarticle, {
      headers
    });
  }
  public deleteArticle(id, userid, checked): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/articles/deletearticle?id=${id}&userid=${userid}&checked=${checked}`, {
      headers
    });
  }
  public saveInquiry(inquiry): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/home/saveinquiry`, inquiry, {
      headers
    });
  }
  public getInquiry(pageIndex, pageSize): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/home/getinquiry?pageindex=${pageIndex}&pagesize=${pageSize}`, {
      headers
    });
  }
}
