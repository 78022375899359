import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadvideosService } from 'src/app/admin/services/uploadvideos.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';

declare const $: any;
declare const Plyr: any;
@Component({
  selector: 'app-viewvideo',
  templateUrl: './viewvideo.component.html',
  styleUrls: ['./viewvideo.component.scss']
})
export class ViewvideoComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private route: ActivatedRoute,
    private encdec: EncDecService,
    private loader: LoaderService,
    private uploadvservice: UploadvideosService,
    private mservice: ToastService
  ) { }
  id: any;
  iframeUrl: any;
  videodetails: any;
  ngOnInit(): void {

    this.route.params.pipe(takeUntil(this.componentDestroyed$)).subscribe(params => {
      this.id = this.encdec.decryptSensitive(params.id);
    });
    this.uploadvservice.getvideosettingById(this.encdec.encryptSensitive(this.id)).pipe(takeUntil(this.componentDestroyed$)).subscribe(ldata => {
      this.loader.loadme = false;
      if (ldata.flag) {
        this.videodetails = (ldata.result && ldata.result.length > 0) ? ldata.result : [];
        if (this.videodetails.length > 0) {
          setTimeout(() => {
            const player = new Plyr('#player');
          }, 100);
          var video_id = this.videodetails[0].path.split('v=')[1];
          var ampersandPosition = video_id.indexOf('&');
          if (ampersandPosition != -1) {
            video_id = video_id.substring(0, ampersandPosition);
          }
          this.iframeUrl = 'https://www.youtube.com/embed/' + video_id;
          if ($('.ytp-pause-overlay .ytp-scroll-min').length > 0) {
            $('.ytp-pause-overlay .ytp-scroll-min').css('display', 'none');
          }
        }
      }
    });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
