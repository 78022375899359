import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorService } from './services/interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { LtypingComponent } from './components/ltyping/ltyping.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TypingTestComponent } from './components/typing-test/typing-test.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { LivetestComponent } from './components/livetest/livetest.component';
import { WatchvideosComponent } from './components/watchvideos/watchvideos.component';
import { ViewvideoComponent } from './components/viewvideo/viewvideo.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { FeaturesComponent } from './components/features/features.component';
import { LearnTypingComponent } from './components/learn-typing/learn-typing.component';
import { LearnTypinglistComponent } from './components/learn-typing-list/learn-typing-list.component';
import { LearningTestComponent } from './components/learning-test/learning-test.component';
import { LearningTestSetsComponent } from './components/learning-test-sets/learning-test-sets.component';
import { PackagedetailsComponent } from './components/package-details/packagedetails.component';
// import { VgCoreModule } from 'ngx-videogular';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LtypingComponent,
    TypingTestComponent,
    ThankyouComponent,
    LivetestComponent,
    WatchvideosComponent,
    ViewvideoComponent,
    TutorialComponent,
    FeaturesComponent,
    LearnTypingComponent,
    LearnTypinglistComponent,
    LearningTestComponent,
    LearningTestSetsComponent,
    PackagedetailsComponent
    // VgCoreModule
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    CarouselModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      countDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
