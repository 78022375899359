<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer"></div>
<div class="container-fluid watch_video" *ngIf="videodetails && videodetails.length > 0">
    <div class="row pt-4">
        <div class="col-md-12 video_box">
            <div class="plyr__video-embed" id="player">
                <iframe [src]="iframeUrl | safe" allowfullscreen allowtransparency allow="autoplay"></iframe>
            </div>
        </div>

    </div>
</div>