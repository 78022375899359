import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadvideosService } from 'src/app/admin/services/uploadvideos.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';

@Component({
  selector: 'app-watchvideos',
  templateUrl: './watchvideos.component.html',
  styleUrls: ['./watchvideos.component.scss']
})
export class WatchvideosComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private loader: LoaderService,
    private uploadvservice: UploadvideosService,
    private mservice: ToastService,
    private encdec: EncDecService,
    private adataservice: AuthdataService
  ) { }
  pageIndex = 1;
  pageSize = 50;
  searchtext = '';
  alllistofvideos: any = [];
  pcount = 0;
  ngOnInit(): void {
    this.getAllVideosList();
  }
  getAllVideosList() {
    this.loader.loadme = true;
    this.uploadvservice.getwatchvideos(this.searchtext).pipe(takeUntil(this.componentDestroyed$)).subscribe(ldata => {
      this.loader.loadme = false;
      if (ldata.flag) {
        this.alllistofvideos = (ldata.result && ldata.result[0].length > 0) ? ldata.result[0] : [];
      }
    });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
