import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TestService } from 'src/app/admin/services/test.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';

declare const $: any;
@Component({
  selector: 'app-learn-typing-list',
  templateUrl: './learn-typing-list.component.html',
  styleUrls: ['./learn-typing-list.component.scss']
})
export class LearnTypinglistComponent implements OnInit {

  constructor(
    private testService: TestService,
    private mservice: ToastService,
    public loadService: LoaderService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private adataservice: AuthdataService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Learn hindi and english typing key by key");
  }
  language = '';
  myParam = '';
  testlist = [];
  isexpired = true;
  userid = 0;
  userdata: any;
  ngOnInit(): void {
    this.getTestByFilter();
    var water1 = document.getElementById("water1");
    var water3 = document.getElementById("water3");
    var water4 = document.getElementById("water4");
    var water5 = document.getElementById("water5");
    var water6 = document.getElementById("water6");
    var water7 = document.getElementById("water7");
    var percent = 1;
    var interval;
    setTimeout(() => {
      interval = setInterval(function () {
        percent++;
        water1.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
        water3.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
        water4.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
        water5.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
        water6.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
        water7.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
        if (percent == 50) {
          clearInterval(interval);
        }
      }, 100);
    }, 2000);
    this.userdata = (this.adataservice.getUserData()) ? this.adataservice.getUserData() : [];
    this.userid = (this.adataservice.isAuthenticated()) ? this.adataservice.getUserId() : 0;
    let subscriptiondate = (this.userdata && this.userdata.length > 0) ? this.userdata[0].subscriptiondate : null;
    var today = new Date();
    if (subscriptiondate != null) {
      var newDate = new Date(subscriptiondate);
      if (this.userdata[0].dtype == 'month') {
        const expireddate = new Date(newDate.setMonth(newDate.getMonth() + this.userdata[0].duration));
        if (today > expireddate) {
          this.isexpired = true;
        } else {
          this.isexpired = false;
        }
      } else {
        const expireddate = new Date(newDate.setFullYear(newDate.getFullYear() + this.userdata[0].duration));
        if (today > expireddate) {
          this.isexpired = true;
        } else {
          this.isexpired = false;
        }
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    this.myParam = urlParams.get('language');
    this.language = this.myParam;
  }
  redirectToPackages() {
    $.confirm({
      icon: 'fas fa-trash-alt',
      title: 'Subscribe?',
      content: 'You have not subscribed to this package.',
      type: '',
      draggable: false,
      typeAnimated: true,
      buttons: {
        subscribe: {
          text: 'Subscribe',
          btnClass: 'btn-blue',
          action: () => {
            this.router.navigate([''], { fragment: 'packages' });
          }
        },
        Cancel: () => {
        }
      }
    });
  }
  getTestByFilter() {
    this.loadService.loadme = true;
    this.testService.getLearningTestForUsers(this.language).subscribe(data => {
      if (data.flag) {
        this.testlist = data[0];
        this.loadService.loadme = false;
        this.cd.markForCheck();
      } else {
        this.mservice.generateMessage('ERROR', data.message, '');
        this.loadService.loadme = false;
      }
    });
  }
  getTestBytype(language) {
    this.language = language;
    this.getTestByFilter();
  }
}
