<footer>
    <div class="copyright py-3 ">
        <div class="container-fluid">
            <div class="row color-white">
                <div class="align-self-center col col-md-auto small text-center text-md-left">
                    <span class="d-block d-sm-inline">Copyright ©
                        <script>document.write(new Date().getFullYear())</script> All Right Reserved.</span>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- BACK TO TOP -->
<span id="back_to_top" class="scrollup" title="Back to top">
    <i class="fa fa-chevron-up" aria-hidden="true"></i>
</span>