import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
headers = headers.set('notoken', 'true');


@Injectable({
  providedIn: 'root'
})
export class PackageService {
  private httpclientbackend: HttpClient;
  prefix = environment._apiUrl;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend = new HttpClient(handler);
  }
  public getPackages(pageindex, pagesize, searchtext): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/package/getpackage?searchtext=${searchtext}&pageindex=${pageindex}&pagesize=${pagesize}`, {
      headers
    });
  }
  public savePackage(newpackage): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/package/savepackage`, newpackage, {
      headers
    });
  }
  public deletePackage(id): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/package/deletepackage?id=${id}`, {
      headers
    });
  }
  public subscribePackge(packageid, userid): Observable<any> {
    console.log("packageid == > "+packageid+"    userid == > "+userid)
    return this.httpclient.get<any>(`${environment._apiUrl}api/payment/createpayment?pid=${packageid}&uid=${userid}`, {
      headers
    });
  }
  public savePaymentDetails(objpakage): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/payment/savepaymentdetails`, objpakage, {
      headers
    });
  }
  public getsubscriptionlist(userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/payment/getsubscriptionlist?userid=${userid}`, {
      headers
    });
  }
}
