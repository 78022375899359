import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PackageService } from 'src/app/admin/services/package.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { ToastService } from 'src/app/services/message/toast.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private route: ActivatedRoute,
    private packageservice: PackageService,
    private adataservice: AuthdataService,
    private encdec: EncDecService,
    private mservice: ToastService
  ) { }
  payment_id: any;
  payment_status: any;
  payment_request_id: any;
  userid: any;
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.payment_id = queryParams.get('paymentId').toString();
      // this.payment_status = queryParams.get('payment_status').toString();
      // this.payment_request_id = queryParams.get('payment_request_id').toString();
    });
    let userdata = this.adataservice.getUserData();
    this.userid = this.encdec.encryptSensitive(this.adataservice.getUserId());
    const packageid = sessionStorage.getItem('packageid');
    const pname = sessionStorage.getItem('packagename');
  
    const objpakage = {
      payment_id: this.payment_id,
      packagename: pname,
      // payment_status: this.payment_status,
      // payment_request_id: this.payment_request_id,
      userid: this.userid,
      packageid: packageid,
      email: userdata[0].email
    }

    this.packageservice.savePaymentDetails(objpakage).pipe(takeUntil(this.componentDestroyed$)).subscribe(ldata => {
      if (!ldata.flag) {
        this.mservice.generateMessage('ERROR', ldata.error, 'FAILED');
      }
    });
  }

}
