import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  private httpclientbackend: HttpClient;
  prefix: string = environment._apiUrl;
  suffix: string;
  constructor(private httpclient: HttpClient, handler: HttpBackend) {
    this.httpclientbackend = new HttpClient(handler);
  }

  public getTest(pageIndex, pageSize, searchText, islivetest, language): Observable<any> {

    return this.httpclient.get<any>(`${this.prefix}api/test/gettest?pagesize=${pageSize}&pageindex=${pageIndex}&searchtext=${searchText}&islivetest=${islivetest}&language=${language}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getLearningTest(pageIndex, pageSize, searchText, language, practiceset): Observable<any> {
    return this.httpclient.get<any>(`${this.prefix}api/test/getlearningtest?pagesize=${pageSize}&pageindex=${pageIndex}&searchtext=${searchText}&language=${language}&practiceset=${practiceset}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getLearningTestForUsers(language): Observable<any> {
    return this.httpclientbackend.get<any>(`${this.prefix}api/test/getlearningtestforuser?language=${language}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getLearningTestForUsersById(testid, language): Observable<any> {
    return this.httpclientbackend.get<any>(`${this.prefix}api/test/getlearningtestforuserbyid?testid=${testid}&language=${language}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getLearningTestForUsersByType(testid, language): Observable<any> {
    return this.httpclientbackend.get<any>(`${this.prefix}api/test/getlearningtestforuserbytype?testid=${testid}&language=${language}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public saveLearningTest(payload): Observable<any> {
    return this.httpclient.post<any>(`${this.prefix}api/test/savelearningtest`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getFreeTest(type, islivetest, pageindex, pagesize, userid): Observable<any> {
    return this.httpclientbackend.get<any>(`${this.prefix}api/test/getfreetest?type=${type}&islivetest=${islivetest}&pageindex=${pageindex}&pagesize=${pagesize}&userid=${userid}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getFreeTestByID(id, type, userid): Observable<any> {
    return this.httpclientbackend.get<any>(`${this.prefix}api/test/getfreetestbyid?id=${id}&type=${type}&userid=${userid}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getTestResultsByID(id, userid): Observable<any> {
    return this.httpclient.get<any>(`${this.prefix}api/test/gettestresultsbyid?id=${id}&userid=${userid}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getTestRank(testid): Observable<any> {
    return this.httpclientbackend.get<any>(`${this.prefix}api/test/gettestrank?testid=${testid}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public getTestResults(type, islivetest, pageindex, pagesize, userid, name): Observable<any> {
    return this.httpclient.get<any>(`${this.prefix}api/test/gettestresults?type=${type}&islivetest=${islivetest}&pageindex=${pageindex}&pagesize=${pagesize}&userid=${userid}&name=${name}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public saveTestResults(payload): Observable<any> {
    return this.httpclient.post<any>(`${this.prefix}api/test/savetestresults`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  public saveTest(payload): Observable<any> {
    return this.httpclient.post<any>(`${this.prefix}api/test/savetest`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
