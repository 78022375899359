<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer" style="height: 60.1094px;"></div>
<section class="pb-4" *ngIf="showTestResults===1">
    <div class="container-fluid">
        <!-- <ul class="flex-nowrap mt-4 progressbar row d-none d-md-flex">
            <li class="col" (click)="getTestBytype(1)" [ngClass]="(testtype===1)?'active':''">Time based test</li>
            <li class="col" (click)="getTestBytype(2)" [ngClass]="(testtype===2)?'active':''">Page based test</li>
        </ul> -->
        <section class="pb-4 py-3">
            <div class="card shadow">
                <div class="card-header border-0" style="background-color: #f57f35;">
                    <div class="row">
                        <div class="col">
                            <h5 class="d-inline-block align-middle my-0 mr-3">Live Test</h5>
                        </div>
                        <div class="col-auto">
                            <div class="input-group input-group-sm">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-filter"></i> {{(testtype=='')?'Language':testtype}}
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="javascript:void()" (click)="getTestBytype('English')">English</a>
                                        <a class="dropdown-item" href="javascript:void()" (click)="getTestBytype('Hindi')">Hindi</a>
                                        <a class="dropdown-item" href="javascript:void()" (click)="getTestBytype('MangalRem')">MangalRem</a>
                                        <a class="dropdown-item" href="javascript:void()" (click)="getTestBytype('MangalCBI')">MangalCBI</a>
                                        <a class="dropdown-item" href="javascript:void()" (click)="getTestBytype('MangalIns')">MangalIns</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0 px-0">
                    <div class="position-relative">
                        <div class="bg-light position-absolute w-100" style="height: 2px;"></div>
                        <div class="table-top-column-fixed overflow-auto" style="max-height: 400px;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Test Name</th>
                                        <th>Backspace Allowed</th>
                                        <th>Highlight Option</th>
                                        <th>Test Duration</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="testcount>0">
                                    <tr *ngFor="let t of testList | paginate: {itemsPerPage: pagesize,
                                        currentPage: pageindex,
                                        totalItems: testcount};let index = index">

                                        <td>
                                            {{t.name}}
                                        </td>
                                        <td>
                                            {{t.backspaceoption}}
                                        </td>
                                        <td>
                                            {{t.highlightoption}}
                                        </td>
                                        <td>
                                            {{t.timeduration}} - minutes
                                        </td>
                                        <td>
                                            {{t.teststartdate | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{t.testenddate | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td style="width: 20%;text-align: end;">

                                            <a href="javascript:void()" (click)="getTestRank(t.id)" class="btn btn-sm btn-secondary rounded mr-1">
                                                <i class="fas fa-eye"></i> Rank
                                            </a>
                                            <!-- <a [routerLink]="['/typing/practice',t.id,1]"
                                                class="btn btn-sm btn-secondary rounded mr-1" *ngIf="t.testresultid==0">
                                                <i class="fas fa-play-circle" *ngIf="t.ispaid==0"></i><i
                                                    class="fas fa-lock" *ngIf="t.ispaid==1"></i>
                                                {{t.language | titlecase}} Test
                                            </a> -->
                                            <a *ngIf="t.ispaid==0  && t.language=='English' && (!t.testresultid || t.testresultid==0)" [routerLink]="['/typing/practice',t.id|encdec: 'enc',1|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1">
                                                <i class="fas fa-play-circle"></i> {{t.language | titlecase}} Test 1
                                            </a>
                                            <a *ngIf="t.ispaid==0  && t.language=='Hindi' && (!t.testresultid || t.testresultid==0)" [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1">
                                                <i class="fas fa-play-circle"></i> {{t.language | titlecase}} Test 2
                                            </a>

                                            <a *ngIf="t.ispaid==0  && t.language=='MangalIns' && (!t.testresultid || t.testresultid==0)" [routerLink]="['/typing/practice',t.id|encdec: 'enc',3|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1">
                                                <i class="fas fa-play-circle"></i> {{t.language | titlecase}} Test 2
                                            </a>

                                            <a *ngIf="t.ispaid==0  && t.language=='MangalRem' && (!t.testresultid || t.testresultid==0)" [routerLink]="['/typing/practice',t.id|encdec: 'enc',3|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1">
                                                <i class="fas fa-play-circle"></i> {{t.language | titlecase}} Test 2
                                            </a>

                                            <a *ngIf="t.ispaid==0  && t.language=='MangalCBI' && (!t.testresultid || t.testresultid==0)" [routerLink]="['/typing/practice',t.id|encdec: 'enc',3|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1">
                                                <i class="fas fa-play-circle"></i> {{t.language | titlecase}} Test 2
                                            </a>

                                            <a *ngIf="t.ispaid==1  && userid == 0" [routerLink]="['/auth/login']" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-lock"></i>
                                                {{t.language | titlecase}} Test 3
                                            </a>
                                            <a *ngIf="t.ispaid==1  && t.testresultid==0 && userid != 0 && !isexpired && t.language=='English'" [routerLink]="['/typing/practice',t.id|encdec: 'enc',1|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-play-circle"></i>
                                                {{t.language | titlecase}} Test 4
                                            </a>
                                            <a *ngIf="t.ispaid==1  && t.testresultid==0 && userid != 0 && !isexpired && t.language=='Hindi'" [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-play-circle"></i>
                                                {{t.language | titlecase}} Test 5
                                            </a>
                                            <a *ngIf="t.ispaid==1  && t.testresultid==0 && userid != 0 && !isexpired && t.language=='MangalIns'" [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-play-circle"></i>
                                                {{t.language | titlecase}} Test 5
                                            </a>
                                            <a *ngIf="t.ispaid==1  && t.testresultid==0 && userid != 0 && !isexpired && t.language=='MangalRem'" [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-play-circle"></i>
                                                {{t.language | titlecase}} Test 5
                                            </a>
                                            <a *ngIf="t.ispaid==1  && t.testresultid==0 && userid != 0 && !isexpired && t.language=='MangalCBI'" [routerLink]="['/typing/practice',t.id|encdec: 'enc',2|encdec: 'enc']" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-play-circle"></i>
                                                {{t.language | titlecase}} Test 5
                                            </a>

                                            <!-- <a *ngIf="t.ispaid==0  && t.language=='MangalIns' && (!t.testresultid || t.testresultid==0)"
                                            [routerLink]="['/typing/practice',t.id|encdec: 'enc',3|encdec: 'enc']"
                                            class="btn btn-sm btn-secondary rounded mr-1">
                                            <i class="fas fa-play-circle"></i>
                                            {{t.language | titlecase}} Test 2
                                            </a> -->
                                            <a *ngIf="t.ispaid==1 && userid != 0 && isexpired" href="javascript:void(0);" (click)="redirectToPackages()" class="btn btn-sm btn-secondary rounded mr-1"><i
                                                    class="fas fa-lock"></i>
                                                {{t.language | titlecase}} Test 6
                                            </a>
                                            <a href="javascript:void()" (click)="getTestResults(t.testresultid)" class="btn btn-sm btn-secondary rounded mr-1" *ngIf="t.testresultid>0">
                                                <i class="fas fa-eye"></i> View
                                            </a>
                                        </td>

                                    </tr>
                                </tbody>

                                <tbody *ngIf="testcount==0">
                                    <tr>
                                        <td colspan="3" style="text-align: center;">No {{testtype}} live test for the day</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="px-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <select style="width: 60px;" class="custom-select custom-select-sm mr-2" id="inputGroupSelect01" [(ngModel)]="pagesize" (change)="this.pageindex=1;getTest()" aria-label="pagesize">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <p class="d-inline-block" *ngIf="(pageindex*pagesize)>=testcount">Displaying {{(testcount==0)?0:((pageindex-1)*pagesize) + 1}} - {{testcount}} of {{testcount}} records
                                </p>
                                <p class="d-inline-block" *ngIf="(pageindex*pagesize)<=testcount">Displaying {{((pageindex-1)*pagesize) + 1}} - {{((pageindex-1)*pagesize) + (pagesize*1)}} of {{testcount}} records
                                </p>
                            </div>
                            <div class="col-auto">
                                <nav aria-label="Page navigation example">
                                    <pagination-controls [autoHide]="true" (pageChange)="pageChanged($event)">
                                    </pagination-controls>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="pb-4" *ngIf="testtype===2">
            <div class="container-fluid">
                <div class="border border-radius-10 bg-white mt-4">
                    <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                        <span style=" height: 13px; width: 13px; "
                            class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span>
                        Page Based
                    </h6>
                    <div class="px-5 py-3">
                        <table class="mb-0 table table-details">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Language</th>
                                    <th>Highlight</th>
                                    <th>Backspace</th>
                                    <th>Customization</th>
                                    <th>English/Hindi</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let t of testList">
                                    <td>{{t.name}}</td>
                                    <td>{{t.language}}</td>
                                    <td>{{t.highlightoption}}</td>
                                    <td>{{t.backspaceoption}}</td>
                                    <td>{{(t.allowusersetting===1)?'Yes':'No'}}</td>
                                    <td>
                                        <a [routerLink]="['/typing/practice',t.id,1]"
                                            class="btn btn-sm btn-secondary rounded mr-1" *ngIf="t.isenglish==1">
                                            <i class="fas fa-play-circle"></i> English
                                        </a><a [routerLink]="['/typing/practice',t.id,2]"
                                            class="btn btn-sm btn-secondary rounded" *ngIf="t.ishindi==1">
                                            <i class="fas fa-play-circle"></i> Hindi
                                        </a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </section> -->
    </div>
</section>

<div class="main-content col-12 px-4  p-3" *ngIf="showTestResults===2">
    <section class="pb-4">
        <div class="container-fluid">
            <div class="warpper">
                <input class="radio" id="one" (click)="getResultByExam(testDetails.passingmarks)" name="group" type="radio" checked>
                <input class="radio" id="two" (click)="getResultByExam(testDetails.hcldc)" name="group" type="radio">
                <input class="radio" id="three" (click)="getResultByExam(testDetails.hcs)" name="group" type="radio">
                <input class="radio" id="four" (click)="getResultByExam(testDetails.rldc)" name="group" type="radio">
                <input class="radio" id="five" (click)="getResultByExam(testDetails.rrb)" name="group" type="radio">
                <input class="radio" id="six" (click)="getResultByExam(testDetails.scgl)" name="group" type="radio">
                <input class="radio" id="seven" (click)="getResultByExam(testDetails.schsl)" name="group" type="radio">
                <div class="tabs">
                    <label class="tab" id="one-tab" for="one">All Type exam</label>
                    <label class="tab" id="two-tab" for="two">High Court LDC</label>
                    <label class="tab" id="three-tab" for="three">High Court Steno</label>
                    <label class="tab" id="four-tab" for="four">Rssb LDC</label>
                    <label class="tab" id="five-tab" for="five">RBB NTPC</label>
                    <label class="tab" id="six-tab" for="six">SSC CGL</label>
                    <label class="tab" id="seven-tab" for="seven">SSC CHSL</label>
                </div>
            </div>
            <div class="border border-radius-10 bg-white mt-4">


                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style="cursor: pointer;" (click)="showTestResults=1"><i
                            class="fas fa-arrow-left"></i></span>&nbsp; <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Final test results
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th>Test Name</th>
                                <th>
                                    {{testDetails.name}}</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Result</th>
                                <th [ngClass]="(testDetails.result==='Qualified')?'text-success':'text-danger'">
                                    {{testDetails.result}}</th>
                                <th>NET(WPM) SPEED</th>
                                <th>{{testDetails.ns}}</th>
                            </tr>
                            <tr>
                                <th>GROSS SPEED</th>
                                <th>{{testDetails.gs}}</th>
                                <th>GROSS WPM(WORD PER MIN)</th>
                                <th>{{testDetails.gwpm}}</th>
                            </tr>
                            <tr>
                                <th>REQUIRED ACCURACY</th>
                                <th>{{testDetails.ra.toFixed(2)}}%</th>
                                <th>GAINED ACCURACY</th>
                                <th>{{testDetails.ga.toFixed(2)}}%</th>
                            </tr>
                            <tr>
                                <th>Error</th>
                                <th>{{testDetails.error.toFixed(2)}}%</th>
                                <th>Total Words</th>
                                <th>{{testDetails.tlw}}</th>
                            </tr>
                            <tr>
                                <th>Typed Words</th>
                                <th>{{testDetails.tpw}}</th>
                                <th>Skipped Words</th>
                                <th>{{testDetails.tlw-testDetails.tpw}}</th>
                            </tr>
                            <tr>
                                <th>Correct Words</th>
                                <th>{{testDetails.cw}}</th>
                                <th>Incorrect Words</th>
                                <th>{{testDetails.icw}}</th>
                            </tr>
                            <tr>
                                <th>Total time for the test</th>
                                <th>{{testDetails.tltime}}</th>
                                <th>Time taken</th>
                                <th>{{testDetails.tktime}}</th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Original Paragraph
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="testDetails.content"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Paragraph you typed
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="testDetails.paragraph"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Error's in paragraph&nbsp;<span class="failed">Incorrect</span>&nbsp;<span class="match">Correct</span> &nbsp;
                    <span class="extra">Extra</span> &nbsp;
                    <span class="missing">Missing</span>
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="paradiff"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
        </div>
    </section>
</div>

<div class="main-content col-12 px-4  p-3" *ngIf="showTestResults===3">
    <section class="pb-4">
        <div class="container-fluid">

            <div class="border border-radius-10 bg-white mt-4">


                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style="cursor: pointer;" (click)="showTestResults=1"><i
                            class="fas fa-arrow-left"></i></span>&nbsp; <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Top 10 scorrer
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Name</th>
                                <th>Gross Speed</th>
                                <th>Net Speed</th>
                                <th>Gross WPM</th>
                                <th>Requires Accuracy</th>
                                <th>Gained Accuracy</th>
                                <th>Error</th>
                                <th>Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rank of testScrores;index as index">
                                <td>{{index+1}}</td>
                                <td>{{rank.firstname}} {{rank.lastname}}</td>
                                <td>{{rank.gs}}</td>
                                <td>{{rank.ns}}</td>
                                <td>{{rank.gwpm}}</td>
                                <td>{{rank.ra.toFixed(2)}}</td>
                                <td>{{rank.ga.toFixed(2)}}</td>
                                <td>{{rank.error.toFixed(2)}}%</td>
                                <td>{{rank.result}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <app-footer></app-footer> -->