import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TutorialpdfService } from 'src/app/admin/services/tutorialpdf.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';

declare const $: any;
@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private loader: LoaderService,
    private tutorialservice: TutorialpdfService,
    private cd: ChangeDetectorRef,
    private mservice: ToastService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Tez Typing Tutorials for hindi and english typing test");
   }
  tutoriallist = [];
  pageIndex = 1;
  pageSize = 50;
  searchtext = '';
  tcount: any;
  ngOnInit(): void {
    this.getPdfList();
  }
  getPdfList() {
    this.loader.loadme = true;
    this.tutorialservice.getPdfList(this.pageIndex, this.pageSize, this.searchtext).pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      this.loader.loadme = false;
      if (data.flag) {
        this.tutoriallist = (data.result && data.result[0].length > 0) ? data.result[0] : [];
        this.tcount = data.result[1][0].tcount;
        this.loader.loadme = false;
        this.cd.markForCheck();
      } else {
        this.mservice.generateMessage('ERROR', data.error, '');
        this.loader.loadme = false;
      }
    });
  }
  downloadpdf(list) {
    //   $.ajax({
    //     url:list.link,
    //     cache:false,
    //     xhr:function(){// Seems like the only way to get access to the xhr object
    //         var xhr = new XMLHttpRequest();
    //         xhr.responseType= 'blob'
    //         return xhr;
    //     },
    //     success: function(data){
    //       var link = document.createElement("a");
    //       link.download = list.filename;
    //       link.href = window.URL.createObjectURL(data);
    //       link.click();
    //     },
    //     error:function(){

    //     }
    // });
    this.loader.loadme = true;
    let spd = list.link.split('/');
    this.tutorialservice.downloadTutorial(list.link).pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      this.loader.loadme = false;
      let filename: string = spd[spd.length - 1];
      let binaryData = [];
      binaryData.push(data.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.cd.markForCheck();
    });
  }
  getAllTutorialByFilter() {
    this.loader.loadme = true;
    this.tutorialservice.getPdfList(this.pageIndex, this.pageSize, this.searchtext).pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      if (data.flag) {
        this.tutoriallist = (data.result && data.result[0].length > 0) ? data.result[0] : [];
        this.tcount = data.result[1][0].tcount;
        this.loader.loadme = false;
        this.cd.markForCheck();
      } else {
        this.mservice.generateMessage('ERROR', data.error, '');
        this.loader.loadme = false;
      }
    });
  }
  pageChanged(event) {
    this.pageIndex = event;
    this.getAllTutorialByFilter();
    this.cd.markForCheck();
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
