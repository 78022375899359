export class Permissions {
    canAdd = false;
    canView = false;
    canEdit = false;
    canDelete = false;
    // canMap = false;
    // canImport = false;
    // canExport = false;
    // canApprove = false;
    // canReject = false;
    // canShare = false;
}