import { Component, OnInit } from '@angular/core';
import { AuthdataService } from 'src/app/services/authdata.service';

declare const $: any;
@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  username = '';
  uploadvideop: any;
  packagep: any;
  testhistoryp: any;
  managep: any;
  featuresp: any;
  rolep: any;
  usersp: any;
  inquiriesp: any;
  testp: any;
  learningp: any;
  tutorialsp: any;
  constructor(
    private authdata: AuthdataService
  ) { }

  ngOnInit(): void {
    this.username = this.authdata.getUserUserName();
    this.uploadvideop = this.authdata.getPermission('upload-video');
    this.packagep = this.authdata.getPermission('Package');
    this.testhistoryp = this.authdata.getPermission('Test History');
    this.managep = this.authdata.getPermission('Manage');
    this.featuresp = this.authdata.getPermission('Features');
    this.rolep = this.authdata.getPermission('Role');
    this.usersp = this.authdata.getPermission('Users');
    this.inquiriesp = this.authdata.getPermission('Inquiries');
    this.testp = this.authdata.getPermission('Test');
    this.learningp = this.authdata.getPermission('Learning Test');
    this.tutorialsp = this.authdata.getPermission('Tutorials');
    /* Navigation */
    const rxtNav = $('#rxtNavWrap, .rxt-nav-overlay');

    $('.rxt-nav-trigger').click(function () {
      rxtNav.toggleClass('menu-open');
    });
    $('.rxt-nav-overlay, .mobOnly-menu-close-link').click(function () {
      rxtNav.removeClass('menu-open');
    });
    /* end Navigation */
  }

}
