import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TestService } from 'src/app/admin/services/test.service';
import { AuthdataService } from 'src/app/services/authdata.service';

declare const $: any;
@Component({
  selector: 'app-packagedetails',
  templateUrl: './packagedetails.component.html',
  styleUrls: ['./packagedetails.component.scss'],
})
export class PackagedetailsComponent implements OnInit {
  testList = [];
  testtype = '';
  myParam = '';
  pageindex = 1;
  pagesize = 10;
  testcount = 0;
  isexpired = true;
  userid = 0;
  userdata: any;
  constructor(
    private testService: TestService,
    private adataservice: AuthdataService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle(
      'Tez Typing Practice hindi and english typing test'
    );
  }
  ngOnInit(): void {
    if (localStorage.getItem('planguage')) {
      this.testtype = localStorage.getItem('planguage');
    }
    this.getTest();
    this.userdata = this.adataservice.getUserData()
      ? this.adataservice.getUserData()
      : [];
    let subscriptiondate =
      this.userdata && this.userdata.length > 0
        ? this.userdata[0].subscriptiondate
        : null;
    var today = new Date();
    if (subscriptiondate != null) {
      var newDate = new Date(subscriptiondate);
      if (this.userdata[0].dtype == 'month') {
        const expireddate = new Date(
          newDate.setMonth(newDate.getMonth() + this.userdata[0].duration)
        );
        if (today > expireddate) {
          this.isexpired = true;
        } else {
          this.isexpired = false;
        }
      } else {
        const expireddate = new Date(
          newDate.setFullYear(newDate.getFullYear() + this.userdata[0].duration)
        );
        if (today > expireddate) {
          this.isexpired = true;
        } else {
          this.isexpired = false;
        }
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    this.myParam = urlParams.get('exam');
  }
  
  redirectToPackages() {
    $.confirm({
      icon: 'fas fa-trash-alt',
      title: 'Subscribe?',
      content: 'You have not subscribed to this package.',
      type: '',
      draggable: false,
      typeAnimated: true,
      buttons: {
        subscribe: {
          text: 'Subscribe',
          btnClass: 'btn-blue',
          action: () => {
            this.router.navigate([''], { fragment: 'packages' });
          },
        },
        Cancel: () => {},
      },
    });
  }
  getTest() {
    this.userid = this.adataservice.isAuthenticated()
      ? this.adataservice.getUserId()
      : 0;
    this.testService
      .getFreeTest(this.testtype, 0, this.pageindex, this.pagesize, this.userid)
      .subscribe((data) => {
        if (data.flag) {
          if (data[0].length > 0) {
            this.testList = data[0];
            this.testcount = data[1][0].testcount;
          }
        }
      });
  }
  getTestBytype(type) {
    if (type !== this.testtype) {
      localStorage.setItem('planguage', type);
      this.testList = [];
      this.testtype = type;
      this.pageindex = 1;
      this.getTest();
    }
  }
  pageChanged(event) {
    this.pageindex = event;
    this.getTest();
  }

  public trackByIndex(index: number) {
    return index;
  }

  // @HostListener('window:keyup', ['$event'])
  // onkeypress(event: any) {
  //   if (this.teststatus === 1) {
  //     if (event.key !== 'Backspace') {
  //       this.currentBackSpace = 0;
  //       if (this.paragrapharray[this.cindex] === event.key) {
  //         document.getElementById('key_' + this.cindex).classList.add('success');
  //       } else {
  //         let aud: any = document.getElementById('audio');
  //         aud.play();
  //         document.getElementById('key_' + this.cindex).classList.add('failed');
  //         document.getElementById('key_' + this.cindex).classList.add('shake');
  //         document.getElementById('key_' + this.cindex).classList.add('shake-constant');
  //         document.getElementById('key_' + this.cindex).classList.add('shake-little');

  //         this.mistakes++;
  //       }
  //       this.cindex++;
  //     } else if (event.key === 'Backspace' && this.cindex > 0 && this.allowback) {
  //       this.currentBackSpace++;
  //       this.cindex--;
  //       this.backspace++;
  //       document.getElementById('key_' + this.cindex).classList.remove('success');
  //       document.getElementById('key_' + this.cindex).classList.remove('failed');
  //       document.getElementById('key_' + this.cindex).classList.remove('shake');
  //       document.getElementById('key_' + this.cindex).classList.remove('shake-constant');
  //       document.getElementById('key_' + this.cindex).classList.remove('shake-little');
  //       this.mistakes = document.getElementsByClassName('failed').length;

  //     } else if (event.key === 'Backspace' && this.cindex === 0 && this.allowback) {
  //       this.currentBackSpace++;
  //       document.getElementById('key_' + this.cindex).classList.remove('success');
  //       document.getElementById('key_' + this.cindex).classList.remove('failed');
  //       document.getElementById('key_' + this.cindex).classList.remove('shake');
  //       document.getElementById('key_' + this.cindex).classList.remove('shake-constant');
  //       document.getElementById('key_' + this.cindex).classList.remove('shake-little');
  //       this.mistakes = document.getElementsByClassName('failed').length;
  //     }
  //     if (this.backallow === 'One Backspace') {
  //       if (this.currentBackSpace > 0) {
  //         this.allowback = false;
  //       } else {
  //         this.allowback = true;
  //       }
  //     } else if (this.backallow === 'Full Backspace') {
  //       this.allowback = true;
  //     } else if (this.backallow === 'Disable Backspace') {
  //       this.allowback = false;
  //     }
  //   }
  // }
}
