<link rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
    integrity="sha512-D1Otn9GdFr+QW+vPybDv8BwSe71B/eZFLFlkmCpm9n2cL6Ceqb6Yn/BHJ/Zx3qE8wWwoU6vx/DGdrhX9vT8M6g=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
    integrity="sha512-D1Otn9GdFr+QW+vPybDv8BwSe71B/eZFLFlkmCpm9n2cL6Ceqb6Yn/BHJ/Zx3qE8wWwoU6vx/DGdrhX9vT8M6g=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />

<div id="footer">
    <div class="container">
        <div class="row ">
            <div class="col-lg-3 col-md-4 mr-0">
                <img src="assets/images/logo_website.jpg" alt="Logo">
            </div>

            <div class="col-lg-3 ml-xl-auto col-md-4 mr-0">
                <div class="text-center text-md-left mt-4">
                    <h4 class="fs-13 text-uppercase fw-600 border-bottom
                        border-gray-900 pb-2 mb-4">
                        Contact Info
                    </h4>
                    <ul class="list-unstyled">
                        <li class="mb-2">
                            <span class="d-block opacity-30">Phone:</span>
                            <span class="d-block opacity-70">+91-9509678468</span>
                        </li>
                        <li class="mb-2">
                            <span class="d-block opacity-30">Email:</span>
                            <span class="d-block opacity-70">
                                <a href="mailto:teztypinghelpcenter@gmail.com"
                                    class="text-reset">teztypinghelpcenter@gmail.com</a>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="pt-3 pb-7 pb-xl-3 bg-black text-light" id="footer1">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="text-center text-md-left" current-verison="5.0">
                    <span class="d-block d-sm-inline">Copyright �
                        <script>document.write(new Date().getFullYear())</script>
                        All Right Reserved.</span>
                </div>
            </div>
            <div class="col-lg-4">
                <ul class="social-icons" style="margin-top: revert;">
                    <li><a
                            href="https://api.whatsapp.com/send?phone=7791920694"><i
                                class="fab fa-whatsapp"></i></a></li>
                    <li><a href="mailto:teztypinghelpcenter@gmail.com"><i
                                class="far
                                fa-envelope"></i></a></li>
                    <li><a
                            href="https://www.facebook.com/profile.php?id=100063698664392"><i
                                class="fab fa-facebook"></i></a></li>
                </ul>
            </div>
            <div class="col-lg-4 payment-option">
                <div class="text-center text-md-right">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <img src="assets/images/payment_icons.png"
                                height="30" class="mw-100 h-auto"
                                style="max-height: 30px">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>



<!-- End Theme change -->
<!-- BACK TO TOP -->
<span id="back_to_top" class="scrollup" title="Back to top">
    <i class="fa fa-chevron-up" aria-hidden="true"></i>
</span>