import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EncDecService } from './enc-dec.service';
import { AuthService } from './auth.service';
import { Permissions } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthdataService {
  userdata: any;
  tmpdata: any;
  helper = new JwtHelperService();
  constructor(private encdec: EncDecService,
    private aservice: AuthService) { }
  public getToken(): string {
    try {
      if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
        return JSON.parse(this.encdec.convertText('dec', localStorage.getItem('token'), true));
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  public setToken(token) {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }

  public isAuthenticated(): boolean {
    const token: any = this.getToken();
    if (token && token !== null && token.token) {
      try {
        return this.helper.decodeToken(token.token);
      } catch (Error) {
        return false;
      }
    }
    return false;
  }
  public isTokeExpired(): boolean {
    const token: any = this.getToken();
    if (token && token !== null && token.token) {
      try {
        return this.helper.isTokenExpired(token.token);
      } catch (Error) {
        return true;
      }
    }
    return true;
  }
  public isRefreshTokeExpired(): boolean {
    const token: any = this.getToken();
    if (token && token !== null && token.token) {
      try {
        return this.helper.isTokenExpired(token.refreshtoken);
      } catch (Error) {
        return true;
      }
    }
    return true;
  }
  public getUserId(): any {
    if (this.isAuthenticated()) {
      const token: any = this.getToken();
      if (token && token != null && token.token) {
        return this.helper.decodeToken(token.token).sub;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public getUserUserName(): any {
    if (this.isAuthenticated()) {
      const token: any = this.getToken();
      if (token && token != null && token.token) {
        return this.helper.decodeToken(token.token).name;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  public getNewTicket(rtoken) {
    this.aservice.getNewToken(rtoken).subscribe(data => {
      if (data.flag) {
        this.setToken(data.outdata);
        return this.getToken();
      } else {
        this.setToken('');
      }
    });
  }

  public getPermission(menuname) {
    const permissiondata = new Permissions();
    if (this.isAuthenticated()) {
      let permissions = (localStorage.getItem('pdatasmc')) ? JSON.parse(this.encdec.convertText('dec', localStorage.getItem('pdatasmc'))) : null;
      if (permissions) {
        const pdata = permissions.filter(p => p.menu === menuname)
        pdata.forEach(element => {
          switch (element.permission.toLowerCase()) {
            case 'view': {
              permissiondata.canView = true;
              break;
            }
            case 'create': {
              permissiondata.canAdd = true;
              break;
            }
            case 'update': {
              permissiondata.canEdit = true;
              break;
            }
            case 'delete': {
              permissiondata.canDelete = true;
              break;
            }
          }
        });
      }
    }
    return permissiondata;
  }
  public getUserData(): any {
    const tmpdata = localStorage.getItem('userdata');
    if (tmpdata === '' || tmpdata == null) {
      this.userdata = '';
    } else {
      this.userdata = JSON.parse(this.encdec.convertText('dec', localStorage.getItem('userdata')));
    }
    return this.userdata;
  }
  public setUserData(data): void {
    if (data) {
      localStorage.setItem('userdata', this.encdec.convertText('enc', JSON.stringify(data)));
      // localStorage.setItem('userdata', data);
    } else {
      localStorage.removeItem('userdata');
    }
  }
  public setPermission(permission) {
    if (permission) {
      // localStorage.setItem('pdatasmc', permission);
      localStorage.setItem('pdatasmc', this.encdec.convertText('enc', JSON.stringify(permission)));
    } else {
      localStorage.removeItem('pdatasmc');
    }
  }
  public setMenu(menu) {
    if (menu) {
      localStorage.setItem('mdatasmc', this.encdec.convertText('enc', JSON.stringify(menu)));
    } else {
      localStorage.removeItem('mdatasmc');
    }
  }
  public getMenu() {
    const menu = (localStorage.getItem('mdatasmc')) ? JSON.parse(this.encdec.convertText('dec', localStorage.getItem('mdatasmc'))) : [];
    return menu.filter(m => m.canbind === 1);
  }
}
