<!-- <app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer" style="height: 60.1094px;"></div> -->
<div *ngIf="canshow">
    <section class="pb-4" id="container" style="background: #f2f2f2;" *ngIf="!testSubmit">
        <div class="bg-theme-secondary" style="padding: 10px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col align-self-center">
                        <div class="form-row">
                            <div class="col align-self-center">
                                <div class="btn-group">
                                    <button *ngIf="!exammode" type="button" (click)="exammode=true;openFullscreen()" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" aria-haspopup="true" aria-expanded="false">
                                        Enter Exam Mode
                                    </button>
                                    <button *ngIf="exammode" type="button" (click)="exammode=false;closeFullscreen()" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" aria-haspopup="true" aria-expanded="false">
                                        Exit Exam Mode
                                    </button>
                                </div>

                            </div>
                            <div class="col align-self-center">
                                <span class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;" *ngIf="!sound" (click)="sound=true">
                                    <i class="fas fa-volume-mute"></i> Key Sound
                                </span>
                                <span class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" *ngIf="sound" style="cursor: pointer;" (click)="sound=false">
                                    <i class="fas fa-volume-up"></i> Key Sound
                                </span>
                            </div>
                            <div class="col align-self-center">
                                <span class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" *ngIf="teststatus===0" style="cursor: pointer;" (click)="startTest()">
                                    <i class="fas fa-play-circle"></i>
                                    Start
                                </span>
                                <span class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;" *ngIf="teststatus===1" (click)="stopTest()">
                                    <i class="fas fa-pause-circle"></i>
                                    Pause
                                </span>
                                <span class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;" *ngIf="teststatus===2" (click)="startTest()">
                                    <i class="fas fa-play-circle"></i>
                                    Resume
                                </span>
                            </div>
                            <div class="col align-self-center" *ngIf="teststatus===1">
                                <span class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill">
                                    <i class="fas fa-clock"></i>
                                    <span class="bg-theme-primary d-inline-block ml-1 px-1 py-1 rounded"> {{h}} : {{m}}
                                        :
                                        {{s}}
                                    </span>
                                </span>
                            </div>
                            <div class="col align-self-center" *ngIf="teststarted===0">
                                <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="testDetails.duration" (change)="timechanged()">
                                    <option [ngValue]="1">1 Minute</option>
                                    <option [ngValue]="2">2 Minute</option>
                                    <option [ngValue]="3">3 Minute</option>
                                    <option [ngValue]="4">4 Minute</option>
                                    <option [ngValue]="5">5 Minute</option>
                                    <option [ngValue]="6">6 Minute</option>
                                    <option [ngValue]="7">7 Minute</option>
                                    <option [ngValue]="8">8 Minute</option>
                                    <option [ngValue]="9">9 Minute</option>
                                    <option [ngValue]="10">10 Minute</option>
                                    <option [ngValue]="11">11 Minute</option>
                                    <option [ngValue]="12">12 Minute</option>
                                    <option [ngValue]="13">13 Minute</option>
                                    <option [ngValue]="14">14 Minute</option>
                                    <option [ngValue]="15">15 Minute</option>
                                    <option [ngValue]="16">16 Minute</option>
                                    <option [ngValue]="17">17 Minute</option>
                                    <option [ngValue]="18">18 Minute</option>
                                    <option [ngValue]="19">19 Minute</option>
                                    <option [ngValue]="20">20 Minute</option>
                                    <option [ngValue]="21">21 Minute</option>
                                    <option [ngValue]="22">22 Minute</option>
                                    <option [ngValue]="23">23 Minute</option>
                                    <option [ngValue]="24">24 Minute</option>
                                    <option [ngValue]="25">25 Minute</option>
                                    <option [ngValue]="26">26 Minute</option>
                                    <option [ngValue]="27">27 Minute</option>
                                    <option [ngValue]="28">28 Minute</option>
                                    <option [ngValue]="29">29 Minute</option>
                                    <option [ngValue]="30">30 Minute</option>
                                </select>
                            </div>
                            <div class="col align-self-center">
                                <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="backallow">
                                    <option>One Backspace</option>
                                    <option>Full Backspace</option>
                                    <option>Disable Backspace</option>
                                </select>
                            </div>
                            <div class="col align-self-center" *ngIf="teststarted===0">
                                <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="checkType">
                                    <option>Word Highlight</option>
                                    <option>No Highlight</option>
                                </select>
                            </div>

                            <div class="col align-self-center">
                                <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="fontsize">
                                    <option>10px</option>
                                    <option>20px</option>
                                    <option>30px</option>
                                    <option>40px</option>
                                    <option>50px</option>
                                    <option>60px</option>
                                    <option>70px</option>
                                    <option>80px</option>
                                    <option>90px</option>
                                    <option>100px</option>
                                </select>
                            </div>
                            <div class="col-auto" *ngIf="teststarted===1">
                                <div class="btn-group">
                                    <button type="button" (click)="submitTest()" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" aria-haspopup="true" aria-expanded="false">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-2">
            <div class="col-md-12" id="editor" style="border-style: groove;
            word-wrap: break-word;
            flex-wrap: wrap;
           overflow-y: scroll;
           text-align: justify;
    word-break: break-all;
            height: 250px;
       max-height: 250px;" [style.fontSize]="fontsize">
                <span *ngFor="let word of paragrapharray;trackBy: trackByIndex; index as index;" [ngClass]="(cindex===index && checkType!='No Highlight')?'blink':''" class="cspan" id="key_{{index}}" [innerHTML]="word+'&nbsp'"></span>
            </div>
            <div class="col-md-12">
                <div class="row border" style="background: #38566c;padding: 5px;">
                    <div class="col-auto align-self-center" *ngIf="language==='Hindi'">
                        <span data-toggle="modal" data-target="#myModal" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;">
                            Show Alt Codes
                        </span>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='Hindi'">
                        <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                            <option> Alt Codes Allow</option>
                            <option> Alt Codes Not Allow</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='Hindi'">
                        <select class="form-control form-control-sm bg-gray-light" (change)="setFont()" [(ngModel)]="font">
                            <option>Kruti Dev</option>
                            <option>DevLys</option>
                            <option>Bold</option>
                        </select>
                    </div>



                    <div class="col-auto align-self-center" *ngIf="language==='MangalRem'">
                        <span data-toggle="modal" data-target="#myModal" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;">
                            Show Alt Codes
                        </span>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalRem'">
                        <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                            <option> Alt Codes Allow</option>
                            <option> Alt Codes Not Allow</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalRem'">
                        <select class="form-control form-control-sm bg-gray-light" (change)="setFont()" [(ngModel)]="font">
                            <option>Kruti Dev</option>
                            <option>DevLys</option>
                            <option>Bold</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalCBI'">
                        <span data-toggle="modal" data-target="#myModal" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;">
                            Show Alt Codes
                        </span>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalCBI'">
                        <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                            <option> Alt Codes Allow</option>
                            <option> Alt Codes Not Allow</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalCBI'">
                        <select class="form-control form-control-sm bg-gray-light" (change)="setFont()" [(ngModel)]="font">
                            <option>Kruti Dev</option>
                            <option>DevLys</option>
                            <option>Bold</option>
                        </select>
                    </div>

                    <div class="col-auto align-self-center" *ngIf="language==='MangalIns'">
                        <span data-toggle="modal" data-target="#myModal" class="border bg-transparent color-white px-3 pr-2 py-2  rounded-pill" style="cursor: pointer;">
                            Show Alt Codes
                        </span>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalIns'">
                        <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                            <option> Alt Codes Allow</option>
                            <option> Alt Codes Not Allow</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="language==='MangalIns'">
                        <select class="form-control form-control-sm bg-gray-light" (change)="setFont()" [(ngModel)]="font">
                            <option>Kruti Dev</option>
                            <option>DevLys</option>
                            <option>Bold</option>
                        </select>
                    </div>

                    <div class="col-auto align-self-center" *ngIf="teststarted===0 && checkType!='No Highlight'">
                        <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="scrollopt">
                            <option>Auto Scroll</option>
                            <option>Manual Scroll</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center" *ngIf="teststarted===0">
                        <select class="form-control form-control-sm bg-gray-light" (change)="getSize()" [(ngModel)]="psize">
                            <option value="{{d}}" *ngFor="let d of parrange">{{d}} Words</option>
                        </select>
                    </div>
                    <div class="col align-self-center text-right">
                        <span class=" bg-transparent color-white px-3 pr-2 py-2  rounded-pill">
                            Exercise {{testid}} of {{totaltest}}
                        </span>
                    </div>
                    <div class="col-auto align-self-center">
                        <button class="btn btn-light" [disabled]="1==testid" (click)="testid=testid-1;getTest();"><i
                                class="fas fa-backward"></i></button>
                    </div>
                    <div class="col-auto align-self-center">
                        <select class="form-control form-control-sm bg-gray-light" (change)="getTest()" [(ngModel)]="testid">
                            <option value="{{i}}" *ngFor="let i of indexlist">{{i}}</option>
                        </select>
                    </div>
                    <div class="col-auto align-self-center">
                        <button class="btn btn-light" [disabled]="totaltest==testid" (click)="getNextTest();"><i
                                class="fas fa-forward"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="border-style: groove; padding: 10px;">
                <textarea id="textInput" data-gramm_editor=" false" spellcheck="false" (mousedown)="inputClicked($event)" [(ngModel)]="userInput" (keyup)="fnuserInput1($event)" (keydown)="fnuserInput($event)" style="border-style: groove;
            word-wrap: break-word;
            display: flex;
            flex-wrap: wrap;
            font-weight: bold;
            font-size: 20px;
            overflow-y: scroll;
            width: 100%;
            height: 250px;"></textarea>

            </div>
        </div>
        <audio id="audio" controls style="display:none">
            <source src="/assets/key.mp3" type="audio/mpeg"> Your browser does not support the audio element.
        </audio>
    </section>
    <section class="pb-4" *ngIf="testSubmit">
        <div class="bg-theme-secondary text-center rounded p-3" style="padding: 5px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col align-self-center text-left">
                        <a class="btn btn-secondary" [routerLink]="['/learn-typing']"><i class="fas fa-arrow-left"></i>
                            Back
                            to list</a>
                    </div>
                    <div class="col  align-self-center text-white" style="
                    font-size: xx-large;
                    font-family: ui-sans-serif;
                ">
                        Test Results
                    </div>
                    <div class="col  align-self-center text-right text-white">
                        <img style="
                        background: white;
                        width: 146px;
                        /* height: 58px; */
                        border-radius: 50px;
                    " src="./assets/images/160390492852302904.png" alt="Tez Typing test results">
                    </div>
                    <div class="col-auto  align-self-center text-right text-white">
                        <button class="btn btn-light" [disabled]="1==testid" (click)="testid=testid-1;getTest();"><i
                                class="fas fa-backward"></i> Previous</button>
                    </div>
                    <div class="col-auto  align-self-center text-right text-white">
                        <button class="btn btn-light" [disabled]="totaltest==testid" (click)="getNextTest();"><i
                                class="fas fa-forward"></i> Next</button>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="container-fluid">
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Final test results
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th>Result</th>
                                <th [ngClass]="(testResult==='Qualified')?'text-success':'text-danger'">{{testResult}}
                                </th>
                                <th>NET(WPM) SPEED</th>
                                <th>{{rsnwpm}}</th>
                            </tr>
                            <tr>
                                <th>GROSS SPEED</th>
                                <th>{{rsgrossspeed}}</th>
                                <th>GROSS WPM(WORD PER MIN)</th>
                                <th>{{rsgwpm}}</th>
                            </tr>
                            <tr>
                                <th>REQUIRED ACCURACY</th>
                                <th>{{passingmarks.toFixed(2)}}%</th>
                                <th>GAINED ACCURACY</th>
                                <th>{{rsaccuracy.toFixed(2)}}%</th>
                            </tr>
                            <tr>
                                <th>Error</th>
                                <th>{{rserrors.toFixed(2)}}%</th>
                                <th>Total Words</th>
                                <th>{{rstw}}</th>
                            </tr>
                            <tr>
                                <th>Typed Words</th>
                                <th>{{rstpw}}</th>
                                <th>Skipped Words</th>
                                <th>{{rstw-rstpw}}</th>
                            </tr>
                            <tr>
                                <th>Correct Words</th>
                                <th>{{rstpw-mistakes}}</th>
                                <th>Incorrect Words</th>
                                <th>{{mistakes}}</th>
                            </tr>
                            <tr>
                                <th>Total time for the test</th>
                                <th>{{h}}:{{m}}:{{s}}</th>
                                <th>Time taken</th>
                                <th>{{rstime}}</th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Original Paragraph
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="paragraph"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Paragraph you typed
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="userInput"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; " class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Error's in paragraph&nbsp;<span class="failed">Incorrect</span>&nbsp;<span class="match">Correct</span> &nbsp;
                    <span class="extra">Extra</span> &nbsp;
                    <span class="missing">Missing</span>
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="paradiff"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
        </div>
    </section>

    <!-- Modal -->
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <!-- Modal content-->
            <div class="modal-content">

                <div class="modal-body" *ngIf="language==='Hindi'">
                    <object width="100%" height="100%" data="/assets/hindi-typing-code-krutidev.pdf"></object>
                </div>
                <div class="modal-body" *ngIf="language==='MangalRem'">
                    <object width="100%" height="100%" data="/assets/MangalRem-Alt-codes.pdf"></object>
                </div>
                <div class="modal-body" *ngIf="language==='MangalCBI'">
                    <object width="100%" height="100%" data="/assets/MangalRem-Alt-codes.pdf"></object>
                </div>
                <div class="modal-body" *ngIf="language==='MangalIns'">
                    <object width="100%" height="100%" data="/assets/Mangal-Inscript.pdf"></object>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>