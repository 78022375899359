<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="main-content col-12 px-4 mt-5 p-3">
    <main>
        <div class="container">
            <ul class="gradient-list">
                <p *ngFor="let list of tutoriallist">
                    <strong style="    font-size: x-large;
                    text-decoration: underline;"><a class="btn-link" href="javascript:void()"
                        (click)="downloadpdf(list)">{{list.name}}</a></strong><br>
                    <span>{{list.description}}</span>
                </p>
            </ul>
        </div>
    </main>
    <!-- <div class="card shadow my-4">
        <div class="card-header border-0">
            <div class="row">
                <div class="col">
                    <h5 class="d-inline-block align-middle my-0 mr-3">
                        Tutorial List</h5>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 px-0">

            <div class="table-responsive">
                <table class="table table-striped mb-0">
                    <thead class="text-uppercase">
                        <tr>
                            <th>Name

                            </th>

                            <th>Description

                            </th>
                            <th>PDF

                            </th>
                            <th width="190" class="p-0 align-middle">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="tutoriallist && tutoriallist.length>0">
                            <tr *ngFor="let list of tutoriallist | paginate: {itemsPerPage: pageSize,
                                currentPage: pageIndex,
                                totalItems: tcount};let index = index">
                                <td class="align-middle">{{list.name}}</td>

                                <td class="align-middle">
                                    {{list.description}}
                                </td>
                                <td class="align-middle">
                                    <a target="_blank" href="{{list.link}}">{{list.filename}}</a>
                                </td>
                                <td class="align-middle">
                                    <label role="button" (click)="downloadpdf(list)" aria-label="edit">
                                        <i class="material-icons material-icons-15"> create </i>
                                        download
                                    </label>
                         ]       </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="(tutoriallist && tutoriallist.length == 0) || !tutoriallist">
                            <tr class="text-center text-color-red">
                                <td colspan="100">
                                    No Data Found
                                </td>
                            </tr>
                        </ng-container>


                    </tbody>
                </table>
            </div>
            <div class="px-3">
                <div class="row align-items-center">
                    <div class="col">
                        <select style="width: 60px;" class="custom-select custom-select-sm mr-2" id="inputGroupSelect01"
                            [(ngModel)]="pageSize" (change)="getAllTutorialByFilter()" aria-label="pagesize">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <p class="d-inline-block" *ngIf="(pageIndex*pageSize)>tcount">Displaying
                            {{(tcount==0)?0:((pageIndex-1)*pageSize) + 1}} - {{tcount}} of
                            {{tcount}}
                            records</p>
                        <p class="d-inline-block" *ngIf="(pageIndex*pageSize)<tcount">Displaying
                            {{((pageIndex-1)*pageSize) + 1}} -
                            {{(((pageIndex-1)*pageSize) + (pageSize*1))}} of
                            {{tcount}}
                            records</p>
                    </div>
                    <div class="col-auto">
                        <nav aria-label="Page navigation example">
                            <pagination-controls autoHide="true" (pageChange)="pageChanged($event)">
                            </pagination-controls>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>