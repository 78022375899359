<app-header>

</app-header>

<section class="exam_design" id="exam_design">
    <div class="container-fluid">
        <div class="rows">
            <h1 class="inner_page_title">Learning and Exams</h1>
        </div>
        <div class="row Learning_row">
            <!-- <div class="col-lg-3 col-md-3 order-1 order-lg-2 card p-1"> -->
            <div class="col-md-4 card english_learn_card">

                <h3 class="title suitable-title-learning ">Learning</h3>
                <div class="row">
                    <div class="col-md-6 left_card-tez">
                        <a class="tez-cat-box tez-cat-box-1 text-center
                            card" [routerLink]="['/learn-typing-list']" [queryParams]="{language:'English'}"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="inner">
                                <div class="icons">
                                    <img src="/assets/images/laptop.png" alt="Icons Images">
                                </div>
                                <div class="content">
                                    <h5 class="title suitable-title-learning"> English <br>
                                        Learning</h5>

                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-md-6 left_card-tez">
                        <a class="tez-cat-box tez-cat-box-1 text-center
                            card" [routerLink]="['/learn-typing-list']" [queryParams]="{language:'Number'}"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="inner">
                                <div class="icons">
                                    <img src="/assets/images/keypad.png" alt="Icons Images">
                                </div>
                                <div class="content">
                                    <h5 class="title
                                        suitable-title-learning">Numerical
                                        Learning</h5>

                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-8 card hindi_learn_card">
                <h3 class="title suitable-title-learning ">Hindi Learning</h3>
                <div class="row">
                    <div class="col-md-3 left_card-tez">
                        <a class="tez-cat-box tez-cat-box-1 text-center
                            card" [routerLink]="['/learn-typing-list']" [queryParams]="{language:'Hindi'}"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="inner">
                                <div class="icons">
                                    <img src="/assets/images/hindi_learn.png" alt="Icons
                                        Images">
                                </div>
                                <div class="content">
                                    <h5 class="title
                                        suitable-title-learning">Kurutidev Learning</h5>

                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 left_card-tez">
                        <a class="tez-cat-box tez-cat-box-1 text-center
                            card" [routerLink]="['/learn-typing-list']" [queryParams]="{language:'MangalRem'}"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="inner">
                                <div class="icons">
                                    <img src="/assets/images/mangal_learn.png" alt="Icons
                                        Images">
                                </div>
                                <div class="content">
                                    <h5 class="title
                                        suitable-title-learning">Mangal
                                        Remington</h5>

                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-md-3 left_card-tez">
                        <a class="tez-cat-box tez-cat-box-1 text-center
                            card" [routerLink]="['/learn-typing-list']" [queryParams]="{language:'MangalCBI'}"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="inner">
                                <div class="icons">
                                    <img src="/assets/images/cbi_learn.png" alt="Icons
                                        Images">
                                </div>
                                <div class="content">
                                    <h5 class="title
                                        suitable-title-learning">Mangal <br>CBI
                                    </h5>

                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 left_card-tez">
                        <a class="tez-cat-box tez-cat-box-1 text-center
                            card" [routerLink]="['/learn-typing-list']" [queryParams]="{language:'MangalIns'}"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="inner">
                                <div class="icons">
                                    <img src="/assets/images/inscript_learn.png" alt="Icons
                                        Images">
                                </div>
                                <div class="content">
                                    <h5 class="title suitable-title-learning">Mangal<br>
                                        Inscript
                                    </h5>

                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- </div> -->


        </div>


    </div>
</section>


<section class="gov_exam_design" id="gov_exam_design">
    <div class="container-fluid">
        <div class="row ">

            <!-- <div class="row"> -->
            <!-- <div class="col-lg-3 col-md-3 order-1 order-lg-2 card p-1">
                    <div>
                        <h5 class="title suitable-title-learning ">Learning</h5>
                        <div class="row">
                            <div class="col-md-6 left_card-tez">
                                <a class="tez-cat-box tez-cat-box-1 text-center
                                    card"
                                    [routerLink]="['/learn-typing-list']"
                                    [queryParams]="{language:'English'}"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="inner">
                                        <div class="icons">
                                            <img src="/assets/images/laptop.png"
                                                alt="Icons Images">
                                        </div>
                                        <div class="content">
                                            <h5 class="title
                                                suitable-title-learning">English
                                                Learning</h5>
    
                                        </div>
                                    </div>
                                </a>
                            </div>
    
                            <div class="col-md-6 left_card-tez">
                                <a class="tez-cat-box tez-cat-box-1 text-center
                                    card"
                                    [routerLink]="['/learn-typing-list']"
                                    [queryParams]="{language:'Number'}"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="inner">
                                        <div class="icons">
                                            <img src="/assets/images/keypad.png"
                                                alt="Icons Images">
                                        </div>
                                        <div class="content">
                                            <h5 class="title
                                                suitable-title-learning">Numerical
                                                Learning</h5>
    
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5 class="title suitable-title-learning ">Hindi Learning</h5>
                        <div class="row">
                            <div class="col-md-6 left_card-tez">
                                <a class="tez-cat-box tez-cat-box-1 text-center
                                    card"
                                    [routerLink]="['/learn-typing-list']"
                                    [queryParams]="{language:'Hindi'}"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="inner">
                                        <div class="icons">
                                            <img src="/assets/images/hindi_learn.png"
                                                alt="Icons
                                                Images">
                                        </div>
                                        <div class="content">
                                            <h5 class="title
                                                suitable-title-learning">Hindi
                                                Learning</h5>
    
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-6 left_card-tez">
                                <a class="tez-cat-box tez-cat-box-1 text-center
                                    card"
                                    [routerLink]="['/learn-typing-list']"
                                    [queryParams]="{language:'MangalRem'}"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="inner">
                                        <div class="icons">
                                            <img src="/assets/images/mangal_learn.png"
                                                alt="Icons
                                                Images">
                                        </div>
                                        <div class="content">
                                            <h5 class="title
                                                suitable-title-learning">Mangal
                                                Remington</h5>
    
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 left_card-tez">
                                <a class="tez-cat-box tez-cat-box-1 text-center
                                    card"
                                    [routerLink]="['/learn-typing-list']"
                                    [queryParams]="{language:'MangalCBI'}"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="inner">
                                        <div class="icons">
                                            <img src="/assets/images/cbi_learn.png"
                                                alt="Icons
                                                Images">
                                        </div>
                                        <div class="content">
                                            <h5 class="title
                                                suitable-title-learning">Mangal CBI
                                            </h5>
    
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-6 left_card-tez">
                                <a class="tez-cat-box tez-cat-box-1 text-center
                                    card"
                                    [routerLink]="['/learn-typing-list']"
                                    [queryParams]="{language:'MangalIns'}"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="inner">
                                        <div class="icons">
                                            <img src="/assets/images/inscript_learn.png"
                                                alt="Icons
                                                Images">
                                        </div>
                                        <div class="content">
                                            <h5 class="title
                                                suitable-title-learning">Mangal
                                                Inscript
                                            </h5>
    
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
            <div class="col-lg-12 col-md-12 order-1 order-lg-2">

                <div class="tez-course-grid-column card ">
                    <h3 class="title suitable-title-learning ">English Exams</h3>
                    <div class="row exams-card-tez ">
                        <!-- Start Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'ldc',language:'English'}" aria-haspopup="true"
                                aria-expanded="false">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        High
                                        court LDC
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End Single Card  -->
                        <!-- Start rssbldc Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'rssbldc',language:'English'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rssmb_ldc.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">RSSB
                                        LDC
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End  rssbldc Single Card  -->

                        <!-- Start steno Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'steno',language:'English'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/high_court.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">
                                    <h4 class="tez-card-title suitable-title">Raj
                                        HC / Raj STENO
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End steno Single Card  -->

                        <!-- Start cgl Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'cgl',
                                language:'English'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/ssc_cgl.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">SSC
                                        CGL
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End cgl Single Card  -->

                        <!-- Start chsl Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'chsl',
                                language:'English'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/ssc_chsl.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        SSC
                                        CHSL
                                    </h4>

                                </div>
                            </a>
                        </div>



                        <!-- End chsl Single Card  -->

                        <!-- Start beltron Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'beltron',language:'English'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/bihar_beltron_deo.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">BIHAR
                                        BELTRON DEO
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End Single beltron Card  -->




                        <!-- Start  rpsc Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'rpsc',
                                language:'English'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rpsc.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        RPSC
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End rpsc Single Card  -->
                        <!-- Start Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'ntpc',language:'English'}" aria-haspopup="true"
                                aria-expanded="false">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        NTPC
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End Single Card  -->
                        <!-- Start Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'cpct',language:'English'}" aria-haspopup="true"
                                aria-expanded="false">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        CPCT
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End Single Card  -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>





<section class="exampackages" id="exampackages">
    <div class="container-fluid">

        <div class="tez-course-grid-column card">
            <h3 class="title suitable-title-learning">Hindi Exams</h3>




            <div class="col-lg-12 col-md-12 order-1 order-lg-2">

                <div class="tez-course-grid-column card ">
                    <h4 class="title suitable-title-learning text-left">Kurutidev</h4>
                    <div class="row exams-card-tez ">

                        <!-- Start ldc Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'ldc',language:'Hindi'}" aria-haspopup="true"
                                aria-expanded="false">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        High
                                        court LDC
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End ldc Single Card  -->
                        <!-- Start rssbldc Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <div class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'rssbldc',language:'Hindi'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rssmb_ldc.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">RSSB
                                        LDC
                                    </h4>

                                </div>
                            </div>
                        </div>
                        <!-- End rssbldc Single Card  -->

                        <!-- Start steno Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'steno',language:'Hindi'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/high_court.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">
                                    <h4 class="tez-card-title suitable-title">Raj
                                        HC / Raj STENO
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End steno Single Card  -->

                        <!-- Start cgl Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'cgl',
                                language:'Hindi'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/ssc_cgl.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">SSC
                                        CGL
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End cgl Single Card  -->

                        <!-- Start chsl Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'chsl',
                                language:'Hindi'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/ssc_chsl.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        SSC
                                        CHSL
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End  chsl Single Card  -->

                        <!-- Start beltron Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'beltron',language:'Hindi'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/bihar_beltron_deo.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">BIHAR
                                        BELTRON DEO
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End  beltron Single Card  -->




                        <!-- Start  rpsc Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <div class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'rpsc',
                                language:'Hindi'}">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rpsc.png" alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        RPSC
                                    </h4>

                                </div>

                            </div>
                        </div>

                        <!-- Start Single Card  -->
                        
                        <!-- End Single Card  -->
                        <!-- Start Single Card  -->
                        <div class="col-md-2 col-sm-6 course-grid-3">
                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                [queryParams]="{exam:'cpct',language:'Hindi'}" aria-haspopup="true"
                                aria-expanded="false">
                                <div class="tez-card-img">

                                    <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                        alt="Card image">

                                </div>
                                <div class="tez-card-body">

                                    <h4 class="tez-card-title suitable-title">
                                        CPCT
                                    </h4>

                                </div>
                            </a>
                        </div>
                        <!-- End Single Card  -->
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 order-1 order-lg-2">

                        <div class="tez-course-grid-column card ">
                            <h4 class="title suitable-title-learning text-left">Mangal Remington</h4>
                            <div class="row exams-card-tez ">

                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                        [queryParams]="{exam:'ldc',language:'MangalRem'}" aria-haspopup="true"
                                        aria-expanded="false">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">
                                                High
                                                court LDC
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->
                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                        [queryParams]="{exam:'rssbldc',language:'MangalRem'}">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/rssmb_ldc.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">RSSB
                                                LDC
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->

                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                        [queryParams]="{exam:'steno',language:'MangalRem'}">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/high_court.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">
                                            <h4 class="tez-card-title suitable-title">Raj
                                                HC / Raj STENO
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->

                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'cgl',
                                            language:'MangalRem'}">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/ssc_cgl.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">SSC
                                                CGL
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->

                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'chsl',
                                            language:'MangalRem'}">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/ssc_chsl.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">
                                                SSC
                                                CHSL
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->

                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                        [queryParams]="{exam:'beltron',language:'MangalRem'}">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/bihar_beltron_deo.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">BIHAR
                                                BELTRON DEO
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->




                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']" [queryParams]="{exam:'rpsc',
                                            language:'MangalRem'}">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/rpsc.png" alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">
                                                RPSC
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->
                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                        [queryParams]="{exam:'ntpc',language:'MangalRem'}" aria-haspopup="true"
                                        aria-expanded="false">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">
                                                NTPC
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->
                                <!-- Start Single Card  -->
                                <div class="col-md-2 col-sm-6 course-grid-3">
                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                        [queryParams]="{exam:'cpct',language:'MangalRem'}" aria-haspopup="true"
                                        aria-expanded="false">
                                        <div class="tez-card-img">

                                            <img class="image_card_tez" src="/assets/images/rajasthan_high_court.png"
                                                alt="Card image">

                                        </div>
                                        <div class="tez-card-body">

                                            <h4 class="tez-card-title suitable-title">
                                                CPCT
                                            </h4>

                                        </div>
                                    </a>
                                </div>
                                <!-- End Single Card  -->

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 order-1 order-lg-2">

                                <div class="tez-course-grid-column card ">
                                    <h4 class="title suitable-title-learning text-left">Mangal CBI</h4>
                                    <div class="row exams-card-tez ">

                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'ldc',language:'MangalCBI'}" aria-haspopup="true"
                                                aria-expanded="false">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez"
                                                        src="/assets/images/rajasthan_high_court.png" alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">
                                                        High
                                                        court LDC
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->
                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'rssbldc',language:'MangalCBI'}">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez" src="/assets/images/rssmb_ldc.png"
                                                        alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">RSSB
                                                        LDC
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->

                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'steno',language:'MangalCBI'}">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez" src="/assets/images/high_court.png"
                                                        alt="Card image">

                                                </div>
                                                <div class="tez-card-body">
                                                    <h4 class="tez-card-title suitable-title">Raj
                                                        HC / Raj STENO
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->

                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'cgl',
                                                    language:'MangalCBI'}">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez" src="/assets/images/ssc_cgl.png"
                                                        alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">SSC
                                                        CGL
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->

                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'chsl',
                                                    language:'MangalCBI'}">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez" src="/assets/images/ssc_chsl.png"
                                                        alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">
                                                        SSC
                                                        CHSL
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->

                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'beltron',language:'MangalCBI'}">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez"
                                                        src="/assets/images/bihar_beltron_deo.png" alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">BIHAR
                                                        BELTRON DEO
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->




                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'rpsc',
                                                    language:'MangalCBI'}">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez" src="/assets/images/rpsc.png"
                                                        alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">
                                                        RPSC
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->
                                        <!-- Start Single Card  -->
                                        <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'ntpc',language:'MangalCBI'}" aria-haspopup="true"
                                                aria-expanded="false">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez"
                                                        src="/assets/images/rajasthan_high_court.png" alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">
                                                        NTPC
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->
                                         <!-- Start Single Card  -->
                                         <div class="col-md-2 col-sm-6 course-grid-3">
                                            <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                [queryParams]="{exam:'cpct',language:'MangalCBI'}" aria-haspopup="true"
                                                aria-expanded="false">
                                                <div class="tez-card-img">

                                                    <img class="image_card_tez"
                                                        src="/assets/images/rajasthan_high_court.png" alt="Card image">

                                                </div>
                                                <div class="tez-card-body">

                                                    <h4 class="tez-card-title suitable-title">
                                                        CPCT
                                                    </h4>

                                                </div>
                                            </a>
                                        </div>
                                        <!-- End Single Card  -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 order-1 order-lg-2">

                                        <div class="tez-course-grid-column card ">
                                            <h4 class="title suitable-title-learning text-left">Mangal Inscript</h4>
                                            <div class="row exams-card-tez ">

                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'ldc',language:'MangalIns'}"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/rajasthan_high_court.png"
                                                                alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">
                                                                High
                                                                court LDC
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->
                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'rssbldc',language:'MangalIns'}">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/rssmb_ldc.png" alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">RSSB
                                                                LDC
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->

                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'steno',language:'MangalIns'}">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/high_court.png" alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">
                                                            <h4 class="tez-card-title suitable-title">Raj
                                                                HC / Raj STENO
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->

                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'cgl',
                                                            language:'MangalIns'}">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez" src="/assets/images/ssc_cgl.png"
                                                                alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">SSC
                                                                CGL
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->

                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'chsl',
                                                            language:'MangalIns'}">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/ssc_chsl.png" alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">
                                                                SSC
                                                                CHSL
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->

                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'beltron',language:'MangalIns'}">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/bihar_beltron_deo.png"
                                                                alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">BIHAR
                                                                BELTRON DEO
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->




                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'rpsc',
                                                            language:'MangalIns'}">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez" src="/assets/images/rpsc.png"
                                                                alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">
                                                                RPSC
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->
                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'ntpc',language:'MangalIns'}"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/rajasthan_high_court.png"
                                                                alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">
                                                                NTPC
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->
                                                <!-- Start Single Card  -->
                                                <div class="col-md-2 col-sm-6 course-grid-3">
                                                    <a class="tez-card card p-1" [routerLink]="['/practice-test']"
                                                        [queryParams]="{exam:'cpct',language:'MangalIns'}"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <div class="tez-card-img">

                                                            <img class="image_card_tez"
                                                                src="/assets/images/rajasthan_high_court.png"
                                                                alt="Card image">

                                                        </div>
                                                        <div class="tez-card-body">

                                                            <h4 class="tez-card-title suitable-title">
                                                                CPCT
                                                            </h4>

                                                        </div>
                                                    </a>
                                                </div>
                                                <!-- End Single Card  -->
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>
</section>



<section class="package" id="packages">
    <div class="container-fluid">
        <div class="card p-1">
            <div class=" py-4 text-center ">
                <h3 class="font-weight-700">Live Test </h3>
                <p class="section-text m-0 font-weight-600"></p>
            </div>
            <div class="row" id="package-section1">



                <div class="col-sm-12 col-md-12">


                    <div class="container-fluid">
                        <table style="width:100%">

                            <tr>
                                <td>S.no</td>
                                <td>Rank</td>
                                <td>Name</td>
                                <td>Typed Words</td>
                                <td>Right Words</td>
                                <td>Accuracy</td>
                                <td>Net Speed</td>
                                <td>Marks</td>
                            </tr>

                        </table>
                    </div>
                    <!-- <div class="card-footer text-center">
                        <a href="#" onclick="alert('Launching Soon');"
                            class="btn p-2 px-4">Buy Now </a>
                    </div> -->
                </div>





            </div>

        </div>
    </div>

</section>

<app-footer></app-footer>