<header class="header">

    <div class="container-fluid d-flex align-items-center">
        <div class="row align-items-center flex-1">
            <div class="col-auto align-self-center mobOnly-rxt-nav-trigger-wrap position-absolute py-4"
                style="z-index: 9;">
                <div class="rxt-nav-trigger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div class="col col-lg-auto text-center text-lg-left">
                <div class="logo"
                    style="height:50px !important;width: 200px !important;background: #f8f9fa !important;">
                    <a [routerLink]="['/']">
                        <img class="img-fluid" src="./assets/images/160390492852302904.png" alt="Tez Typing">
                    </a>
                </div>
            </div>
            <div class="ml-auto">
                <div class="rxt-travel" data-rxt-travel-max="991" data-rxt-travel-appendTo="#rxtNavTravel">
                    <div id="rxtNavWrap" class="rxt-navigation-wrap">
                        <div class="mobile-social d-lg-none">
                            <ul class="p-0">
                                <li style="width: 80%;background: #1b3e5c;">

                                    <div class="logo"
                                        style="height:50px !important;width: 200px !important;background: #f8f9fa !important;">
                                        <a [routerLink]="['/']">
                                            <img class="img-fluid" src="./assets/images/160390492852302904.png"
                                                alt="Tez Typing">
                                        </a>
                                    </div>
                                </li>
                                <!-- <li> <a href="https://twitter.com/HrconnectG" target="_blank"
                                        class="mobile-social-icon color-twitter"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li> <a href="https://www.linkedin.com/company/42287448/admin/" target="_blank"
                                        class="mobile-social-icon color-linkedin"><i class="fab fa-linkedin-in"></i></a>
                                </li>
                                <li> <a href="https://www.instagram.com/hrconnect.aussizzgroup/" target="_blank"
                                        class="mobile-social-icon color-instagram"><i class="fab fa-instagram"></i></a>
                                </li> -->
                                <li>
                                    <div class="mobOnly-menu-close-link mobile-social-icon">
                                        <i class="mobOnly-menu-close fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div id="listQuickLinks"></div>
                        <div class="d-flex d-lg-block flex-column-reverse">
                            <ul class="rxt-navigation text-left">

                                <li *ngIf="uploadvideop && uploadvideop.canView">
                                    <a [routerLink]="['/user/uploadvideos']">
                                        <svg viewBox="0 0 2400 2399.9">
                                            <use xlink:href="#svg-search"></use>
                                        </svg>
                                        Upload Video</a>
                                </li>
                                <!-- <li class="active">
                                    <a [routerLink]="['/user/article']">
                                        <svg viewBox="0 0 1518.6 1400">
                                            <use xlink:href="#svg-search-history"></use>
                                        </svg>
                                        Article</a>
                                </li> -->
                                <li *ngIf="packagep && packagep.canView">
                                    <a [routerLink]="['/user/packagelist']">
                                        <svg viewBox="0 0 155.2 141.4">
                                            <use xlink:href="#svg-application"></use>
                                        </svg>
                                        Packages</a>
                                </li>
                                <li *ngIf="testhistoryp && testhistoryp.canView">
                                    <a [routerLink]="['/user/testhistory']">
                                        <svg viewBox="0 0 155.2 141.4">
                                            <use xlink:href="#svg-application"></use>
                                        </svg>
                                        Test History</a>
                                </li>
                                <li>
                                    <a href="javascript:void()" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">

                                        Learning</a>
                                    <div class="dropdown-menu dropdown-menu-right" style="">
                                        <a class="dropdown-item" [routerLink]="['/tutorials']"
                                            type="button">Tutorials</a>
                                        <!-- <a class="dropdown-item" [routerLink]="['/watch-videos']" type="button">Watch
                                            Videos</a> -->
                                        <a class="dropdown-item" [routerLink]="['/learn-typing']" type="button">Learn
                                            Typing</a>
                                        <a class="dropdown-item" [routerLink]="['/practice-test']"
                                            type="button">Practice</a>
                                        <a class="dropdown-item" [routerLink]="['/live-test']" type="button">Live
                                            Test</a>
                                    </div>
                                </li>
                                <li *ngIf="managep && managep.canView">
                                    <a href="javascript:void()" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <svg viewBox="0 0 770 810">
                                            <use xlink:href="#svg-checklist"></use>
                                        </svg>
                                        Manage</a>
                                    <div class="dropdown-menu dropdown-menu-right" style="">
                                        <a *ngIf="featuresp && featuresp.canView" class="dropdown-item" type="button"
                                            [routerLink]="['/user/features']">Features</a>
                                        <a *ngIf="rolep && rolep.canView" [routerLink]="['/user/role']"
                                            class="dropdown-item" type="button">Role / Permission</a>
                                        <a *ngIf="usersp && usersp.canView" [routerLink]="['/user/user-list']"
                                            class="dropdown-item" type="button">Users</a>
                                        <a *ngIf="inquiriesp && inquiriesp.canView" [routerLink]="['/user/inquiry']"
                                            class="dropdown-item" type="button">User Inquiries</a>
                                        <a *ngIf="testp && testp.canView" [routerLink]="['/user/test-list']"
                                            class="dropdown-item" type="button">Paid Test</a>
                                        <a *ngIf="learningp && learningp.canView"
                                            [routerLink]="['/user/learning-test-list']" class="dropdown-item"
                                            type="button">Learning</a>
                                        <a *ngIf="tutorialsp && tutorialsp.canView"
                                            [routerLink]="['/user/upload-tutorial']" class="dropdown-item"
                                            type="button">Tutorials</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="col-md  align-self-center">
                                        <div class="form-row">
                                            <div class="col-md-auto py-2 py-md-0 text-center">
                                                <div class="btn-group">
                                                    <button type="button"
                                                        class="border bg-transparent color-white px-3  rounded-pill"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <div class="border-right d-inline-block pr-2 py-2">
                                                            {{username | titlecase}}
                                                        </div>
                                                        <img src="/assets/images/down-arrow-white.png" alt="Tez Typing"
                                                            class="img-fluid ml-1" style=" height: 10px; width: 20px; ">
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-right" style="">

                                                        <a [routerLink]="['/user/editprofile']" class="dropdown-item"
                                                            type="button">Edit
                                                            Profile</a>
                                                        <!-- <a class="dropdown-item" type="button">Change
                                                            Password</a> -->
                                                        <a [routerLink]="['/auth/logout']" class="dropdown-item"
                                                            type="button"><i></i>Logout</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                        <p class="border-light-1 border-top d-lg-none mt-3 py-3 small text-center text-white">
                            Copyright ©
                            <script>document.write(new Date().getFullYear())</script> - All Rights Reserved</p>
                    </div>
                    <div class="rxt-nav-overlay"></div>
                </div>
            </div>

        </div>
    </div>
</header>

<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: none;">
    <defs>
        <g id="svg-arrow-right">
            <path
                d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"
                class=""></path>
        </g>
        <g id="svg-delete">
            <polygon points="20.2,28.9 18.5,30.6 7.3,30.6 5.6,29 3.6,9.5 1.6,9.8 3.7,29.9 6.5,32.6 19.3,32.6 22.1,29.8 24.2,9.8 22.2,9.5 	
                " />
            <rect x="8.6" y="9.6" transform="matrix(0.9993 -3.809549e-02 3.809549e-02 0.9993 -0.7351 0.3809)" width="2"
                height="19.7" />
            <rect x="6.1" y="18.5" transform="matrix(3.541007e-02 -0.9994 0.9994 3.541007e-02 -4.0484 34.7636)"
                width="19.7" height="2" />
            <path d="M20.5,5.3V3.7c0-2-1.6-3.7-3.7-3.7H9C7,0,5.3,1.6,5.3,3.7v1.7H0v2h25.9v-2H20.5z M9,2h7.9c0.9,0,1.7,0.7,1.7,1.7v1.7H7.3
                V3.7C7.3,2.7,8.1,2,9,2z" />
        </g>
        <g id="svg-search">
            <g>
                <g>
                    <path
                        d="M2334.9,2020.1l-518-517.8c94.4-151,149.6-328.8,149.6-519.4c0-542-441-983-983.3-983C441.1,0,0,441,0,983
                        c0,542.3,441.1,983.6,983.3,983.6c190.6,0,368.1-55.5,519.1-149.6l518,517.8c43.4,43.3,100.4,65.1,157.4,65.1
                        c56.8,0,113.8-21.9,157.1-65.1C2421.8,2247.9,2421.8,2107.3,2334.9,2020.1z M278.3,983c0-388.6,316.1-704.9,705-704.9
                        c389.1,0,705.3,316.3,705.3,704.9c0,388.9-316.1,705.5-705.3,705.5C594.4,1688.5,278.3,1371.9,278.3,983z" />
                </g>
            </g>
        </g>
        <g id="svg-search-history">
            <path d="M1518.6,700c0-386-314-700-700-700C485.7,0,206.4,233.8,136,545.8H30.5c-29,0-39.1,19.4-22.5,43.2l161.4,230.1
                c16.7,23.8,43.9,23.8,60.6,0l161.4-230.1c16.6-23.8,6.5-43.2-22.5-43.2h-86.1C350,313.1,564.7,142.4,818.6,142.4
                c307.5,0,557.6,250.1,557.6,557.6c0,285-214.9,520.5-491.1,553.5c-5.9,0.7-11.7,1.3-17.5,1.8c-4.1,0.3-8.3,0.7-12.5,1
                c-5.4,0.3-10.9,0.6-16.3,0.8c-4.6,0.2-9.2,0.3-13.8,0.3c-138.8,1.6-273.7-48.3-378.1-141.8c-29.3-26.2-74.3-23.8-100.5,5.5
                c-26.2,29.3-23.8,74.3,5.5,100.5C480.9,1337.3,647,1400,818.5,1400c10.7,0,21.4-0.3,32.1-0.8c2.5-0.1,5-0.2,7.5-0.4
                c10.2-0.6,20.4-1.3,30.6-2.3c2-0.2,4-0.5,6-0.7c5.2-0.6,10.5-1.1,15.7-1.8c1.2-0.2,2.4-0.4,3.6-0.6
                C1255,1346.7,1518.6,1053.6,1518.6,700z" />
            <path d="M818.6,257.3c-39.3,0-71.2,31.9-71.2,71.2V700c0,39.3,31.9,71.2,71.2,71.2h324.1c39.3,0,71.2-31.9,71.2-71.2
                c0-39.3-31.9-71.2-71.2-71.2H889.8V328.5C889.8,289.1,857.9,257.3,818.6,257.3z" />
        </g>
        <g id="svg-application">
            <path d="M107,83c0-0.9,0-1.8-0.1-2.6l8.4-5.3l-2.6-7.9l-9.9,0.7c-0.9-1.5-1.9-2.9-3.1-4.2l3.7-9.2l-6.7-4.9l-7.6,6.4
                c-1.6-0.7-3.3-1.2-5-1.6l-2.4-9.6h-8.3l-2.4,9.6c-1.7,0.4-3.4,0.9-5,1.6l-7.6-6.4l-6.7,4.9l3.7,9.2c-1.2,1.3-2.2,2.7-3.1,4.2
                l-9.9-0.7l-2.6,7.9l8.4,5.3c-0.1,0.9-0.1,1.7-0.1,2.6c0,0.9,0,1.8,0.1,2.6l-8.4,5.3l2.6,7.9l9.9-0.7c0.9,1.5,1.9,2.9,3.1,4.2
                l-3.7,9.2l6.7,4.9l7.6-6.4c1.6,0.7,3.3,1.2,5,1.6l2.4,9.6h8.3l2.4-9.6c1.7-0.4,3.4-0.9,5-1.6l7.6,6.4l6.7-4.9l-3.7-9.2
                c1.2-1.3,2.2-2.7,3.1-4.2l9.9,0.7l2.6-7.9l-8.4-5.3C107,84.7,107,83.8,107,83z M93.7,83c0,8.9-7.2,16.1-16.1,16.1
                S61.5,91.9,61.5,83s7.2-16.1,16.1-16.1S93.7,74,93.7,83z" />
            <path d="M136.1,0h-117C8.6,0,0,8.5,0,19.1v103.2c0,10.5,8.6,19.1,19.1,19.1h117c10.5,0,19.1-8.5,19.1-19.1V19.1
                C155.2,8.5,146.7,0,136.1,0z M57.5,15.1c0-1,0.8-1.9,1.9-1.9h18c1,0,1.9,0.8,1.9,1.9v6.3c0,1-0.8,1.9-1.9,1.9h-18
                c-1,0-1.9-0.8-1.9-1.9V15.1z M41.6,10.6c4.2,0,7.7,3.4,7.7,7.7c0,4.2-3.4,7.7-7.7,7.7c-4.2,0-7.7-3.4-7.7-7.7
                C33.9,14,37.3,10.6,41.6,10.6z M12.2,18.3c0-4.2,3.4-7.7,7.7-7.7c4.2,0,7.7,3.4,7.7,7.7c0,4.2-3.4,7.7-7.7,7.7
                C15.6,25.9,12.2,22.5,12.2,18.3z M140.3,120.4c0,4.2-3.4,7.5-7.5,7.5H22.5c-4.2,0-7.5-3.4-7.5-7.5v-76c0-4.2,3.4-7.5,7.5-7.5h110.2
                c4.2,0,7.5,3.4,7.5,7.5V120.4z" />
        </g>
        <g id="svg-profile">
            <path d="M73.7,59.4c0,0-0.8-0.2-2.2-0.4c-1.3,4.4-9.3,7.8-19,7.8c-9.4,0-17.2-3.2-18.8-7.4C-2.6,68.3,0,102,0,102
                c53,16.3,107.3,0,107.3,0S110,68.3,73.7,59.4z" />
            <path d="M78.4,28.9C78.4,7.7,67.4,0,53.7,0C40,0,29,7.7,29,28.9C29,44.8,40,57.7,53.7,57.7
                C67.4,57.7,78.4,44.8,78.4,28.9z" />
        </g>
        <g id="svg-checklist">
            <path d="M372.6,509.4c7.6,0,15.2,0.6,22.6,1.7c7.4,1.1,14.7,2.8,21.8,5c7.1,2.2,14.1,4.9,20.9,8.1
                c6.8,3.2,13.3,6.9,19.6,11.2c0,0,7.9-7.9,19.7-19.9c11.9-12,27.8-27.9,43.9-44c16.1-16.1,32.4-32.3,45.1-44.6
                c12.7-12.4,21.8-20.9,23.4-21.7v-91.9v-91.9v-91.9V37.6c0-5.2-1.1-10.1-3.2-14.6c-2-4.5-5-8.5-8.6-11.9c-3.6-3.4-7.9-6.2-12.6-8.1
                c-4.7-1.9-9.8-3-15.2-3H421.4H292.6H163.8H35c-5.2,0-10,1.1-14.2,3c-4.3,1.9-8,4.7-11.1,8.1S4.2,18.5,2.6,23
                C0.9,27.5,0,32.4,0,37.6v175.9v175.9v175.9v175.9c0,5.2,0.9,10,2.6,14.3c1.7,4.3,4.1,8.1,7.2,11.3c3.1,3.2,6.8,5.7,11.1,7.4
                c4.3,1.7,9,2.7,14.2,2.7h61.9h61.9h61.9h61.9l-3.9-3.6l-3.9-3.6l-3.9-3.6l-3.9-3.6c-7.1-7.1-13.4-14.7-18.8-22.8
                s-9.9-16.5-13.6-25.3c-3.6-8.8-6.4-18-8.2-27.5c-1.8-9.5-2.8-19.3-2.8-29.3c0-10,1-19.9,2.9-29.4c1.9-9.6,4.7-18.8,8.4-27.7
                c3.7-8.9,8.2-17.5,13.6-25.6c5.4-8.1,11.6-15.8,18.5-22.9c7.1-6.8,14.8-12.9,22.9-18.2c8.1-5.3,16.6-9.8,25.6-13.4
                c8.9-3.6,18.2-6.4,27.7-8.3C352.7,510.3,362.6,509.4,372.6,509.4L372.6,509.4L372.6,509.4L372.6,509.4L372.6,509.4z M194.4,102.4
                c0-5.2,1.1-10.1,3-14.6c1.9-4.5,4.7-8.5,8.1-11.9c3.4-3.4,7.5-6.2,11.9-8.1c4.5-1.9,9.4-3,14.6-3h63.8h63.8h63.8h63.8
                c5.2,0,10.1,1.1,14.6,3c4.5,1.9,8.5,4.7,12,8.1c3.4,3.4,6.2,7.5,8.1,11.9c1.9,4.5,3,9.4,3,14.6v3.9v3.9v3.9v3.9
                c0,5.2-1.1,10.1-3,14.6c-1.9,4.5-4.7,8.5-8.1,11.9c-3.4,3.4-7.5,6.2-12,8.1c-4.5,1.9-9.4,3-14.6,3h-63.8h-63.8h-63.8H232
                c-5.2,0-10.1-1.1-14.6-3c-4.5-1.9-8.5-4.7-11.9-8.1c-3.4-3.4-6.2-7.5-8.1-11.9c-1.9-4.5-3-9.4-3-14.6v-3.9v-3.9v-3.9V102.4z
                 M194.4,244.9c0-5.2,1.1-10.1,3-14.6c1.9-4.5,4.7-8.5,8.1-11.9c3.4-3.4,7.5-6.2,11.9-8.1c4.5-1.9,9.4-3,14.6-3h63.8h63.8h63.8h63.8
                c5.2,0,10.1,1.1,14.6,3c4.5,1.9,8.5,4.7,12,8.1c3.4,3.4,6.2,7.4,8.1,11.9c1.9,4.5,3,9.4,3,14.6v3.9v3.9v3.9v3.9
                c0,5.2-1.1,10.1-3,14.6c-1.9,4.5-4.7,8.5-8.1,12c-3.4,3.4-7.5,6.2-12,8.1c-4.5,1.9-9.4,3-14.6,3h-63.8h-63.8h-63.8H232
                c-5.2,0-10.1-1.1-14.6-3c-4.5-1.9-8.5-4.7-11.9-8.1c-3.4-3.4-6.2-7.5-8.1-12c-1.9-4.5-3-9.4-3-14.6v-3.9v-3.9v-3.9V244.9z
                 M104.3,436.1c-6.2,0-12-1.2-17.3-3.4c-5.3-2.2-10-5.4-14-9.4c-4-4-7.2-8.7-9.4-14c-2.2-5.3-3.4-11.1-3.4-17.3
                c0-6.2,1.2-12,3.4-17.3c2.2-5.3,5.4-10,9.4-14c4-4,8.7-7.2,14-9.4c5.3-2.2,11.1-3.4,17.3-3.4c6.2,0,12,1.2,17.3,3.4
                c5.3,2.2,10,5.4,14,9.4c4,4,7.2,8.7,9.4,14c2.2,5.3,3.4,11.1,3.4,17.3c0,6.2-1.3,12-3.5,17.3c-2.3,5.3-5.5,10-9.5,14
                c-4,4-8.8,7.2-14,9.4C116.1,434.9,110.3,436.1,104.3,436.1L104.3,436.1L104.3,436.1L104.3,436.1L104.3,436.1z M104.3,291.6
                c-6.2,0-12-1.2-17.3-3.4c-5.3-2.2-10-5.4-14-9.4c-4-4-7.2-8.7-9.4-14c-2.2-5.3-3.4-11.1-3.4-17.3c0-6.2,1.2-12,3.4-17.3
                c2.2-5.3,5.4-10,9.4-14c4-4,8.7-7.2,14-9.4c5.3-2.2,11.1-3.4,17.3-3.4c6.2,0,12,1.2,17.3,3.4c5.3,2.2,10,5.4,14,9.4
                c4,4,7.2,8.7,9.4,14c2.2,5.3,3.4,11.1,3.4,17.3c0,6-1.3,11.7-3.5,17c-2.3,5.3-5.5,10-9.5,14c-4,4-8.8,7.3-14,9.5
                C116.1,290.4,110.3,291.6,104.3,291.6L104.3,291.6L104.3,291.6L104.3,291.6L104.3,291.6z M104.3,152.9c-6.2,0-12-1.2-17.3-3.4
                c-5.3-2.2-10-5.4-14-9.4c-4-4-7.2-8.7-9.4-14c-2.2-5.3-3.4-11.1-3.4-17.3c0-6.2,1.2-12,3.4-17.3c2.2-5.3,5.4-10,9.4-14
                c4-4,8.7-7.2,14-9.4c5.3-2.2,11.1-3.4,17.3-3.4c6.2,0,12,1.2,17.3,3.4c5.3,2.2,10,5.4,14,9.4c4,4,7.2,8.7,9.4,14
                c2.2,5.3,3.4,11.1,3.4,17.3c0,6.2-1.3,12-3.5,17.3c-2.3,5.3-5.5,10-9.5,14c-4,4-8.8,7.2-14,9.4
                C116.1,151.7,110.3,152.9,104.3,152.9L104.3,152.9L104.3,152.9L104.3,152.9L104.3,152.9z M194.4,396.6v-3.9v-3.9v-3.9V381
                c0-5.2,1.1-10.1,3-14.6c1.9-4.5,4.7-8.5,8.1-12c3.4-3.4,7.5-6.2,11.9-8.1c4.5-1.9,9.4-3,14.6-3h63.8h63.8h63.8h63.8
                c5.2,0,10.1,1.1,14.6,3c4.5,1.9,8.5,4.7,12,8.1c3.4,3.4,6.2,7.5,8.1,12c1.9,4.5,3,9.4,3,14.6v3.9v3.9v3.9v3.9
                c0,5.2-1.1,10.1-3,14.6c-1.9,4.5-4.7,8.5-8.1,12c-3.4,3.4-7.5,6.2-12,8.1c-4.5,1.9-9.4,3-14.6,3h-63.8h-63.8h-63.8H232
                c-5.2,0-10.1-1.1-14.6-3c-4.5-1.9-8.5-4.7-11.9-8.1c-3.4-3.4-6.2-7.5-8.1-12C195.5,406.7,194.4,401.8,194.4,396.6L194.4,396.6
                L194.4,396.6L194.4,396.6L194.4,396.6z M194.4,396.6" />
            <path
                d="M416,791.3c3.1,3.1,6.4,5.8,9.9,8.1c3.5,2.3,7.2,4.3,11.1,5.9c3.8,1.6,7.8,2.8,11.9,3.6
                c4.1,0.8,8.3,1.2,12.5,1.2c4.2,0,8.4-0.4,12.5-1.2c4.1-0.8,8.1-2,11.9-3.6c3.8-1.6,7.6-3.5,11.1-5.9c3.5-2.3,6.8-5.1,9.9-8.1
                l60.9-60.9l60.9-60.9l60.9-60.9l60.9-60.9c6.2-6.2,10.9-13.3,14.2-20.9c3.3-7.6,5.1-15.7,5.4-23.9c0.3-8.1-0.9-16.3-3.8-24
                c-2.8-7.7-7.2-15-13.2-21.3c-6.2-6.6-13.4-11.7-21.2-15.1c-7.8-3.4-16.2-5.2-24.6-5.3c-8.4-0.1-16.8,1.4-24.7,4.6
                c-7.9,3.2-15.3,8-21.6,14.5l-43.3,43.3l-43.3,43.3L531.2,586L488,629.2c-3.7,3.7-8,6.5-12.5,8.4c-4.5,1.9-9.3,2.8-14.1,2.8
                c-4.8,0-9.6-0.9-14.1-2.8c-4.5-1.9-8.8-4.7-12.5-8.4l-5.7-5.7l-5.7-5.7l-5.7-5.7l-5.7-5.7c-6.3-6.3-13.6-11-21.3-14.2
                c-7.7-3.2-15.9-4.7-24.1-4.7c-8.2,0-16.4,1.6-24.1,4.7c-7.7,3.2-15,7.9-21.3,14.2c-6.3,6.3-11.1,13.6-14.2,21.3
                c-3.2,7.7-4.7,15.9-4.7,24.1s1.6,16.4,4.7,24.1c3.2,7.7,7.9,15,14.2,21.3l23.7,23.5l23.7,23.5l23.7,23.5L416,791.3z M416,791.3" />
        </g>
        <g id="svg-logout">
            <polygon points="121.8,180.8 197.3,180.8 197.3,220.2 298.8,147.5 197.3,74.7 197.3,114.1 121.8,114.1 	" />
            <path d="M40.2,294.9h141c22.2,0,40.2-18,40.2-40.2v-16.7H188v16.7c0,3.8-3.1,6.8-6.8,6.8h-141c-3.8,0-6.8-3.1-6.8-6.8
                V40.2c0-3.8,3.1-6.8,6.8-6.8h141c3.8,0,6.8,3.1,6.8,6.8v15h33.4v-15c0-22.2-18-40.2-40.2-40.2h-141C18,0,0,18,0,40.2v214.5
                C0,276.9,18,294.9,40.2,294.9z" />
        </g>
        <g id="svg-calander">
            <line x1="25.3" y1="5.2" x2="18.2" y2="5.2"></line>
            <path d="M5.2,5.2c-2.2,0-4,1.8-4,4V42c0,2.2,1.8,4,4,4H38c2.2,0,4-1.8,4-4V9.1c0-2.2-1.8-4-4-4">
            </path>
            <line x1="1.3" y1="15.7" x2="42" y2="15.7"></line>
            <g>
                <path d="M13.2,9.1h-3.1c-1.3,0-2.4-1.1-2.4-2.4V3.6c0-1.3,1.1-2.4,2.4-2.4h3.1c1.3,0,2.4,1.1,2.4,2.4v3.1
                                   C15.5,8.1,14.5,9.1,13.2,9.1z"></path>
                <path d="M33.2,9.1H30c-1.3,0-2.4-1.1-2.4-2.4V3.6c0-1.3,1.1-2.4,2.4-2.4h3.1c1.3,0,2.4,1.1,2.4,2.4v3.1
                                   C35.5,8.1,34.5,9.1,33.2,9.1z"></path>
            </g>
        </g>
    </defs>
</svg>