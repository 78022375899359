import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { ValidationDirective } from '../directives/validation.directive';
import { ClearValidationDirective } from '../directives/clear-validation.directive';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { UserFooterComponent } from './components/user-footer/user-footer.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EncPipe } from './pipes/encdec.pipe';
import { FilterPipe, RolePermissionPipe } from './pipes/firstcharactercolor.pipe';
import { SafePipe } from './pipes/safeurl.pipe';
import { DatePipe } from './pipes/dateformat.pipe';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ValidationDirective,
    ClearValidationDirective,
    UserHeaderComponent,
    UserFooterComponent,
    EncPipe,
    RolePermissionPipe,
    FilterPipe,
    SafePipe,
    DatePipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    NgxPaginationModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FormsModule,
    NgxPaginationModule,
    ValidationDirective,
    ClearValidationDirective,
    UserHeaderComponent,
    UserFooterComponent,
    EncPipe,
    RolePermissionPipe,
    FilterPipe,
    SafePipe,
    DatePipe
  ]
})
export class SharedModule { }
