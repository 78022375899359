export class altcodes {
    hindikeypair = [
        { id: 'true-96', code: [2381], char: '्' },
        { id: 'true-49', code: [33], char: '!' },
        { id: 'false-32', code: [32], char: '' },
        { id: 'true-50', code: [47], char: '/' },
        { id: 'true-51', code: [2352, 2369], char: 'रु' },
        { id: 'true-52', code: [43], char: '+' },
        { id: 'true-53', code: [58], char: ':' },
        { id: 'true-54', code: [8216], char: '‘' },
        { id: 'true-55', code: [45], char: '-' },
        { id: 'true-56', code: [8217], char: '’' },
        { id: 'true-57', code: [59], char: ';' },
        { id: 'true-48', code: [2342, 2381, 2343], char: 'द्ध' },
        { id: 'true-45', code: [2315], char: 'ऋ' },
        { id: 'true-61', code: [2364], char: '़' },
        { id: 'true-81', code: [2347], char: 'फ' },
        { id: 'true-87', code: [2373], char: 'ॅ' },
        { id: 'true-69', code: [2350, 2381], char: 'म्' },
        { id: 'true-82', code: [2340, 2381], char: 'त्' },
        { id: 'true-84', code: [2332, 2381], char: 'ज्' },
        { id: 'true-89', code: [2354, 2381], char: 'ल्' },
        { id: 'true-85', code: [2344, 2381], char: 'न्' },
        { id: 'true-73', code: [2346, 2381], char: 'प्' },
        { id: 'true-79', code: [2357, 2381], char: 'व्' },
        { id: 'true-80', code: [2330, 2381], char: 'च्' },
        { id: 'true-219', code: [2325, 2381, 2359, 2381], char: 'क्ष्' },
        { id: 'true-221', code: [2342, 2381, 2357], char: 'द्व' },
        { id: 'true-220', code: [2342, 2381, 2351], char: 'द्य' },
        { id: 'true-65', code: [2404], char: '।' },
        { id: 'true-83', code: [2376], char: 'ै' },
        { id: 'true-68', code: [2325, 2381], char: 'क्' },
        { id: 'true-70', code: [2341, 2381], char: 'थ्' },
        { id: 'true-71', code: [2355], char: 'ळ' },
        { id: 'true-72', code: [2349, 2381], char: 'भ्' },
        { id: 'true-74', code: [2358, 2381, 2352], char: 'श्र' },
        { id: 'true-75', code: [2332, 2381, 2334], char: 'ज्ञ' },
        { id: 'true-76', code: [2360, 2381], char: 'स्' },
        { id: 'true-186', code: [2352, 2370], char: 'रू:' },
        { id: 'true-222', code: [2359, 2381], char: 'ष्' },
        { id: 'true-90', code: [2373], char: 'ॅ' },
        { id: 'true-88', code: [2327, 2381], char: 'ग्' },
        { id: 'true-67', code: [2348, 2381], char: 'ब्' },
        { id: 'true-86', code: [2335], char: 'ट' },
        { id: 'true-66', code: [2336], char: 'ठ' },
        { id: 'true-78', code: [2331], char: 'छ' },
        { id: 'true-77', code: [2337], char: 'ड' },
        { id: 'true-188', code: [2338], char: 'ढ' },
        { id: 'true-190', code: [2333], char: 'झ' },
        { id: 'true-191', code: [2328, 2381], char: 'घ्' },
        { id: 'false-96', code: [2371], char: 'ृ' },
        { id: 'false-49', code: [2407], char: '१' },
        { id: 'false-50', code: [2408], char: '२' },
        { id: 'false-51', code: [2409], char: '३' },
        { id: 'false-52', code: [2410], char: '४' },
        { id: 'false-53', code: [2411], char: '५' },
        { id: 'false-54', code: [2412], char: '६' },
        { id: 'false-55', code: [2413], char: '७' },
        { id: 'false-56', code: [2414], char: '८' },
        { id: 'false-57', code: [2415], char: '९' },
        { id: 'false-48', code: [2406], char: '०' },
        { id: 'false-45', code: [46], char: '.' },
        { id: 'false-61', code: [2340, 2381, 2352], char: 'त्र' },
        { id: 'false-81', code: [2369], char: 'ु' },
        { id: 'false-87', code: [2370], char: 'ू' },
        { id: 'false-69', code: [2350], char: 'म' },
        { id: 'false-82', code: [2340], char: 'त' },
        { id: 'false-84', code: [2332], char: 'ज' },
        { id: 'false-89', code: [2354], char: 'ल' },
        { id: 'false-85', code: [2344], char: 'न' },
        { id: 'false-73', code: [2346], char: 'प' },
        { id: 'false-79', code: [2357], char: 'व' },
        { id: 'false-80', code: [2330], char: 'च' },
        { id: 'false-219', code: [2326, 2381], char: 'ख्' },
        { id: 'false-221', code: [44], char: ',' },
        { id: 'false-220', code: [63], char: '?' },
        { id: 'false-65', code: [2306], char: 'ं' },
        { id: 'false-83', code: [2375], char: 'े' },
        { id: 'false-68', code: [2325], char: 'क' },
        { id: 'false-70', code: [2367], char: 'ि' },
        { id: 'false-71', code: [2361], char: 'ह' },
        { id: 'false-72', code: [2368], char: 'ी' },
        { id: 'false-74', code: [2352], char: 'र' },
        { id: 'false-75', code: [2366], char: 'ा' },
        { id: 'false-76', code: [2360], char: 'स' },
        { id: 'false-186', code: [2351], char: 'य' },
        { id: 'false-222', code: [2358, 2381], char: 'श्' },
        { id: 'false-90', code: [2381, 2352], char: '्र' },
        { id: 'false-88', code: [2327], char: 'ग' },
        { id: 'false-67', code: [2348], char: 'ब' },
        { id: 'false-86', code: [2309], char: 'अ' },
        { id: 'false-66', code: [2311], char: 'इ' },
        { id: 'false-78', code: [2342], char: 'द' },
        { id: 'false-77', code: [2313], char: 'उ' },
        { id: 'false-188', code: [2319], char: 'ए' },
        { id: 'false-190', code: [2339, 2381], char: 'ण्' },
        { id: 'false-191', code: [2343, 2381], char: 'ध्' }
    ];

    codes = [
        {
            "altcode": "alt + 2304",
            "charcode": "2304",
            "char": "ऀ"
        },
        {
            "altcode": "alt + 2306",
            "charcode": "2306",
            "char": "ं"
        },
        {
            "altcode": "alt + 2307",
            "charcode": "2307",
            "char": "ः"
        },
        {
            "altcode": "alt + 2384",
            "charcode": "2384",
            "char": "ॐ"
        },
        {
            "altcode": "alt + 2305",
            "charcode": "2305",
            "char": "ँ"
        },
        {
            "altcode": "alt + 2308",
            "charcode": "2308",
            "char": "ऄ"
        },
        {
            "altcode": "alt + 2309",
            "charcode": "2309",
            "char": "अ"
        },
        {
            "altcode": "alt + 2310",
            "charcode": "2310",
            "char": "आ"
        },
        {
            "altcode": "alt + 2311",
            "charcode": "2311",
            "char": "इ"
        },
        {
            "altcode": "alt + 2312",
            "charcode": "2312",
            "char": "ई"
        },
        {
            "altcode": "alt + 2313",
            "charcode": "2313",
            "char": "उ"
        },
        {
            "altcode": "alt + 2314",
            "charcode": "2314",
            "char": "ऊ"
        },
        {
            "altcode": "alt + 2315",
            "charcode": "2315",
            "char": "ऋ"
        },
        {
            "altcode": "alt + 2316",
            "charcode": "2316",
            "char": "ऌ"
        },
        {
            "altcode": "alt + 2317",
            "charcode": "2317",
            "char": "ऍ"
        },
        {
            "altcode": "alt + 2318",
            "charcode": "2318",
            "char": "ऎ"
        },
        {
            "altcode": "alt + 2319",
            "charcode": "2319",
            "char": "ए"
        },
        {
            "altcode": "alt + 2320",
            "charcode": "2320",
            "char": "ऐ"
        },
        {
            "altcode": "alt + 2321",
            "charcode": "2321",
            "char": "ऑ"
        },
        {
            "altcode": "alt + 2322",
            "charcode": "2322",
            "char": "ऒ"
        },
        {
            "altcode": "alt + 2323",
            "charcode": "2323",
            "char": "ओ"
        },
        {
            "altcode": "alt + 2324",
            "charcode": "2324",
            "char": "औ"
        },
        {
            "altcode": "alt + 2400",
            "charcode": "2400",
            "char": "ॠ"
        },
        {
            "altcode": "alt + 2401",
            "charcode": "2401",
            "char": "ॡ"
        },
        {
            "altcode": "alt + 2418",
            "charcode": "2418",
            "char": "ॲ"
        },
        {
            "altcode": "alt + 2419",
            "charcode": "2419",
            "char": "ॳ"
        },
        {
            "altcode": "alt + 2420",
            "charcode": "2420",
            "char": "ॴ"
        },
        {
            "altcode": "alt + 2421",
            "charcode": "2421",
            "char": "ॵ"
        },
        {
            "altcode": "alt + 2422",
            "charcode": "2422",
            "char": "ॶ"
        },
        {
            "altcode": "alt + 2423",
            "charcode": "2423",
            "char": "ॷ"
        },
        {
            "altcode": "alt + 2325",
            "charcode": "2325",
            "char": "क"
        },
        {
            "altcode": "alt + 2326",
            "charcode": "2326",
            "char": "ख"
        },
        {
            "altcode": "alt + 2327",
            "charcode": "2327",
            "char": "ग"
        },
        {
            "altcode": "alt + 2328",
            "charcode": "2328",
            "char": "घ"
        },
        {
            "altcode": "alt + 2329",
            "charcode": "2329",
            "char": "ङ"
        },
        {
            "altcode": "alt + 2330",
            "charcode": "2330",
            "char": "च"
        },
        {
            "altcode": "alt + 2331",
            "charcode": "2331",
            "char": "छ"
        },
        {
            "altcode": "alt + 2332",
            "charcode": "2332",
            "char": "ज"
        },
        {
            "altcode": "alt + 2333",
            "charcode": "2333",
            "char": "झ"
        },
        {
            "altcode": "alt + 2334",
            "charcode": "2334",
            "char": "ञ"
        },
        {
            "altcode": "alt + 2335",
            "charcode": "2335",
            "char": "ट"
        },
        {
            "altcode": "alt + 2336",
            "charcode": "2336",
            "char": "ठ"
        },
        {
            "altcode": "alt + 2337",
            "charcode": "2337",
            "char": "ड"
        },
        {
            "altcode": "alt + 2338",
            "charcode": "2338",
            "char": "ढ"
        },
        {
            "altcode": "alt + 2339",
            "charcode": "2339",
            "char": "ण"
        },
        {
            "altcode": "alt + 2340",
            "charcode": "2340",
            "char": "त"
        },
        {
            "altcode": "alt + 2341",
            "charcode": "2341",
            "char": "थ"
        },
        {
            "altcode": "alt + 2342",
            "charcode": "2342",
            "char": "द"
        },
        {
            "altcode": "alt + 2343",
            "charcode": "2343",
            "char": "ध"
        },
        {
            "altcode": "alt + 2344",
            "charcode": "2344",
            "char": "न"
        },
        {
            "altcode": "alt + 2345",
            "charcode": "2345",
            "char": "ऩ"
        },
        {
            "altcode": "alt + 2346",
            "charcode": "2346",
            "char": "प"
        },
        {
            "altcode": "alt + 2347",
            "charcode": "2347",
            "char": "फ"
        },
        {
            "altcode": "alt + 2348",
            "charcode": "2348",
            "char": "ब"
        },
        {
            "altcode": "alt + 2349",
            "charcode": "2349",
            "char": "भ"
        },
        {
            "altcode": "alt + 2350",
            "charcode": "2350",
            "char": "म"
        },
        {
            "altcode": "alt + 2351",
            "charcode": "2351",
            "char": "य"
        },
        {
            "altcode": "alt + 2352",
            "charcode": "2352",
            "char": "र"
        },
        {
            "altcode": "alt + 2353",
            "charcode": "2353",
            "char": "ऱ"
        },
        {
            "altcode": "alt + 2354",
            "charcode": "2354",
            "char": "ल"
        },
        {
            "altcode": "alt + 2355",
            "charcode": "2355",
            "char": "ळ"
        },
        {
            "altcode": "alt + 2356",
            "charcode": "2356",
            "char": "ऴ"
        },
        {
            "altcode": "alt + 2357",
            "charcode": "2357",
            "char": "व"
        },
        {
            "altcode": "alt + 2358",
            "charcode": "2358",
            "char": "श"
        },
        {
            "altcode": "alt + 2359",
            "charcode": "2359",
            "char": "ष"
        },
        {
            "altcode": "alt + 2360",
            "charcode": "2360",
            "char": "स"
        },
        {
            "altcode": "alt + 2361",
            "charcode": "2361",
            "char": "ह"
        },
        {
            "altcode": "alt + 2362",
            "charcode": "2362",
            "char": "ऺ"
        },
        {
            "altcode": "alt + 2363",
            "charcode": "2363",
            "char": "ऻ"
        },
        {
            "altcode": "alt + 2364",
            "charcode": "2364",
            "char": "़"
        },
        {
            "altcode": "alt + 2365",
            "charcode": "2365",
            "char": "ऽ"
        },
        {
            "altcode": "alt + 2366",
            "charcode": "2366",
            "char": "ा"
        },
        {
            "altcode": "alt + 2367",
            "charcode": "2367",
            "char": "ि"
        },
        {
            "altcode": "alt + 2368",
            "charcode": "2368",
            "char": "ी"
        },
        {
            "altcode": "alt + 2369",
            "charcode": "2369",
            "char": "ु"
        },
        {
            "altcode": "alt + 2370",
            "charcode": "2370",
            "char": "ू"
        },
        {
            "altcode": "alt + 2371",
            "charcode": "2371",
            "char": "ृ"
        },
        {
            "altcode": "alt + 2372",
            "charcode": "2372",
            "char": "ॄ"
        },
        {
            "altcode": "alt + 2373",
            "charcode": "2373",
            "char": "ॅ"
        },
        {
            "altcode": "alt + 2374",
            "charcode": "2374",
            "char": "ॆ"
        },
        {
            "altcode": "alt + 2375",
            "charcode": "2375",
            "char": "े"
        },
        {
            "altcode": "alt + 2376",
            "charcode": "2376",
            "char": "ै"
        },
        {
            "altcode": "alt + 2377",
            "charcode": "2377",
            "char": "ॉ"
        },
        {
            "altcode": "alt + 2378",
            "charcode": "2378",
            "char": "ॊ"
        },
        {
            "altcode": "alt + 2379",
            "charcode": "2379",
            "char": "ो"
        },
        {
            "altcode": "alt + 2380",
            "charcode": "2380",
            "char": "ौ"
        },
        {
            "altcode": "alt + 2381",
            "charcode": "2381",
            "char": "्"
        },
        {
            "altcode": "alt + 2382",
            "charcode": "2382",
            "char": "ॎ"
        },
        {
            "altcode": "alt + 2383",
            "charcode": "2383",
            "char": "ॏ"
        },
        {
            "altcode": "alt + 2389",
            "charcode": "2389",
            "char": "ॕ"
        },
        {
            "altcode": "alt + 2390",
            "charcode": "2390",
            "char": "ॖ"
        },
        {
            "altcode": "alt + 2391",
            "charcode": "2391",
            "char": "ॗ"
        },
        {
            "altcode": "alt + 2402",
            "charcode": "2402",
            "char": "ॢ"
        },
        {
            "altcode": "alt + 2403",
            "charcode": "2403",
            "char": "ॣ"
        },
        {
            "altcode": "alt + 2385",
            "charcode": "2385",
            "char": "॑"
        },
        {
            "altcode": "alt + 2386",
            "charcode": "2386",
            "char": "॒"
        },
        {
            "altcode": "alt + 2387",
            "charcode": "2387",
            "char": "॓"
        },
        {
            "altcode": "alt + 2388",
            "charcode": "2388",
            "char": "॔"
        },
        {
            "altcode": "alt + 2392",
            "charcode": "2392",
            "char": "क़"
        },
        {
            "altcode": "alt + 2393",
            "charcode": "2393",
            "char": "ख़"
        },
        {
            "altcode": "alt + 2394",
            "charcode": "2394",
            "char": "ग़"
        },
        {
            "altcode": "alt + 2395",
            "charcode": "2395",
            "char": "ज़"
        },
        {
            "altcode": "alt + 2396",
            "charcode": "2396",
            "char": "ड़"
        },
        {
            "altcode": "alt + 2397",
            "charcode": "2397",
            "char": "ढ़"
        },
        {
            "altcode": "alt + 2398",
            "charcode": "2398",
            "char": "फ़"
        },
        {
            "altcode": "alt + 2399",
            "charcode": "2399",
            "char": "य़"
        },
        {
            "altcode": "alt + 2424",
            "charcode": "2424",
            "char": "ॸ"
        },
        {
            "altcode": "alt + 2425",
            "charcode": "2425",
            "char": "ॹ"
        },
        {
            "altcode": "alt + 2426",
            "charcode": "2426",
            "char": "ॺ"
        },
        {
            "altcode": "alt + 2404",
            "charcode": "2404",
            "char": "।"
        },
        {
            "altcode": "alt + 2405",
            "charcode": "2405",
            "char": "॥"
        },
        {
            "altcode": "alt + 2406",
            "charcode": "2406",
            "char": "०"
        },
        {
            "altcode": "alt + 2407",
            "charcode": "2407",
            "char": "१"
        },
        {
            "altcode": "alt + 2408",
            "charcode": "2408",
            "char": "२"
        },
        {
            "altcode": "alt + 2409",
            "charcode": "2409",
            "char": "३"
        },
        {
            "altcode": "alt + 2410",
            "charcode": "2410",
            "char": "४"
        },
        {
            "altcode": "alt + 2411",
            "charcode": "2411",
            "char": "५"
        },
        {
            "altcode": "alt + 2412",
            "charcode": "2412",
            "char": "६"
        },
        {
            "altcode": "alt + 2413",
            "charcode": "2413",
            "char": "७"
        },
        {
            "altcode": "alt + 2414",
            "charcode": "2414",
            "char": "८"
        },
        {
            "altcode": "alt + 2415",
            "charcode": "2415",
            "char": "९"
        },
        {
            "altcode": "alt + 2416",
            "charcode": "2416",
            "char": "॰"
        },
        {
            "altcode": "alt + 2417",
            "charcode": "2417",
            "char": "ॱ"
        }
        
        
    ]
}