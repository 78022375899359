<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="header-spacer" style="height: 60.1094px;"></div>
<main role="main">
    <section class="videos" id="featured-videos">
        <div class="video-grid front-page" id="front-page-videos">
            <h3>Hindi</h3>
            <hr>
            <ul class="video-list featured">
                <li class="video featured" *ngFor="let video of alllistofvideos | filter : 'category' : 'Hindi'">
                    <a data-fancybox [routerLink]="['/viewvideo',video.id | encdec : 'enc']" class="featured-video">
                        <figure style="background-image: url({{video.thumbnailpath}});">
                            <img
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/50598/video-thumb-placeholder-16-9.png" />
                            <figcaption>{{video.name}}</figcaption>
                        </figure>
                    </a>
                </li>
            </ul>

            <h3>English</h3>
            <hr>
            <ul class="video-list featured">
                <li class="video featured" *ngFor="let video of alllistofvideos | filter : 'category' : 'English'">
                    <a data-fancybox [routerLink]="['/viewvideo',video.id | encdec : 'enc']" class="featured-video">
                        <figure style="background-image: url({{video.thumbnailpath}});">
                            <img
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/50598/video-thumb-placeholder-16-9.png" />
                            <figcaption>{{video.name}}</figcaption>
                        </figure>
                    </a>
                </li>
            </ul>

            <h3>Others</h3>
            <hr>
            <ul class="video-list featured">
                <li class="video featured" *ngFor="let video of alllistofvideos | filter : 'category' : 'Others'">
                    <a data-fancybox [routerLink]="['/viewvideo',video.id | encdec : 'enc']" class="featured-video">
                        <figure style="background-image: url({{video.thumbnailpath}});">
                            <img
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/50598/video-thumb-placeholder-16-9.png" />
                            <figcaption>{{video.name}}</figcaption>
                        </figure>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</main>