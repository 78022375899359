<div *ngIf="canshow">
    <section class="pb-4" *ngIf="!testSubmit" id="container" style="background: #f2f2f2;">
        <div class="row">

            <div class="col-md-11" style="padding: 0;" [style.height.px]="innerHeight">
                <div class="row m-2" style="height:47%">
                    <div class="col-md-12" id="editor" style="border-style: groove;
                overflow-y: scroll;
                height: 100%;
                text-align: justify; font-family: Calibri;" [style.fontSize]="fontsize">
                        <span *ngFor="let word of paragrapharray;trackBy: trackByIndex; index as index;"
                            [ngClass]="(cindex===index && checkType!='No Highlight')?'blink':''" class="cspan"
                            id="key_{{index}} " [innerHTML]="word+'&nbsp'" style="float: left;"></span>
                    </div>
                </div>

                <div class="div m-2" style="border-style: groove; height:50%">
                    <textarea id="textInput" data-gramm_editor="false" spellcheck="false"
                        (mousedown)="inputClicked($event)" [(ngModel)]="userInput" (keyup)="fnuserInput1($event)"
                        (keydown)="fnuserInput($event)" style="border-style: groove;
                word-wrap: break-word;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
                overflow-y: scroll;
                width: 100%;
                        height: 100%; font-family: Calibri;" [style.fontSize]="fontsize"></textarea>

                </div>
                <audio id="audio" controls style="display:none">
                    <source src="/assets/key.mp3" type="audio/mpeg"> Your browser does not support the audio element.
                </audio>
            </div>
            <div class="col-md-1 mt-2">
                <div class="bg-theme-secondary side-panel" style="padding: 5px;">
                    <div class="row">
                        <div class="col align-self-center">
                            <div class="form-row" style="flex-direction: column; font-size: 11px;">
                                <div class="col align-self-center">
                                    <div class="btn-group">
                                        <button *ngIf="!exammode" type="button" (click)="exammode=true;openFullscreen()"
                                            class="border bg-transparent color-white px-1 pr-2 py-2"
                                            style="border-radius:5px ;" aria-haspopup="true" aria-expanded="false">
                                            Enter Exam Mode1
                                        </button>
                                        <button *ngIf="exammode" type="button"
                                            (click)="exammode=false;closeFullscreen()" style="border-radius:5px ;"
                                            class="border bg-transparent color-white px-1 pr-2 py-2"
                                            aria-haspopup="true" aria-expanded="false">
                                            Exit Exam Mode
                                        </button>
                                    </div>

                                </div>
                                <div class="col align-self-center mt-2">
                                    <div class="border bg-transparent color-white px-1 pr-2 py-2 text-center"
                                        style="cursor: pointer; border-radius:5px ;" *ngIf="!sound"
                                        (click)="sound=true">
                                        <i class="fas fa-volume-mute"></i><br>Key Sound
                                    </div>
                                    <div class="border bg-transparent color-white px-1 pr-2 py-2 text-center"
                                        *ngIf="sound" style="cursor: pointer; border-radius:5px ;" (click)="sound=true">
                                        <i class="fas fa-volume-up"></i><br>Key Sound
                                    </div>
                                </div>
                                <div class="col align-self-center mt-2">
                                    <div class="border bg-transparent color-white px-1 pr-2 py-2 text-center"
                                        *ngIf="teststatus===0" style="cursor: pointer; border-radius:5px;"
                                        (click)="startTest()">
                                        <i class="fas fa-play-circle"></i>
                                        Start
                                    </div>
                                    <div class="border bg-transparent color-white px-1 pr-2 py-2 text-center"
                                        style="cursor: pointer; border-radius:5px;" *ngIf="teststatus===1"
                                        (click)="stopTest()">
                                        <i class="fas fa-pause-circle"></i>
                                        Pause
                                    </div>
                                    <div class="border bg-transparent color-white px-1 pr-2 py-2 text-center"
                                        style="cursor: pointer; border-radius:5px;" *ngIf="teststatus===2"
                                        (click)="startTest()">
                                        <i class="fas fa-play-circle"></i>
                                        Resume
                                    </div>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="teststatus===1">
                                    <div class="border bg-transparent color-white px-1 pr-2 py-2 text-center"
                                        style="border-radius:5px;">
                                        <i class="fas fa-clock"></i>
                                        <div class="bg-theme-primary d-inline-block ml-1 px-1 py-1 rounded"> {{h}}
                                            : {{m}}
                                            :
                                            {{s}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="islivetest===0 && teststarted===0">
                                    <select class="form-control form-control-sm bg-gray-light" (change)="timechanged()"
                                        [(ngModel)]="usertime">
                                        <option [ngValue]="1">1 Minute</option>
                                        <option [ngValue]="2">2 Minute</option>
                                        <option [ngValue]="3">3 Minute</option>
                                        <option [ngValue]="4">4 Minute</option>
                                        <option [ngValue]="5">5 Minute</option>
                                        <option [ngValue]="6">6 Minute</option>
                                        <option [ngValue]="7">7 Minute</option>
                                        <option [ngValue]="8">8 Minute</option>
                                        <option [ngValue]="9">9 Minute</option>
                                        <option [ngValue]="10">10 Minute</option>
                                        <option [ngValue]="11">11 Minute</option>
                                        <option [ngValue]="12">12 Minute</option>
                                        <option [ngValue]="13">13 Minute</option>
                                        <option [ngValue]="14">14 Minute</option>
                                        <option [ngValue]="15">15 Minute</option>
                                        <option [ngValue]="16">16 Minute</option>
                                        <option [ngValue]="17">17 Minute</option>
                                        <option [ngValue]="18">18 Minute</option>
                                        <option [ngValue]="19">19 Minute</option>
                                        <option [ngValue]="20">20 Minute</option>
                                        <option [ngValue]="21">21 Minute</option>
                                        <option [ngValue]="22">22 Minute</option>
                                        <option [ngValue]="23">23 Minute</option>
                                        <option [ngValue]="24">24 Minute</option>
                                        <option [ngValue]="25">25 Minute</option>
                                        <option [ngValue]="26">26 Minute</option>
                                        <option [ngValue]="27">27 Minute</option>
                                        <option [ngValue]="28">28 Minute</option>
                                        <option [ngValue]="29">29 Minute</option>
                                        <option [ngValue]="30">30 Minute</option>
                                    </select>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="islivetest===0 && teststarted===0">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="backallow">
                                        <option>One Backspace</option>
                                        <option>Full Backspace</option>
                                        <option>Disable Backspace</option>
                                    </select>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="islivetest===1">
                                    <div class="border bg-transparent color-white px-3 pr-2 py-2"
                                        style="border-radius:5px;">
                                        {{backallow}}
                                    </div>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="islivetest===1">
                                    <div class="border bg-transparent color-white px-3 pr-2 py-2"
                                        style="border-radius:5px;">
                                        {{checkType}}
                                    </div>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="islivetest===0 && teststarted===0">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="checkType">
                                        <option>Word Highlight</option>
                                        <option>No Highlight</option>
                                    </select>
                                </div>
                                <div class="col align-self-center mt-2">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="fontsize">
                                        <option *ngFor='let in of counter_font(20) ;let i = index'>{{i+11}}px</option>
                                    </select>
                                </div>

                                <div class="col mt-2" *ngIf="teststarted===1">
                                    <div class="btn-group">
                                        <!-- <button type="button" (click)="submitTest()"
                                            class="border bg-transparent color-white px-3 pr-2 py-2"
                                            style="border-radius:5px;" aria-haspopup="true" aria-expanded="false">
                                            Submit
                                        </button> -->

                                        <button type="button" (click)="submit(myParam)"
                                            class="border bg-transparent color-white px-3 pr-2 py-2"
                                            style="border-radius:5px;" aria-haspopup="true" aria-expanded="false">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-theme-secondary side-panel" style="background: #38566c;padding: 5px;">
                    <div class="row">
                        <div class="col align-self-center">
                            <div class="form-row" style="flex-direction: column;">
                                <div class="col align-self-center" *ngIf="testDetails.language==='Hindi'">
                                    <div data-toggle="modal" data-target="#myModal"
                                        class="border bg-transparent color-white px-3 pr-2 py-2 "
                                        style="cursor: pointer; border-radius:5px;">
                                        Show Alt Codes
                                    </div>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='Hindi'">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                                        <option> Alt Codes Allow</option>
                                        <option> Alt Codes Not Allow</option>
                                    </select>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='Hindi'">
                                    <select class="form-control form-control-sm bg-gray-light" (change)="setFont()"
                                        [(ngModel)]="font">
                                        <option>Kruti Dev</option>
                                        <option>DevLys</option>
                                        <option>Bold</option>
                                    </select>
                                </div>

                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalRem'">
                                    <div data-toggle="modal" data-target="#myModal"
                                        class="border bg-transparent color-white px-3 pr-2 py-2"
                                        style="cursor: pointer; border-radius:5px;">
                                        Show Alt Codes
                                    </div>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalRem'">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                                        <option> Alt Codes Allow</option>
                                        <option> Alt Codes Not Allow</option>
                                    </select>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalRem'">
                                    <select class="form-control form-control-sm bg-gray-light" (change)="setFont()"
                                        [(ngModel)]="font">
                                        <option>Kruti Dev</option>
                                        <option>DevLys</option>
                                        <option>Bold</option>
                                    </select>
                                </div>

                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalCBI'">
                                    <div data-toggle="modal" data-target="#myModal"
                                        class="border bg-transparent color-white px-3 pr-2 py-2"
                                        style="cursor: pointer; border-radius:5px;">
                                        Show Alt Codes
                                    </div>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalCBI'">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                                        <option> Alt Codes Allow</option>
                                        <option> Alt Codes Not Allow</option>
                                    </select>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalCBI'">
                                    <select class="form-control form-control-sm bg-gray-light" (change)="setFont()"
                                        [(ngModel)]="font">
                                        <option>Kruti Dev</option>
                                        <option>DevLys</option>
                                        <option>Bold</option>
                                    </select>
                                </div>

                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalIns'">
                                    <div data-toggle="modal" data-target="#myModal"
                                        class="border bg-transparent color-white px-3 pr-2 py-2"
                                        style="cursor: pointer; border-radius:5px;">
                                        Show Alt Codes
                                    </div>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalIns'">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="altcodes">
                                        <option> Alt Codes Allow</option>
                                        <option> Alt Codes Not Allow</option>
                                    </select>
                                </div>
                                <div class="col align-self-center" *ngIf="testDetails.language==='MangalIns'">
                                    <select class="form-control form-control-sm bg-gray-light" (change)="setFont()"
                                        [(ngModel)]="font">
                                        <option>Kruti Dev</option>
                                        <option>DevLys</option>
                                        <option>Bold</option>
                                    </select>
                                </div>

                                <div class="col align-self-center mt-2"
                                    *ngIf="teststarted===0 && checkType!='No Highlight'">
                                    <select class="form-control form-control-sm bg-gray-light" [(ngModel)]="scrollopt">
                                        <option>Auto Scroll</option>
                                        <option>Manual Scroll</option>
                                    </select>
                                </div>
                                <div class="col align-self-center mt-2" *ngIf="islivetest===0 && teststarted===0">
                                    <select class="form-control form-control-sm bg-gray-light" (change)="getSize()"
                                        [(ngModel)]="psize">
                                        <option value="{{d}}" *ngFor="let d of parrange">{{d}} Words</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="pb-4" *ngIf="testSubmit">
        <div class="bg-theme-secondary text-center rounded p-3" style="padding: 5px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col align-self-center text-left" *ngIf="testDetails.islivetest==1">
                        <a class="btn btn-secondary" [routerLink]="['/live-test']"><i class="fas fa-arrow-left"></i>
                            Back
                            to list</a>
                    </div>
                    <div class="col align-self-center text-left" *ngIf="testDetails.islivetest==0">
                        <a class="btn btn-secondary" [routerLink]="['/practice-test']"><i class="fas fa-arrow-left"></i>
                            Back
                            to list</a>
                    </div>
                    <div class="col  align-self-center text-white">
                        Test Results
                    </div>
                    <div class="col  align-self-center text-right text-white">
                        <img style="background: white;width: 150px;border-radius: 5px;padding: 2px 7px;"
                            src="/assets/images/logo_website.jpg" alt="Tez Typing">
                    </div>
                </div>
            </div>
        </div><br>


        <div class="container-fluid">
            <div class="warpper">
                <input class="radio" id="one" (click)="getResultByExam(testDetails.passingmarks)" name="group"
                    type="radio" checked>
                <input class="radio" id="two" (click)="getResultByExam(testDetails.hcldc)" name="group" type="radio">
                <input class="radio" id="three" (click)="getResultByExam(testDetails.hcs)" name="group" type="radio">
                <input class="radio" id="four" (click)="getResultByExam(testDetails.rldc)" name="group" type="radio">
                <input class="radio" id="five" (click)="getResultByExam(testDetails.rrb)" name="group" type="radio">
                <input class="radio" id="six" (click)="getResultByExam(testDetails.scgl)" name="group" type="radio">
                <input class="radio" id="seven" (click)="getResultByExam(testDetails.schsl)" name="group" type="radio">
                <div class="tabs">
                    <!--<label class="tab" id="one-tab" for="one">RBB NTPC</label>
                     <label class="tab" id="one-tab" for="one">All Type exam</label>
                     <label class="tab" id="two-tab" for="two">High Court LDC</label>
                    <label class="tab" id="three-tab" for="three">High Court Steno</label>
                    <label class="tab" id="four-tab" for="four">Rssb LDC</label>
                    <label class="tab" id="five-tab" for="five">RBB NTPC</label>
                    <label class="tab" id="six-tab" for="six">SSC CGL</label>
                    <label class="tab" id="seven-tab" for="seven">SSC CHSL</label> -->
                </div>
            </div>
            <div style="background-color: #38566c;font-size: 15px;padding: 20px 20px;color: #fff;border-radius: 5px;">
                {{ myParam == 'chsl' ? 'SSC-CHSL' : '' }}
                {{ myParam == 'ldc' ? 'High Court LDC ' : '' }}
                {{ myParam == 'cgl' ? 'SSC-CGL' : '' }}
                {{ myParam == 'steno' ? 'High Court Steno' : '' }}
                {{ myParam == 'rssbldc' ? 'RSSB LDC' : '' }}
            </div>
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; "
                        class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Final test results
                </h6>
                <div class="px-5 py-3">
                    <div class="row typing-result">
                        <div class="col-md-12 text-center p-3" style="font-size: 20px;"
                            [ngClass]=" (this.testResult == 'qualified')?'text-success':'text-danger'">
                            <i
                                [ngClass]=" (this.testResult == 'qualified')?'fas fa-check-circle':'fas fa-xmark-circle'"></i>
                            <strong>{{ (this.testResult == 'qualified' )?' Congratulation!! You are Qualified ':'
                                Sorry!!
                                You are Not Qualified ' }}

                            </strong>
                            <!-- <div style="font-size:16px ;">{{ (reason === ' less_word')?'( Your typed words are
                                    less than 300 as per NTPC exam rules so you are disqualify )':''}}</div> -->
                        </div>


                        <div class="col-md-4 text-center">
                            <div class="logo" style="height:50px !important;width: 200px !important;">
                                <a [routerLink]="['/']" class="exam-head">
                                    <img class="img-fluid" src="/assets/images/logo_website.jpg"
                                        alt="Tez Typing online">
                                    <span [style.display]="myParam=='chsl' ? 'block' : 'none' ">SSC &nbsp; CHSL</span>
                                    <span [style.display]="myParam=='ldc' ? 'block' : 'none' ">High Court &nbsp;
                                        LDC</span>
                                    <span [style.display]="myParam=='cgl' ? 'block' : 'none' ">SSC &nbsp; CGL</span>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4 text-center short-box"><strong>Total time for the test:</strong>
                            {{usertime}} Minute : {{0}} Seconds
                        </div>

                        <div class="col-md-2 text-center p-3" style="font-size: 20px;"></div>

                        <div [style.display]="myParam=='ldc' || myParam=='rssbldc'  || myParam=='steno'? 'block' : 'none' "
                            class="col-md-4 text-left p-3">

                            <strong>Maximum Marks:</strong><br>
                            <strong>Minimum Passing Marks:</strong> <br>
                            <strong>Marks Obtained:</strong>

                        </div>
                        <div [style.display]="myParam=='ldc' || myParam=='rssbldc' || myParam=='steno' ? 'block' : 'none' "
                            class="col-md-6 text-left p-3">

                            25<br>
                            10<br>
                            {{Obtained .toFixed(2)}}

                        </div>
                        <div [style.display]="myParam=='chsl' ? 'block' : 'none' " class="col-md-12 text-left p-3"
                            style="font-size: 11px;">
                            <strong>Note:</strong><br>
                            (1) Error limit- 5-7 %<br>
                            (2) Error % = number of error/key depression × 100<br>
                            (3) 10 min.(35wpm or 1750 strokes or key depression) 10500 keys per hour
                        </div>
                        <div [style.display]="myParam=='ldc' ? 'block' : 'none' " class="col-md-12 text-left p-3"
                            style="font-size: 11px;">
                            <strong>Note:</strong><br>
                            (1) Result of this method may differ from other methods as the result depends on the
                            calcualtion method used. There are different result calcualtion methods for different
                            exams<br>
                            (2) Result is calculated according the forumula : (20/8000) x Net Speed (in depressions per
                            hour)<br>
                            (3) This formula is used for marks calcualtion in Raj.High Court Exam. For confirmation
                            please read the notification of Raj. High Court Examination.
                        </div>
                        <div [style.display]="myParam=='rssbldc' ? 'block' : 'none' " class="col-md-12 text-left p-3"
                            style="font-size: 11px;">
                            <strong>Note:</strong><br>
                            (1) For English typing 0.05 marks for each correct word and<br>
                            (2) For Hindi typing 0.0625 marks for each correct word.<br>
                            (3) Your duration. if you have selected some other duration of 10 minutes. So always select
                            10 minutes dureation because marks are as per 10 minutes dureation. if you have selected
                            some other dureation instend of 10 minutes then this result may not be valid.<br>
                            (4) Use 500 words limit for English test and 400 words limit for Hindi Test.
                        </div>
                        <div [style.display]="myParam=='steno' ? 'block' : 'none' " class="col-md-12 text-left p-3"
                            style="font-size: 11px;">
                            <strong>Note:</strong><br>
                            (1) Result of this method may differ from other methods as the result depends on the
                            calcualtion method used. There are different result calcualtion methods for different
                            exams.<br>
                            (2) Result is calculated according the forumula : (20/8000) x Net Speed (in depressions per
                            hour)<br>
                            (3) this formula is used for marks calculation in Raj. High Court Exam. For Confimation
                            please read the notification of Raj. High Court Examination<br>

                        </div>
                        <div [style.display]="myParam=='ntpc' ? 'block' : 'none' " class="col-md-12 text-left p-3"
                            style="font-size: 11px;">
                            <strong>Note:</strong><br>
                            (1) Result of this method may differ from other methods as the result depends on the
                            calcualtion method used. There are different result calculation methods for different
                            exams<br>
                            (2) 5% Mistakes are ignorable. If you make upto 5% mistakes your gross speed and net speed
                            will be same<br>
                            (3) As per RB NTPC rules you need minimum 30 WPM speed in English and 25 WPM speed in Hindi
                            to qualify the typing skill test.<br>

                        </div>


                        <div class="col-md-12 mistake-box border border-dark  mt-3">
                            <div class="row">
                                <div class="col-md-12 text-center mb-2">
                                    <strong><u>Detailed Result as Below</u> </strong>
                                </div>
                                <div class="col-md-12 text-center mb-2"
                                    [style.display]="myParam =='cpct' ? 'block' : 'none' ">
                                    <strong><u>Result :</u> {{Obtained}}<span> % </span> </strong>
                                </div>

                                <div class="col-md-3 " [style.display]="myParam !='cpct' ? 'block' : 'none' ">

                                    <strong>Test Duration :<br></strong>
                                    <strong>Correct Words Typed :<br></strong>
                                    <strong>Omitted(Skipped) Words :<br></strong>
                                </div>

                                <div class="col-md-3" [style.display]="myParam !='cpct' ? 'block' : 'none' ">

                                    {{rstime}}<br>
                                    {{keystrokes-conterror}}<br>
                                    {{fullomission}}<br>

                                </div>
                                <div class="col-md-3" [style.display]="myParam !='cpct' ? 'block' : 'none' ">
                                    <strong>Total Words Typed :<br></strong>
                                    <strong>Incorrect :<br></strong>
                                    <strong>Effective Correct Words :<br></strong>

                                </div>
                                <div class="col-md-3" [style.display]="myParam !='cpct' ? 'block' : 'none' ">
                                    {{keystrokes}}<br>
                                    {{conterror-fullomission}} <span class="result_caption"> (Omitted words not
                                        included )</span><br>
                                    {{keystrokes-conterror}}<span class="result_caption">(Correct Words
                                        -
                                        Omitted Word )</span><br>

                                </div>


                                <!-- cpct start -->
                                <div class="col-md-3" [style.display]="myParam =='cpct' ? 'block' : 'none' ">
                                    <strong>Test Duration :<br></strong>
                                    <strong>Correct Words Typed :<br></strong>

                                </div>
                                <div class="col-md-3" [style.display]="myParam =='cpct' ? 'block' : 'none' ">
                                    {{rstime}}<br>
                                    {{keystrokes-conterror}}<br>


                                </div>
                                <div class="col-md-3" [style.display]="myParam =='cpct' ? 'block' : 'none' ">
                                    <strong>Total Words Typed :<br></strong>
                                    <strong>Incorrect :<br></strong>


                                </div>
                                <div class="col-md-3" [style.display]="myParam =='cpct' ? 'block' : 'none' ">
                                    {{keystrokes}}<br>
                                    {{conterror-fullomission}} <br>

                                </div>
                                <!-- cpct end -->


                                <div class="col-md-12 mt-4 mb-3">
                                    <strong>Standard Method: </strong>(one word = 5 character or keystrock)
                                </div>

                                <div class="col-md-4" [style.display]="myParam !='ntpc'  ? 'block' : 'none' "
                                    [style.display]=" myParam !='cpct' ? 'block' : 'none' "
                                    [style.display]=" myParam !='beltron' ? 'block' : 'none' ">
                                    <strong>Net Speed </strong><br>
                                    <strong> </strong><br>
                                    <strong>Gross Speed </strong><br>
                                    <strong> </strong><br>
                                    <strong>Accuracy </strong><br>
                                    <strong> Backspace</strong><br>
                                    <strong>Errors</strong><br>
                                    <strong>Full Mistakes </strong><br>
                                    <strong>Half Mistakes </strong><br>



                                </div>
                                <div class="col-md-8" [style.display]="myParam !='ntpc'  ? 'block' : 'none' "
                                    [style.display]=" myParam !='cpct'  ? 'block' : 'none' "
                                    [style.display]=" myParam !='beltron' ? 'block' : 'none' ">
                                    : {{netwpm}} words per minute <br>
                                    : {{netwpm*5}} key strokes per minute<span
                                        class="result_caption">(<i>{{netwpm*5*60}}
                                            per hour</i>)</span><br>
                                    : {{grosswpm}} words per minute<br>
                                    : {{grosswpm*5}} key strokes per minute<span class="result_caption">(<i>{{
                                            grosswpm*5*60 }} per hour</i>) </span><br>
                                    : {{rsaccuracy.toFixed(2)}} %<br>
                                    : {{backspacecount}} time<br>
                                    : {{rserrors.toFixed(2)}} Errors<br>
                                    : {{fullmistake}} <span class="result_caption">(Omission : {{fullomission}},
                                        Addition : {{fulladdition}}, Repeatation : {{fullrepeatation}}, Subtstitation :
                                        {{fullsubtstitation }}
                                        )</span> <br>
                                    : {{halfmistake}} <span class="result_caption">(Omission : {{halfomission}},
                                        Repeatation : {{halfrepeatation}}, Addition : {{halfaddition}},
                                        Subtstitation : {{halfsubtstitation }}, Spacing : {{halfspacing}}
                                        Capitalization
                                        : {{halfcapitalization}}, Punctuation : {{halfpunctuation }}
                                        )</span><br>

                                </div>
                                <!-- cpct start -->
                                <div class="col-md-4" [style.display]="myParam =='cpct' ? 'block' : 'none' "
                                [style.display]=" myParam =='beltron' ? 'block' : 'none' ">
                                    <strong>Net Speed </strong><br>
                                    <strong> </strong><br>
                                    <strong>Gross Speed </strong><br>
                                    <strong> </strong><br>
                                    <strong>Accuracy </strong><br>
                                    <strong> Backspace</strong><br>
                                    <strong>Errors</strong><br>




                                </div>
                                <div class="col-md-8" [style.display]="myParam =='cpct' ? 'block' : 'none' "
                                [style.display]=" myParam =='beltron' ? 'block' : 'none' ">
                                    : {{netwpm}} words per minute <br>
                                    : {{netwpm*5}} key strokes per minute<span
                                        class="result_caption">(<i>{{netwpm*5*60}}
                                            per hour</i>)</span><br>
                                    : {{grosswpm}} words per minute<br>
                                    : {{grosswpm*5}} key strokes per minute<span class="result_caption">(<i>{{
                                            grosswpm*5*60 }} per hour</i>) </span><br>
                                    : {{rsaccuracy.toFixed(2)}} %<br>
                                    : {{backspacecount}} time<br>
                                    : {{rserrors.toFixed(2)}} Errors<br>


                                </div>
                                <!-- cpct end -->
                                <!-- ntpc start -->
                                <div class="col-md-4" [style.display]="myParam =='ntpc' ? 'block' : 'none' ">
                                    <strong>Net Speed </strong><br>
                                    <strong> </strong><br>
                                    <strong>Gross Speed </strong><br>
                                    <strong> </strong><br>
                                    <strong>Accuracy </strong><br>
                                    <strong> Backspace</strong><br>
                                    <strong>Errors</strong><br>
                                    <strong>Full Mistakes </strong><br>
                                    <strong>Half Mistakes </strong><br>
                                    <strong> </strong><br>
                                    <strong>Total Mistakes </strong><br>
                                    <strong>Ignorable Mistakes </strong><br>
                                    <strong>Net Mistakes for evaluation</strong><br>
                                    <strong>Keystrokes pressed: </strong><br>
                                    <strong>Gross Word Count: </strong><br>
                                    <strong>Net Word Count for evaluation : </strong><br>


                                </div>
                                <div class="col-md-8" [style.display]="myParam =='ntpc' ? 'block' : 'none' ">
                                    : {{netwpm}} words per minute <br>
                                    : {{netwpm*5}} key strokes per minute<span
                                        class="result_caption">(<i>{{netwpm*5*60}}
                                            per hour</i>)</span><br>
                                    : {{grosswpm}} words per minute<br>
                                    : {{grosswpm*5}} key strokes per minute<span class="result_caption">(<i>{{
                                            grosswpm*5*60 }} per hour</i>) </span><br>
                                    : {{rsaccuracy.toFixed(2)}} %<br>
                                    : {{backspacecount}} time<br>
                                    : {{rserrors.toFixed(2)}} Errors<br>
                                    : {{fullmistake}} <span class="result_caption">(Omission : {{fullomission}},
                                        Addition : {{fulladdition}}, Repeatation : {{fullrepeatation}}, Subtstitation :
                                        {{fullsubtstitation }}
                                        )</span> <br>
                                    : {{halfmistake}} <span class="result_caption">(Omission : {{halfomission}},
                                        Repeatation : {{halfrepeatation}}, Addition : {{halfaddition}},
                                        Subtstitation : {{halfsubtstitation }}, Spacing : {{halfspacing}} ,
                                        Transposition :{{halftransposition}}, Capitalization : {{halfcapitalization}},
                                        Punctuation : {{halfpunctuation }} )</span><br>
                                    <strong> </strong><br> :{{conterror}}<span class="result_cation">(Full Mistakes +
                                        (Half Mistakes / 2))</span><br>
                                    :{{ignorablemistake}}<span class="result_cation">(5% of total words
                                        typed)</span><br>
                                    :{{netmistakes}}<span class="result_cation">(Total Mistakes - Ignorable
                                        Mistakes)</span><br>
                                    :{{keystrokes*5}}<span class="result_cation">(Total keystrokes
                                        pressed)</span><br>
                                    :{{keystrokes}}<span class="result_cation">(Total keystrokes pressed / 5)</span><br>
                                    :{{net_wordcount_for_evaluation}}<span class="result_cation">(Gross word count -
                                        (Net mistakes for evaluation × 10) / time of test)</span><br>

                                </div>
                                <!-- ntpc end -->
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <!-- cpct -->

            <div class="border border-radius-10 bg-white mt-4" [style.display]="myParam =='cpct' ? 'block' : 'none' ">
                <div class="px-5 py-3">
                    <div class="row typing-result">
                        <div class="col-md-12 mistake-box border border-dark  mt-3">
                            <div class="row">
                                <div class="col-md-12 text-center mb-2">
                                    <strong><u>CPCT Score Calcualtion Rules </u> </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-left p-3" style="font-size: 11px;">
                                    1. The minimum passing score for CPCT is 50%. In other words you need 30 NWPM speed
                                    in English and
                                    20 NWPM speed in Hindi to qualify the test.
                                    <br>

                                    2. The typing speed of the candidate will be assessed in Net Words per Minute (NWPM)
                                    which is scaled to an equivalent score percentage for the certification purpose (the
                                    scaled chart will be provided with score card).<br>

                                </div>
                                <div class="col-md-6 text-center p-3" style="font-size: 11px;">
                                    <table class="mb-0 table table-details" style="border: black solid 1px;">
                                        <thead>
                                            <tr>
                                                <th colspan="2" style="border: black solid 1px;">English</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th style="border: black solid 1px;">Typing Speed</th>
                                                <th style="border: black solid 1px;">Scaled Score</th>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">30 - 40 NWPM</td>
                                                <td style="border: black solid 1px;">50.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">41 - 50 NWPM</td>
                                                <td style="border: black solid 1px;">60.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">51 -60 NWPM</td>
                                                <td style="border: black solid 1px;">70.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">61 - 70 NWPM</td>
                                                <td style="border: black solid 1px;">80.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">71 - 80 NWPM</td>
                                                <td style="border: black solid 1px;">90.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;"> >= 81 NWPM</td>
                                                <td style="border: black solid 1px;">100.00 %</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6 text-center p-3" style="font-size: 11px;">
                                    <table class="mb-0 table table-details" style="border: black solid 1px;">
                                        <thead>
                                            <tr>
                                                <th colspan="2" style="border: black solid 1px;">Hindi - Unicode</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th style="border: black solid 1px;">Typing Speed</th>
                                                <th style="border: black solid 1px;">Scaled Score</th>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">20 - 25 NWPM</td>
                                                <td style="border: black solid 1px;">50.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">26 - 30 NWPM</td>
                                                <td style="border: black solid 1px;">60.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">31 -35 NWPM</td>
                                                <td style="border: black solid 1px;">70.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">36 - 40 NWPM</td>
                                                <td style="border: black solid 1px;">80.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;">41 - 50 NWPM</td>
                                                <td style="border: black solid 1px;">90.00 %</td>
                                            </tr>
                                            <tr>
                                                <td style="border: black solid 1px;"> >= 51 NWPM</td>
                                                <td style="border: black solid 1px;">100.00 %</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-12 text-left p-3" style="font-size: 11px;">
                                    3. Though it is mandatory for a candidate to attempt both Hindi and English typing
                                    test, the score card will record scores for Computer proficiency and for each typing
                                    test separately. The candidate will be declare as 'CPCT Qualified' for the skill
                                    where he/ she has secured 50 % marks/score and above
                                    <br> 4. The Candidates will be certified as 'Not Qualified in respective sections
                                    where the scores would be less than 50% marks/score
                                    <br>5. On the basis of CPCT Score Card, Candidate will be eligible to apply for
                                    Job/interview as per the Skill Suitability Matrix only if he/she is qualified in
                                    Computer proficiency and then qualified in typing skill(s) shall be considered as per
                                    job requirements.
                                    <br> 6. Based on the performance, the candidate will be certified as 'CPCT
                                    Qualified' as per the Skill Suitability Matrix

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- cpct -->
            <!-- <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; "
                        class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Original Paragraph
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th [innerHTML]="paragraph"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div> -->
            <div class="border border-radius-10 bg-white mt-4">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; "
                        class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span> Paragraph you typed
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th id="textInput" [innerHTML]="userInput"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <div class="border border-radius-10 bg-white mt-4" [style.display]="myParam !='cpct' ? 'block' : 'none' "
            [style.display]=" myParam !='beltron' ? 'block' : 'none' ">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; "
                        class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span>
                    Fullmistake in paragraph&nbsp;<span class="match">Correct</span>&nbsp;
                    <span class="fullomission">Omission </span> &nbsp;
                    <span class="fullrepaddsub">Substitution + Addition + Repetition</span>&nbsp;<br>
                    <span style=" height: 13px; width: 13px; "
                        class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span>
                    Halfmistake in paragraph&nbsp;
                    <span class="halfspacing">Spacing error</span> &nbsp;
                    <span class="halfpanc">Punctuation Errors</span> &nbsp;
                    <span class="halfcapti">Wrong capitalization</span> &nbsp;
                    <span class="halfspelling">Spelling error (Repetition + Addition + Omission + Substitution)</span>
                    &nbsp;
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th id="textInput1" [innerHTML]="fullparadiff"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <!-- cpct -->
            <div class="border border-radius-10 bg-white mt-4" [style.display]="myParam =='cpct' ? 'block' : 'none' "
            [style.display]=" myParam =='beltron' ? 'block' : 'none' ">
                <h6 class="border-bottom mb-0 px-5 py-3 color-primary">
                    <span style=" height: 13px; width: 13px; "
                        class="bg-theme-secondary d-inline-block mr-2 rounded-circle"></span>
                    <span class="match">Correct</span>&nbsp;
                    <span class="failed">Incorrect </span> &nbsp;
                    
                </h6>
                <div class="px-5 py-3">
                    <table class="mb-0 table table-details">
                        <thead>
                            <tr>
                                <th id="textInput1" [innerHTML]="fullparadiff"></th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>
            <!-- cpct -->

        </div>
    </section>


    <!-- Modal -->
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <!-- Modal content-->
            <div class="modal-content">

                <div class="modal-body" *ngIf="testDetails.language==='Hindi'">
                    <object width="100%" height="100%" data="/assets/hindi-typing-code-krutidev.pdf"></object>
                </div>
                <div class="modal-body" *ngIf="testDetails.language==='MangalRem'">
                    <object width="100%" height="100%" data="/assets/MangalRem-Alt-codes.pdf"></object>
                </div>
                <div class="modal-body" *ngIf="testDetails.language==='MangalCBI'">
                    <object width="100%" height="100%" data="/assets/MangalRem-Alt-codes.pdf"></object>
                </div>
                <div class="modal-body" *ngIf="testDetails.language==='MangalIns'">
                    <object width="100%" height="100%" data="/assets/Mangal-Inscript.pdf"></object>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</div>