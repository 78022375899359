import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
@Injectable({
  providedIn: 'root'
})
export class UploadvideosService {
  private httpclientbackend: HttpClient;
  prefix = environment._apiUrl;
  constructor(
    private httpclient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpclientbackend = new HttpClient(handler);
  }

  public savevideosettings(body): Observable<any> {
    return this.httpclient.post<any>(`${environment._apiUrl}api/admin/savevideosettings`, body, {
      headers
    });
  }
  public getvideosettings(pageindex, pagesize, searchtext): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/admin/getvideosettings?searchtext=${searchtext}&pageindex=${pageindex}&pagesize=${pagesize}`, {
      headers
    });
  }
  public getvideosettingById(id): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/admin/getvideosettingbyid?id=${id}`, {
      headers
    });
  }
  public deletevideosetting(id, userid): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/admin/deletevideosetting?id=${id}&userid=${userid}`, {
      headers
    });
  }
  public getwatchvideos(searchtext): Observable<any> {
    return this.httpclient.get<any>(`${environment._apiUrl}api/user/getvideosettings?searchtext=${searchtext}`, {
      headers
    });
  }
}
