import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ArticleService } from 'src/app/admin/services/article.service';
import { AuthdataService } from 'src/app/services/authdata.service';
import { AuthService } from 'src/app/services/auth.service';
import { EncDecService } from 'src/app/services/enc-dec.service';
import { LoaderService } from 'src/app/services/message/loader.service';
import { ToastService } from 'src/app/services/message/toast.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PackageService } from 'src/app/admin/services/package.service';
import AOS from 'aos';
declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  baseUrl = '';
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private loader: LoaderService,
    private articleservice: ArticleService,
    private packageservice: PackageService,
    private adataservice: AuthdataService,
    private AuthService: AuthService,
    private cd: ChangeDetectorRef,
    private encdec: EncDecService,
    private router: Router,
    private mservice: ToastService,


  ) { }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  articlelist: any;
  packagelist: any;
  isSubscriptionFlag = false;
  inquiry = {
    name: '',
    email: '',
    website: '',
    message: ''
  };
  ngOnInit(): void {
    AOS.init();
    sessionStorage.setItem('packageid', '');
    sessionStorage.setItem('packagename', '');
    this.getArticle();
    const urlParams = new URLSearchParams(window.location.search);
    this.baseUrl = urlParams.get('baseUrl');
  }
  getArticle() {
    // this.loader.loadme = true;
    let userid = (this.adataservice.getUserId()) ? this.encdec.encryptSensitive(this.adataservice.getUserId()) : this.encdec.encryptSensitive(0);
    this.articleservice.getDetails(userid).pipe(takeUntil(this.componentDestroyed$)).subscribe(ldata => {
      //   this.loader.loadme = false;
      if (ldata.flag) {
        this.articlelist = (ldata.result && ldata.result.length > 0) ? ldata.result[0] : [];
        this.packagelist = (ldata.result && ldata.result.length > 0) ? ldata.result[1] : [];
        this.isSubscriptionFlag = (ldata.result && ldata.result[2]) ? (ldata.result[2][0].count > 0) ? false : true : true;
        this.cd.markForCheck();
      } else {
        this.articlelist = [];
        this.packagelist = [];
        this.cd.markForCheck();
      }
    });
  }


  // /////////////////////////
  options = {
    "key": "rzp_test_767HChJSiqNXzs", // Enter the Key ID generated from the Dashboard
    "amount": 50000, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Teztyping", //your business name
    "description": "Test Transaction",
    "image": this.baseUrl + "assets/images/logo_website.jpg",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
    // "callback_url": this.baseUrl+'/thankyou',
    "prefill": {
      "name": "Gaurav Kumar", //your customer's name
      "email": "gaurav.kumar@example.com",
      "contact": "9000090000"
    },
    "notes": {
      "address": "Razorpay Corporate Office"
    },
    "theme": {
      "color": "#fd7e14"
    }
  };


  // //////////////////////////////////////////////////////////////////
  subscribePackage(list) {
    console.log("function")
    if (this.isSubscriptionFlag) {
      console.log("first if")
      if (this.adataservice.getUserId()) {
        console.log("sec. if")
        this.loader.loadme = true;
        sessionStorage.setItem('packageid', this.encdec.encryptSensitive(list.id));
        sessionStorage.setItem('packagename', list.pname);
        this.packageservice.subscribePackge(this.encdec.encryptSensitive(list.id), this.encdec.encryptSensitive(this.adataservice.getUserId())).pipe(takeUntil(this.componentDestroyed$)).subscribe(ldata => {
         

          console.log("ldata.result out  " + ldata.flag+" "+ldata.paymentId+" "+ldata.redirectUrl);
           if (ldata.flag && ldata) {
            this.loader.loadme = false;
          //   // if (ldata.result[0] && ldata.result[0].length > 0) {
          //   //  this.options.prefill.name = (ldata.result[0][0].firstname + ' ' + ldata.result[0][0].lastname);
          //   //   this.options.prefill.email = ldata.result[0][0].email;
          //   //   this.options.prefill.contact = ldata.result[0][0].phoneno;
             
          //   //   console.log("this.options.prefill "+this.options.prefill.name)
          //   // }

          //   // if (ldata.result[1] && ldata.result[1].length > 0) {
          //   //   this.options.description = ldata.result[1][0].pname;
          //   //   this.options.amount = ldata.result[1][0].price*100;
             
          //   // }
          
          //   // const   rzp1 = new this.AuthService.nativeWindow.Razorpay(this.options);
          //   //  rzp1.open();

          //   if(ldata.flag && ldata.result){
          //     ldata.result= JSON.parse(ldata.result);
               window.location.href=ldata.redirectUrl;
          //     this.cd.markForCheck();
          //   }
           }
        });
      } else {
        this.router.navigate(['/auth/login']);
      }
    } else {
      this.mservice.generateMessage('INFO', 'You have already subscribed to a package. You cannot subscribe to some other package', 'INFO');
    }
  }
  contactUs(cform) {
    if (cform.valid) {
      this.loader.loadme = true;
      this.articleservice.saveInquiry(this.inquiry).pipe(takeUntil(this.componentDestroyed$)).subscribe(ldata => {
        this.loader.loadme = false;
        this.inquiry.name = '';
        this.inquiry.website = '';
        this.inquiry.email = '';
        this.inquiry.message = '';
        if (!ldata.flag) {
          this.mservice.generateMessage('ERROR', ldata.error, 'ERROR');
        }
      });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }



}
