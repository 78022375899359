<app-header></app-header>
<div id="rxtNavTravel" class="headerOnTravel"></div>
<div class="main-content col-12 px-4 mt-5 p-3">
    <main>
        <div class="container">
            <p *ngFor="let list of featurelist">
                <strong style="    font-size: x-large;
                text-decoration: underline;">{{list.name}}</strong><br>
                <span>{{list.description}}</span>
            </p>
        </div>
    </main>
</div>